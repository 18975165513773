import TableRow2 from 'src/components/Table_Components/TableRow2';
import { Divider } from '@mui/material';

const NewsShow = (data) => {
	return (
		<>
			<Divider sx={{ mb: 4 }} />
			<TableRow2 label="News" info={data.data.subject} />
			<TableRow2 label="Detail" info={data.data.news_description} />
			<TableRow2 label="Created by" info={data.data.full_name} />
		</>
	);
};
export default NewsShow;
