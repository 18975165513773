import axios from 'axios';

export const edit_one = async (url, values, user_id) => {
	const response = await axios.put(`${process.env.REACT_APP_API_PATH_1}${url}`, { values, user_id });
	return response.data;
};

export const edit_one_with_res_data = async (url, values, user_id) => {
	const response = await axios.put(`${process.env.REACT_APP_API_PATH_1}${url}`, { values, user_id });
	return response;
};
