import { get_graph_token, project_request_to_graph_api } from 'src/queries/queries_actual/graph_api';
import { ExportToCsv } from 'export-to-csv';
import { format } from 'date-fns';

export const get_sharePoint_folder = async ({ workorder_id, customer_name, project }) => {
	await get_graph_token()
		.then((token) => project_request_to_graph_api(token, { folderName: `${workorder_id} - ${customer_name} - ${project}` }, workorder_id))
		.then((res) => {
			res.webUrl ? window.open(res.webUrl) : window.open('https://roterra.sharepoint.com/sites/OperationsGroup/2450125000/Forms/AllItems.aspx');
		});
};

export const changeColor = (invoiced_total, contract_total) => {
	isNaN(invoiced_total) ? (invoiced_total = 0) : (invoiced_total = invoiced_total);

	if (invoiced_total > contract_total) {
		return 'green';
	} else if (contract_total > invoiced_total) {
		return 'red';
	} else {
		return 'black';
	}
};

const csvOptions = {
	fieldSeparator: ',',
	quoteStrings: '"',
	decimalSeparator: '.',
	showLabels: true,
	useBom: true,
	useKeysAsHeaders: false,
	headers: ['id', 'project name', 'technology', 'status', 'contract value', 'invoiced amount', 'customer', 'Project Manager', 'created date'],
};

const csvExporter = new ExportToCsv(csvOptions);

export const handleExportRows = (rows) => {
	csvExporter.generateCsv(
		rows.map(({ original }) => {
			return {
				id: original?.workorder_id,
				project: original?.project,
				project_technology: original?.project_technology,
				status: original?.project_status,
				contract_total: original?.contract_total || 0,
				invoiced_total: original?.invoiced_total || 0,
				customer: original?.customer_name,
				projectManager: original?.projectmanager_name,
				created_on: format(new Date(original?.created_on), 'LLL-dd-yyyy'),
			};
		})
	);
};
