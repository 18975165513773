import { useState } from 'react';

import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import Logout from '@mui/icons-material/Logout';

export const UserIconLoggedOut = () => {
	const [anchorEl, setAnchorEL] = useState(false);

	const open = Boolean(anchorEl);

	const handleClose = () => {
		setAnchorEL(false);
	};

	return (
		<>
			<Tooltip title="Account settings">
				<Button
					id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={(e) => setAnchorEL(e.currentTarget)}
				>
					<Avatar sx={{ width: 42, height: 42 }}></Avatar>
				</Button>
			</Tooltip>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem onClick={handleClose}>
					<Link
						to={`/`}
						style={{
							textDecoration: 'none',
							color: 'black',
						}}
					>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						Log In
					</Link>
				</MenuItem>
			</Menu>
		</>
	);
};
