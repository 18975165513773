import { get_all } from '../query_helpers/get';
import { add_one } from '../query_helpers/post';

// All internal Roterra News
export const getNews = () => {
	const incomingData = get_all('news');
	return incomingData;
};

// export const add_one_news = async (values) => console.log('news', values, values.created_by);
export const add_one_news = async (values) => add_one('news', values, values.created_by);
