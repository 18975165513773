import { Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import InputText2 from 'src/components/forms/controls/Input/InputText2';
import { useParams } from 'react-router';
import { update_plasma_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';
import { useMutation, useQueryClient } from 'react-query';
import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';

const PlasmaRunSheetCutOff = ({ plasmaRunSheet }) => {
	const { plasmaRunSheetId } = useParams();

	const { mutateAsync: updateSheet } = useMutation(update_plasma_run_sheet, {});
	const queryClient = useQueryClient();
	const userData = useContext(UserContext)[0];

	const { handleSubmit, reset, control } = useForm({ defaultValues: {} });

	const onSubmit = async ({ cut_off_length, cut_off_width }) => {
		const update = {
			cut_off_length: cut_off_length,
			cut_off_width: cut_off_width,
		};
		await updateSheet({ id: plasmaRunSheetId, update: update, user_id: userData.user_id });
		await queryClient.invalidateQueries('plasmaRunSheet');
	};

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
			<div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '.5rem' }}>
				<h5>Plate Utilization:</h5>
				<h5>{plasmaRunSheet.plate_utilization || 0}%</h5>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', gap: '.5rem' }}>
				<InputText2
					control={control}
					label={'Cut Off Width'}
					type={'number'}
					name={`cut_off_width`}
					width={'100%'}
					defaultValue={plasmaRunSheet.cut_off_width}
					disabled={Boolean(plasmaRunSheet.cut_off_width)}
				/>
				<h5>X</h5>
				<InputText2
					control={control}
					label={'Cut Off Length'}
					type={'number'}
					name={`cut_off_length`}
					width={'100%'}
					defaultValue={plasmaRunSheet.cut_off_length}
					disabled={Boolean(plasmaRunSheet.cut_off_length)}
				/>
			</div>
			<div>
				{!plasmaRunSheet.cut_off_width && !plasmaRunSheet.cut_off_length && !plasmaRunSheet.completed && (
					<Button size={'small'} variant={'outlined'} onClick={handleSubmit(onSubmit)}>
						Save Cut Off
					</Button>
				)}
			</div>
		</div>
	);
};

export default PlasmaRunSheetCutOff;
