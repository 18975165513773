import { get_all, get_one } from '../query_helpers/get';
import { add_one } from '../query_helpers/post';
import { edit_one } from '../query_helpers/put';

// GET ALL
export const check_sign_out = ({ queryKey: { 1: userId } }) => get_one(`sign_out/one/${userId}`);

export const get_all_signed_out = () => get_all(`sign_out/all`);

export const add_sign_out = (values) => add_one(`sign_out`, values, values.user_id);

export const update_sign_out = (values) => edit_one(`sign_out/signIn`, values);
