import { adaptV4Theme, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';

import { QueryClient, QueryClientProvider } from 'react-query';
import { useRoutes } from 'react-router-dom';

import { UserProvider } from './contexts/UserContext';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import ChristmasComponents from './components/ChristmasComponents/ChristmasComponents';

import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';
import './theme/App.css';
import GlobalStyles from './theme/GlobalStyles';
import { CookiesProvider } from 'react-cookie';

const App = () => {
	const content = useRoutes(routes);
	const { settings } = useSettings();

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				refetchOnmount: true,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
				retry: false,
			},
		},
	});

	const theme = createTheme(
		adaptV4Theme({
			direction: settings.direction,
			responsiveFontSizes: settings.responsiveFontSizes,
			roundedCorners: settings.roundedCorners,
			theme: settings.theme,
		})
	);

	console.log('App JS');
	return (
		<>
			<CookiesProvider>
				<QueryClientProvider client={queryClient}>
					<StyledEngineProvider injectFirst>
						<ThemeProvider theme={theme}>
							<SnackbarProvider dense maxSnack={3}>
								<UserProvider>
									{/* <PermisssionsProvider> */}
									<GlobalStyles />
									{/* <SettingsDrawer /> */}
									<div style={{ height: '100vh', width: '100vw', position: 'relative' }}>
										{content} <ChristmasComponents />
									</div>
									{/* </PermisssionsProvider> */}
								</UserProvider>
							</SnackbarProvider>
						</ThemeProvider>
					</StyledEngineProvider>
				</QueryClientProvider>
			</CookiesProvider>
		</>
	);
};

export default App;
