import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';

import { UserIconLoggedIn } from '../usericon/UserIconLoggedIn';
import { UserIconLoggedInMobile } from '../usericon/UserIconLoggedInMobile';

import { UserIconLoggedOut } from '../usericon/UserIconLoggedOut';

export const UserIcon = ({ mobileView }) => {
	const userData = useContext(UserContext);

	return userData[0].user_id ? (
		mobileView ? (
			<UserIconLoggedInMobile userData={userData[0]} />
		) : (
			<UserIconLoggedIn userData={userData[0]} />
		)
	) : (
		<UserIconLoggedOut />
	);
};
