import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Chip, Divider, Hidden, IconButton, Link, Toolbar } from '@mui/material';
import MenuIcon from '../../icons/Menu';
import Logo from '../zz.RandomComponents/Logo';

const MainNavbar = (props) => {
	const { onSidebarMobileOpen } = props;

	return (
		<AppBar
			elevation={0}
			sx={{
				backgroundColor: 'background.paper',
				color: 'text.secondary',
			}}
		>
			<Toolbar sx={{ minHeight: 64 }}>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
						<MenuIcon fontSize="small" />
					</IconButton>
				</Hidden>
				<Hidden xlDown>
					<RouterLink to="/">
						<Logo
							sx={{
								height: 40,
								width: 40,
							}}
						/>
					</RouterLink>
				</Hidden>
			</Toolbar>
			<Divider />
		</AppBar>
	);
};

MainNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
