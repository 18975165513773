import React from 'react';
import PageTemplateProvider from 'src/pages/dashboard/_Components/PageTemplateProvider';
import RoterraNetVersionTable from '../../components/dashboard/IT_Projects/RoterraNetVersions/Components/RoterraNetVersionTable';

const RoterraNetVersions = () => {
	return (
		<PageTemplateProvider pageName={'RoterraNet Version'} history={[{ title: 'Dashboard', nav: '/dashboard' }]}>
			<RoterraNetVersionTable />
		</PageTemplateProvider>
	);
};

export default RoterraNetVersions;
