import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { Button } from '@mui/material';
import { useContext, useState } from 'react';

import PlasmaRunSheetShowTableRow from './PlasmaRunSheetShowTableRow';
import PlasmaRunSheetTableEditRow from './PlasmaRunSheetTableEditRow';
import { UserContext } from 'src/contexts/UserContext';

const PlasmaRunSheetExtraInfoHelixTableRow = ({ row, index }) => {
	const [edit, setEdit] = useState(false);
	const userData = useContext(UserContext)[0];
	const removePadding = { padding: '0px 0px' };

	const editRow = () => {
		setEdit(!edit);
	};
	console.log(userData);
	return (
		<TableRow hover key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell sx={{ ...removePadding }}>
				{(userData.manufacturing || userData.user_rights <= 2) && (
					<Button onClick={editRow} size={'small'}>
						{edit ? 'Cancel' : 'Edit'}
					</Button>
				)}
			</TableCell>
			{edit ? <PlasmaRunSheetTableEditRow row={row} index={index} setEdit={setEdit} /> : <PlasmaRunSheetShowTableRow row={row} index={index} />}
		</TableRow>
	);
};

export default PlasmaRunSheetExtraInfoHelixTableRow;
