import { useContext, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { UserContext } from 'src/contexts/UserContext';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import { currentDateTime } from 'src/utils/formatDates';
import { SnackBar } from 'src/components/widgets/SnackBar/SnackBar';

const DashboardPopUp = (props) => {
	const { defaultValues, queryName, title, handleClose, popUpState, content, submitFunction } = props;
	const queryClient = useQueryClient();
	const user_data = useContext(UserContext)[0];

	const methods = useForm({
		defaultValues: defaultValues,
	});

	const [snackBar, setSnackBar] = useState(false);
	const [snackBarContent, setSnackBarContent] = useState();
	const [snackBarColor, setSnackBarColor] = useState();

	const { handleSubmit, reset } = methods;

	const { mutateAsync: add_one } = useMutation(submitFunction, {});

	const onSubmit = async (values) => {
		const data = { ...values, created_by: user_data.user_id, created_on: currentDateTime() };
		try {
			const res = await add_one(data);

			if (res) {
				setSnackBarContent('Your Event was added to the Roterra Calendar.');
				setSnackBarColor('success');
				setSnackBar(true);
				setTimeout(() => {
					setSnackBar(false);
				}, 6000);
			}
		} catch {
			setSnackBarContent('Something Went Wrong. Try Again Later.');
			setSnackBarColor('error');
			setSnackBar(true);
			setTimeout(() => {
				setSnackBar(false);
			}, 6000);
		}
		await queryClient.invalidateQueries();
		reset();
		await handleClose();
	};

	return (
		<>
			{/* Pop Up */}
			<SnackBar open={snackBar} content={snackBarContent} onClick={() => setSnackBar(false)} severity={snackBarColor} />

			<FormProvider {...methods}>
				<form>
					<Dialog
						fullwidth={'true'}
						maxwidth="sm"
						open={popUpState}
						onClose={title === 'Add event' ? async () => await handleClose() : handleSubmit(async () => await handleClose())}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						fullWidth
					>
						<DialogTitle id="alert-dialog-title">
							<Typography variant="h5" sx={{ textDecoration: 'underline' }}>
								{props.title}
							</Typography>
						</DialogTitle>
						<DialogContent sx={{ mt: 1 }}>
							<br />
							{content}
						</DialogContent>
						<DialogActions>
							<div>
								<Button
									onClick={title === 'Add event' ? async () => await handleClose() : handleSubmit(async () => await handleClose())}
									style={{ width: 100, marginRight: 15 }}
									size="large"
									color="error"
									variant="contained"
								>
									Exit
								</Button>
								<Button onClick={handleSubmit(onSubmit)} style={{ width: 180 }} size="large" color="success" variant="contained">
									Submit
								</Button>
							</div>
						</DialogActions>
					</Dialog>
				</form>
			</FormProvider>
		</>
	);
};

export default DashboardPopUp;
