import { add_one } from '../query_helpers/post';
import { edit_one } from '../query_helpers/put';
import { delete_one } from '../query_helpers/delete';
import { get_all, get_all_query, get_one } from '../query_helpers/get';

// GET LIST
export const get_active_Suppliers = () => get_all_query('suppliers', 'type', 'active');

export const get_approved_suppliers = () => get_all('suppliers/approved_suppliers');
// ADD 1
export const add_one_Supplier = async (values) => add_one('suppliers', values, values.created_by);

// GET 1
export const get_one_Supplier = ({ queryKey: { 1: id } }) => get_one(`suppliers/${id}`);

// EDIT 1
export const edit_one_Supplier = (values) => edit_one(`suppliers/${values.supplier_id}`, values, values.user_id);

//prettier-ignore
export const edit_one_Supplier_Status = async (values) => edit_one(`suppliers/${values.supplier_id}`, { status: values.status, updated_by:  values.user_id, updated_on: values.updated_on }, values.user_id);

// DELETE 1
export const delete_Supplier = (id, user_id) => delete_one(`suppliers/${id}`, user_id);
