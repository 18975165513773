import { format } from 'date-fns';
import MaterialReactTable from 'material-react-table';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAuthedQuery } from 'src/components/authorization';
import { get_all_versions } from '../ApiEndpoints/RoterraNetVersionsApi';
import RoterraNetVersionsTableAddButton from './RoterraNetVersionsTableAddButton';

const RoterraNetVersionTable = () => {
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 100,
	});

	const [cookie] = useCookies('RPT');

	const projectTableColumns = [
		{
			header: 'Id',
			accessorKey: 'id',
			size: 5,
		},

		{
			header: 'Version # (major.minor.patch)',
			accessorKey: 'version',
			size: 5,
		},

		{
			header: 'Date',
			accessorKey: 'date',
			size: 5,
			Cell: ({ cell }) => {
				return <div>{format(new Date(cell.getValue()), 'LLL-dd-yyyy')}</div>;
			},
		},
		{
			header: 'Detail',
			accessorKey: 'detail',
			size: 700,
			Cell: ({ cell }) => {
				return <div style={{ whiteSpace: 'pre-wrap' }}>{cell.getValue()}</div>;
			},
		},
	];

	const {
		data: incomingData,
		isError,
		isFetching,
		isLoading,
	} = useAuthedQuery({
		queryArr: ['app-versions', cookie.RPT, pagination],
		queryFN: get_all_versions,
	});
	const numOfLiners = incomingData?.data?.pagination;
	const rowOfData = incomingData?.data?.data;
	return (
		<>
			<MaterialReactTable
				columns={projectTableColumns}
				rowCount={numOfLiners?.total ?? 0}
				data={rowOfData ?? []}
				enableDensityToggle={false}
				enableGlobalFilter={false}
				enableColumnFilters={false}
				manualPagination={true}
				onPaginationChange={setPagination}
				state={{
					isLoading,
					showAlertBanner: isError,
					showProgressBars: isFetching,
					density: 'compact',
					pagination,
				}}
				muiToolbarAlertBannerProps={
					isError
						? {
								color: 'error',
								children: 'Error loading data',
						  }
						: undefined
				}
				renderTopToolbarCustomActions={() => {
					return (
						<>
							<RoterraNetVersionsTableAddButton />
						</>
					);
				}}
			/>
		</>
	);
};

export default RoterraNetVersionTable;
