import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import PlasmaRunSheetPopUpContent from '../PlasmaRunSheetPopUp/PlasmaRunSheetPopUpContent';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserContext } from 'src/contexts/UserContext';
import { get_plasma_options, post_plasma_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';
import { todayDate } from 'src/utils/formatDates';

const PlasmaRunSheetAddPopup = () => {
	const [open, setOpen] = useState(false);
	const userData = useContext(UserContext)[0];

	const queryClient = useQueryClient();
	const { mutateAsync: addSheet } = useMutation(post_plasma_run_sheet, {});
	const { handleSubmit, reset, control } = useForm({ defaultValues: {} });

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit = async (formData) => {
		const postData = {
			program_name: formData.program_name,
			sheet_length: formData.sheet_length,
			sheet_width: formData.sheet_width,
			sheet_name: formData.sheet_name,
			sheet_thickness: formData.sheet_thickness.id,
			created_by: userData.user_id,
			created_on: todayDate(),
			plate_utilization: formData.plate_utilization,
			cut_off: formData.cut_off === true ? true : false,
			rush: formData.rush === true ? true : false,
		};

		await addSheet(postData);
		await queryClient.invalidateQueries('plasmaRunSheetTable');
		handleClose();
	};
	const { isLoading, error, data } = useQuery('optionsList', get_plasma_options);
	if (isLoading) return <>Loading...</>;
	if (error) return <>Something Went Wrong</>;
	return (
		<>
			<Button size={'small'} sx={{ mt: 1 }} variant="outlined" onClick={handleClickOpen}>
				Add Plasma Run Sheet
			</Button>
			<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{'Plasma Run Sheet'}</DialogTitle>
				<DialogContent>
					<PlasmaRunSheetPopUpContent control={control} sheetSize={data.sheetSizeList} />
				</DialogContent>

				<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button size={'small'} variant={'outlined'} onClick={handleClose}>
						Cancel
					</Button>
					<Button size={'small'} variant={'contained'} onClick={handleSubmit(onSubmit)} autoFocus>
						Create Sheet
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PlasmaRunSheetAddPopup;
