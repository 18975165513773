import { Card, CardHeader, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';

const DashboardCards = (props) => {
	return (
		<Grid item xs={props.GridXS} sm={props.GridSM} md={props.GridMD} lg={props.GridLG} xl={props.GridXL}>
			<Card sx={{ width: props.width, height: props.height, ...props.otherStyles }}>
				<CardHeader
					title={
						<Typography gutterBottom variant="h6">
							{props.title}
						</Typography>
					}
				/>
				<Container>{props.children}</Container>
			</Card>
		</Grid>
	);
};

export default DashboardCards;
