import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, Card, CardContent, Container, Divider, OutlinedInput, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/zz.RandomComponents/Logo';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { UserContext } from 'src/contexts/UserContext';

const Login = () => {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);

	const [cookies, setCookie, removeCookie] = useCookies(['RPT']);

	const [x, set_user_data] = useContext(UserContext);

	const {
		handleSubmit,
		formState: { error },
		control,
	} = useForm();

	const onLogin = async (data) => {
		const authenticateUser = async () => {
			const url = encodeURI(process.env.REACT_APP_API_PATH_1 + 'auth');
			const basicAuth = 'Basic ' + btoa(data.username + process.env.REACT_APP_SEPARATOR + data.password);
			const headers = {
				Authorization: basicAuth,
				'Content-Type': 'application/json',
			};
			const authResponse = await axios.post(url, {}, { headers });
			return authResponse;
		};

		const newUserRecord = await authenticateUser();

		if (newUserRecord.data.accepted) {
			set_user_data(newUserRecord.data.userData);
			localStorage.setItem('RoterranetUser', JSON.stringify(newUserRecord.data.userData));

			setCookie('RPT', newUserRecord.data.token, { path: '/' });
			enqueueSnackbar(`Welcome ${newUserRecord.data.userData.first_name}`, {
				anchorOrigin: {
					horizontal: 'left',
					vertical: 'bottom',
				},
				variant: 'success',
			});
			navigate('/dashboard', { replace: true });
		} else {
			enqueueSnackbar(`Incorrect Username Or Password`, {
				anchorOrigin: {
					horizontal: 'left',
					vertical: 'bottom',
				},
				variant: 'error',
			});
		}
	};

	return (
		<FormProvider>
			<Helmet>
				<title>Login | RoterraNet</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh',
					background: 'linear-gradient(330deg, rgba(194,0,1,1) 37%, rgba(243,245,247,1) 38%);',
				}}
			>
				<Container maxWidth="sm" sx={{ py: '80px' }}>
					<Card sx={{ borderRadius: 0 }}>
						<CardContent
							sx={{
								display: 'flex',
								flexDirection: 'column',
								p: 4,
								alignItems: 'stretch',
							}}
						>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
									mb: 3,
								}}
							>
								<div>
									<Typography color="textPrimary" gutterBottom variant="h4">
										Log in
									</Typography>
									<Typography color="textSecondary" variant="body2">
										Log in on the internal platform
									</Typography>
								</div>
								<Logo
									sx={{
										height: 60,
										width: 60,
									}}
								/>
							</Box>
							<form onSubmit={handleSubmit(onLogin)}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',

										alignItems: 'center',
										gap: '1rem',
									}}
								>
									<Controller
										name={'username'}
										control={control}
										rules={{ required: true }}
										render={({ field: { onChange, value } }) => (
											<FormControl fullWidth sx={{ m: 1 }}>
												<InputLabel htmlFor="username">Username</InputLabel>
												<OutlinedInput
													sx={{ borderRadius: 0 }}
													id={'username'}
													onChange={onChange}
													placeholder={'User Name'}
													fullWidth
													size="small"
													label={'Username'}
													type={'text'}
												/>
												<FormHelperText id="standard-weight-helper-text">Required</FormHelperText>
											</FormControl>
										)}
									/>
									<Controller
										name={'password'}
										control={control}
										rules={{ required: true }}
										render={({ field: { onChange, value } }) => (
											<FormControl fullWidth sx={{ m: 1 }}>
												<InputLabel htmlFor="password">Password</InputLabel>
												<OutlinedInput
													sx={{ borderRadius: 0 }}
													id="password"
													onChange={onChange}
													placeholder={'Password'}
													fullWidth
													size="small"
													label={'Password'}
													type={showPassword ? 'text' : 'password'}
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={() => setShowPassword(!showPassword)}
																onMouseDown={(e) => e.preventDefault()}
																edge="end"
															>
																{showPassword ? <VisibilityOff /> : <Visibility />}
															</IconButton>
														</InputAdornment>
													}
												/>
												<FormHelperText id="standard-weight-helper-text">Required - Case sensitive </FormHelperText>
											</FormControl>
										)}
									/>
									<Button variant="contained" type="submit" sx={{ color: 'white', backgroundColor: 'C20001', borderRadius: 0 }}>
										LogIn
									</Button>
								</Box>
							</form>
							<Divider sx={{ my: 3 }} />
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<Typography color="textSecondary" variant="caption">
									Having issues logging on? Contact your Supervisor or IT.
								</Typography>
							</div>
						</CardContent>
					</Card>
				</Container>
			</Box>
		</FormProvider>
	);
};

export default Login;
