import React from 'react';
import { Button, Box } from '@mui/material';
import { useQueryClient, useMutation } from 'react-query';
import { update_plasma_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';
import { useParams } from 'react-router';

const PlasmaRunSheetDelete = ({ deleted }) => {
	const { plasmaRunSheetId } = useParams();

	const { mutateAsync: updateSheet } = useMutation(update_plasma_run_sheet, {});

	const queryClient = useQueryClient();

	const onDelete = async () => {
		const update = {
			deleted: true,
		};

		await updateSheet({ id: plasmaRunSheetId, update: update });
		await queryClient.invalidateQueries();
	};

	const undoDelete = async () => {
		const update = {
			deleted: false,
		};
		await updateSheet({ id: plasmaRunSheetId, update: update });
		await queryClient.invalidateQueries();
	};
	return (
		<>
			<Box style={{ border: 'solid red 2px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
				<h5 style={{ color: 'red' }}>Danger</h5>
				<h5 style={{ color: 'red' }}>Clicking the Button Below will Delete the Sheet, This cannot be undone. </h5>

				<div>
					<Button size={'small'} sx={{ margin: '.5rem' }} onClick={deleted ? undoDelete : onDelete} variant="contained">
						{deleted ? 'Undo Delete' : 'Delete'}
					</Button>
				</div>
			</Box>
		</>
	);
};

export default PlasmaRunSheetDelete;
