import { CircularProgress } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router';
import { UserContext } from 'src/contexts/UserContext';
import { get_one_users_permissions } from 'src/queries/queries_actual/users_permissions';

const SetCookie = (props) => {
	const currentURL = useLocation();
	const navigate = useNavigate();

	const [x, set_user_data] = useContext(UserContext);
	// Get Information from Current URL
	const currentINFO = currentURL.pathname.split('/');
	// http/setcookie/ 461  /dashboard-quotes
	const [_a, _b, user_id, newINFO] = currentINFO;
	// Create New URL eg. dashboard-quotes -> dashboard/quotes
	const newURL = newINFO.replace('-', '/');
	// Get User Information and Save it to Context
	const { isLoading: loading_user_data } = useQuery(['users permissions', user_id], get_one_users_permissions, {
		onSuccess: (user_data) => {
			localStorage.setItem('user', JSON.stringify(user_data));
			set_user_data(user_data);
			navigate(`/${newURL}`);
		},
	});

	if (loading_user_data) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh' }}>
				<CircularProgress size={100} />
			</div>
		);
	}

	return (
		<p>
			<div>Server Error. Please ensure Server and Client are connected.</div>
		</p>
	);
};

export default SetCookie;
