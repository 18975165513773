import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircleLoader = ({ error }) => {
	if (error)
		return (
			<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80vw', height: '50vh' }}>
				<h4>This is taking Longer then normal, Try again later!</h4>
				<CircularProgress size={100} />
			</Box>
		);
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80vw', height: '50vh' }}>
			<CircularProgress size={100} />
		</Box>
	);
};

export default CircleLoader;
