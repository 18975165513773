import { Card, CardContent, CardHeader } from '@mui/material';
import React from 'react';

const PlasmaRunSheetCard = ({ cardTitle, titleAlignment, children, height, muiStyles }) => {
	return (
		<Card sx={{ height: height, ...muiStyles }}>
			<CardHeader titleTypographyProps={{ variant: 'h6', align: titleAlignment }} title={cardTitle} />
			<CardContent>{children}</CardContent>
		</Card>
	);
};

export default PlasmaRunSheetCard;
