import { Box, Card, CardContent, CardHeader } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UserContext } from 'src/contexts/UserContext';
import NCRaddContent from './NCRaddContent';
import { useMutation } from 'react-query';
import { currentDateTime } from 'src/utils/formatDates';
import { useSnackbar } from 'notistack';

import { add_one_NCR } from 'src/queries/queries_actual/NCR';
import { useNavigate } from 'react-router';

const StartForm = () => {
	const user_data = useContext(UserContext)[0];
	const methods = useForm();
	const { handleSubmit } = methods;
	const [classType, setClassType] = useState(0);
	const { enqueueSnackbar } = useSnackbar();
	const { mutateAsync: addRequest } = useMutation(add_one_NCR, {});
	const navigate = useNavigate();

	const onSubmit = async (data) => {
		const job_id = await addRequest({
			...data,
			status: 1,
			classification: classType,
			created_on: currentDateTime(),
			created_by: user_data.user_id,
			deleted: false,
		});
		typeof job_id[0].id === 'number'
			? (() => {
					enqueueSnackbar('NCR was Added', {
						anchorOrigin: {
							horizontal: 'left',
							vertical: 'bottom',
						},
						variant: 'success',
					});
					navigate(`/dashboard/qualitycontrol/${job_id[0].id}`);
			  })()
			: enqueueSnackbar('Something went wrong, try again later', {
					anchorOrigin: {
						horizontal: 'left',
						vertical: 'bottom',
					},
					variant: 'error',
			  });
	};
	return (
		<FormProvider {...methods}>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={12}>
					<Card sx={{ pb: 3 }}>
						<CardHeader titleTypographyProps={{ variant: 'h6' }} title={'Add NCR'} />
						<CardContent>
							<NCRaddContent classType={classType} setClassType={setClassType} user_data={user_data} />
						</CardContent>
						<br />
					</Card>
				</Grid>

				<Grid item xs={12} justifyContent="center">
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<Button sx={{ pt: 1, pb: 1 }} color="success" variant="contained" onClick={handleSubmit(onSubmit)}>
							Add
						</Button>
					</Box>
				</Grid>
			</Grid>
		</FormProvider>
	);
};

export default StartForm;
