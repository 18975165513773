import { add_one } from '../query_helpers/post';
import { get_all, get_one } from '../query_helpers/get';
import { delete_one } from '../query_helpers/delete';
import { edit_one } from '../query_helpers/put';
import addHours from 'date-fns/addHours';

export const getInAndOut = () => {
	const incomingData = get_all('calendarevents/current');
	return incomingData;
};

export const getUpcomingInAndOut = () => {
	const incomingData = get_all('calendarevents');
	return incomingData;
};

export const get_oneEvent = ({ queryKey: { 1: id } }) => get_one(`calendarevents/${id}`);

// Post One into calendar
export const add_one_event = (values) => add_one('calendarevents', formatAddtValues(values), values.created_by);

// prettier-ignore
export const edit_one_event = (values) => edit_one(`calendarevents/${values.id}`, formatEditValues(values), values.updated_by);

export const delete_one_event = (id) => delete_one(`calendarevents/${id}`);

export const get_all_Calendar_Categories = () => get_all('calendarevents/categories');

export const get_all_Calendar_Events = ({ queryKey: { 1: month } }) => get_all(`calendarevents/all?${month}`);

const formatAddtValues = (oldValues) => {
	let newValues;
	if (oldValues.return_date) return oldValues;

	return (newValues = { ...oldValues, date: `${oldValues.date}T08:00`, return_date: `${oldValues.date}T16:30` });
};

const formatEditValues = (oldValues) => {
	let newValues;
	if (typeof oldValues.category === 'object' && typeof oldValues.user_id === 'object') {
		console.log('1');
		return (newValues = {
			...oldValues,
			user_id: oldValues.user_id.user_id,
			event_name: oldValues.user_id.full_name,
			category: oldValues.category.id,
		});
	} else if (typeof oldValues.category === 'object') {
		console.log('2');
		return (newValues = {
			...oldValues,
			category: oldValues.category.id,
		});
	} else if (typeof oldValues.user_id === 'object') {
		console.log('3');
		return (newValues = {
			...oldValues,
			user_id: oldValues.user_id.user_id,
			event_name: oldValues.user_id.full_name,
		});
	} else {
		newValues = oldValues;
		delete newValues.detail;
		delete newValues.category_name;
		return newValues;
	}
};
