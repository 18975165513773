import axios from 'axios';

export const add_one = async (url, values, user_id) => {
	const response = await axios.post(`${process.env.REACT_APP_API_PATH_1}${url}`, { values, user_id });
	return response.data;
};

export const add_one_with_res_data = async (url, values, user_id) => {
	const response = await axios.post(`${process.env.REACT_APP_API_PATH_1}${url}`, { values, user_id });
	return response;
};

export const add_one_authed = async (url, values, token) => {
	const response = await axios.post(`${process.env.REACT_APP_API_PATH_1}${url}`, values, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return response.data;
};
