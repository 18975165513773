export const queryFilter = (timeout = 0, setState, initialState, name, element, type, position) => {
	clearTimeout(timeout);

	timeout = setTimeout(() => {
		if (element === '') {
			setState({
				...initialState,
				[name]: {
					...initialState[name],
					searchBy: name,
					filterBy: '',
					type: type,
				},
			});
		} else {
			switch (type) {
				case 'string':
					setState({
						...initialState,
						[name]: {
							...initialState[name],
							searchBy: name,
							filterBy: element,
							type: type,
						},
					});
					break;
				case 'numRange':
					setState({
						...initialState,
						[name]: {
							...initialState[name],
							searchBy: name,
							filterBy: { ...initialState[name]?.filterBy, [position]: element },
							type: type,
						},
					});
					break;
				case 'date':
					setState({
						...initialState,
						[name]: {
							...initialState[name],
							searchBy: name,
							filterBy: { ...initialState[name]?.filterBy, [position]: element },
							type: type,
						},
					});
					break;

				default:
					console.log('error');
					break;
			}
		}
	}, 1500);
};
