import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from '../lib/axios';
import { verify, JWT_SECRET } from '../utils/jwt';

const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
};

const setSession = (accessToken) => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken);
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	} else {
		localStorage.removeItem('accessToken');
		delete axios.defaults.headers.common.Authorization;
	}
};

const handlers = {
	INITIALIZE: (state, action) => {
		const { isAuthenticated, user } = action.payload;

		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user,
		};
	},
	LOGIN: (state, action) => {
		const { user } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
		};
	},
	LOGOUT: (state) => ({
		...state,
		isAuthenticated: false,
		user: null,
	}),
	REGISTER: (state, action) => {
		const { user } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
		};
	},
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
	...initialState,
	platform: 'JWT',
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
	const { children } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const initialize = async () => {
			console.log.apply('lets go wild');
			try {
				const accessToken = window.localStorage.getItem('accessToken');

				if (accessToken && verify(accessToken, JWT_SECRET)) {
					setSession(accessToken);

					const response = await axios.get('/api/identity/me');
					const { user } = response.data;

					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticated: true,
							user,
						},
					});
				} else {
					dispatch({
						type: 'INITIALIZE',
						payload: {
							isAuthenticated: false,
							user: null,
						},
					});
				}
			} catch (err) {
				console.error(err);
				dispatch({
					type: 'INITIALIZE',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				});
			}
		};

		initialize();
	}, []);

	const login = async (email, password) => {
		const response = await axios.post('/api/authentication/login', {
			email,
			password,
		});
		const { accessToken, user } = response.data;

		setSession(accessToken);
		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		});
	};

	const logout = async () => {
		setSession(null);
		dispatch({ type: 'LOGOUT' });
	};

	const register = async (email, name, password) => {
		const response = await axios.post('/api/authentication/register', {
			email,
			name,
			password,
		});
		const { accessToken, user } = response.data;

		window.localStorage.setItem('accessToken', accessToken);
		dispatch({
			type: 'REGISTER',
			payload: {
				user,
			},
		});
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				platform: 'JWT',
				login,
				logout,
				register,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
