import { Grid } from '@mui/material';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { PlasmaRunSheetContext } from 'src/contexts/PlasmaRunSheetContext';
import { get_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';
import PlasmaRunSheetCard from '../PlasmaRunSheetCard';
import PlasmaRunSheetCutOff from './PlasmaRunSheetCutOff/PlasmaRunSheetCutOff';
import PlasmaRunSheetShowCheckedInformation from './PlasmaRunSheetShowCheckedInformation/PlasmaRunSheetShowCheckedInformation';
import PlasmaRunSheetShowSheetinformation from './PlasmaRunSheetShowSheetinformation/PlasmaRunSheetShowSheetinformation';

const PlasmaRunSheetDetail = () => {
	const { setSheet } = useContext(PlasmaRunSheetContext);
	const { plasmaRunSheetId } = useParams();

	const { isLoading, error, data } = useQuery(['plasmaRunSheet', plasmaRunSheetId.toString()], get_run_sheet);

	if (isLoading) return <>Loading...</>;
	if (error) return <>Something Went Wrong</>;

	setSheet(data);
	return (
		<Grid container spacing={0.5}>
			<Grid item xs={6} sm={6} md={4}>
				<PlasmaRunSheetCard cardTitle={'Sheet Information'} titleAlignment={'center'}>
					<PlasmaRunSheetShowSheetinformation plasmaRunSheet={data} />
				</PlasmaRunSheetCard>
			</Grid>
			<Grid item xs={6} sm={6} md={4}>
				<PlasmaRunSheetCard cardTitle={'Checked Information'} titleAlignment={'center'}>
					<PlasmaRunSheetShowCheckedInformation plasmaRunSheet={data} />
				</PlasmaRunSheetCard>
			</Grid>
			<Grid item xs={12} sm={12} md={4}>
				<PlasmaRunSheetCard
					cardTitle={'Cut Off'}
					titleAlignment={'center'}
					plasmaRunSheet={data}
					muiStyles={data.cut_off && { backgroundColor: '#E0E0E0' }}
				>
					<PlasmaRunSheetCutOff plasmaRunSheet={data} />
				</PlasmaRunSheetCard>
			</Grid>
		</Grid>
	);
};

export default PlasmaRunSheetDetail;
