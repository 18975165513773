import { useParams } from 'react-router';
import ControlledCheckBox from 'src/components/forms/controls/DenseInputs/ControlledCheckBox';

import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserContext } from 'src/contexts/UserContext';
import { get_plasma_heat_options, update_plasma_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';

import { Autocomplete, Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import SelectAutoComeplet2 from 'src/components/forms/controls/SelectAutocomplete/SelectAutoComeplet2';
import { PlasmaRunSheetContext } from 'src/contexts/PlasmaRunSheetContext';
import { get_all_plasma_operators } from 'src/queries/queries_actual/users';

const PlasmaRunSheetShowCheckedInformation = ({ plasmaRunSheet }) => {
	const { plasmaRunSheetId } = useParams();
	const userData = useContext(UserContext)[0];
	const { operator, setOperator } = useContext(PlasmaRunSheetContext);
	const { mutateAsync: updateSheet } = useMutation(update_plasma_run_sheet, {});
	const queryClient = useQueryClient();
	const { handleSubmit, reset, control } = useForm({ defaultValues: {} });

	const onChange = async (e, name) => {
		let submitData;
		if (e) {
			submitData = { id: plasmaRunSheetId, update: { [name]: operator.user_id } };
		} else {
			submitData = { id: plasmaRunSheetId, update: { [name]: null } };
		}
		await updateSheet(submitData);
	};

	const onSubmit = async ({ heat_number }) => {
		const update = {
			heat_number: heat_number.heat,
		};
		await updateSheet({ id: plasmaRunSheetId, update: update });
		await queryClient.invalidateQueries('plasmaRunSheet');
	};

	const { isLoading, error, data } = useQuery(['optionsList', plasmaRunSheet.sheet_thickness], get_plasma_heat_options);
	const { isLoading: active_usersLoading, data: active_users, error: usersError } = useQuery('active users', get_all_plasma_operators, {
		refetchOnMount: false,
	});
	if (isLoading || active_usersLoading) return <>Loading</>;
	if (error || usersError) return <>Something Went Wrong</>;

	return (
		<>
			<div>
				<Autocomplete
					disablePortal
					id="combo-box-demo"
					options={active_users}
					getOptionLabel={(option) => `${option.full_name}`}
					sx={{ width: 300 }}
					renderInput={(params) => <TextField {...params} label="Operator" />}
					value={operator}
					onChange={(e, data) => {
						setOperator(data);
					}}
				/>
			</div>

			<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
				<ControlledCheckBox
					disabled={plasmaRunSheet.program_verified}
					checked_by={plasmaRunSheet.program_verified}
					checked_name={plasmaRunSheet.program_verified_name}
					onChange={(e) => onChange(e.target.checked, 'program_verified')}
					label={'Program Verified'}
				/>
				<ControlledCheckBox
					disabled={plasmaRunSheet.thickness_verified}
					checked_by={plasmaRunSheet.thickness_verified}
					checked_name={plasmaRunSheet.thickness_verified_name}
					onChange={(e) => onChange(e.target.checked, 'thickness_verified')}
					label={'Thickness Verified'}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
				<ControlledCheckBox
					disabled={plasmaRunSheet.length_verified}
					checked_by={plasmaRunSheet.length_verified}
					checked_name={plasmaRunSheet.length_verified_name}
					onChange={(e) => onChange(e.target.checked, 'length_verified')}
					label={'Sheet Length Verified'}
				/>
				<ControlledCheckBox
					disabled={plasmaRunSheet.width_verified}
					checked_by={plasmaRunSheet.width_verified}
					checked_name={plasmaRunSheet.width_verified_name}
					onChange={(e) => onChange(e.target.checked, 'width_verified')}
					label={'Sheet Width Verified'}
				/>
			</div>
			<div style={{ display: 'flex', gap: '.5rem' }}>
				<SelectAutoComeplet2
					rules={{ required: true }}
					control={control}
					name={`heat_number`}
					label="Heat #"
					freeSolo={false}
					style={{ width: '50%' }}
					getOptionLabel={(option) => `${option.heat}`}
					options={data}
					defaultValue={{ heat: plasmaRunSheet.heat_number || '' }}
					disabled={Boolean(plasmaRunSheet.heat_number)}
				/>

				{!plasmaRunSheet.heat_number && (
					<Button size={'small'} onClick={handleSubmit(onSubmit)}>
						Save Heat
					</Button>
				)}
			</div>
		</>
	);
};

export default PlasmaRunSheetShowCheckedInformation;
