import DashboardCards from './DashBoardCards';
import { useQuery } from 'react-query';
import { getInAndOut } from 'src/queries/queries_actual/calendar';

import { TableRow, TableCell, Button, Paper, TableContainer, Table, TableHead, TableBody } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import format from 'date-fns/format';

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.tableRow1,
		},
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.background.tableRow2,
		},
		padding: 'none',
	},
}))(TableRow);

const CurrentlyOutRow = ({ data }) => {
	const set_date_time = (data) => {
		if (data.start_time === null) return format(new Date(data.date), 'LLL-dd-yyyy - h:mm aaaa');
		else if (data.start_time === '00:00') return format(new Date(data.date), 'LLL-dd-yyyy');
		else return format(new Date(data.date), 'LLL-dd-yyyy- ') + data.start_time;
	};
	const set_return_date = (data) => {
		if (data.end_time === null) return format(new Date(data.return_date), 'LLL-dd-yyyy - h:mm aaaa');
		else if (data.end_time === '00:00') return format(new Date(data.return_date), 'LLL-dd-yyyy');
		else return format(new Date(data.return_date), 'LLL-dd-yyyy- ') + data.end_time;
	};

	return (
		<StyledTableRow key={data.id} style={{ padding: '1rem' }}>
			<TableCell align="left" sx={{ padding: '.2rem' }} style={{ width: '8rem' }}>
				{data.event_name}
			</TableCell>
			<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem', width: '10rem' }}>
				{set_date_time(data)}
			</TableCell>

			<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem', width: '10rem' }}>
				{set_return_date(data)}
			</TableCell>

			<TableCell align="left" sx={{ padding: '.2rem' }}>
				{data.location === '' ? data.description : data.description + ' - ' + data.location}
			</TableCell>
		</StyledTableRow>
	);
};

export default CurrentlyOutRow;
