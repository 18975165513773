import SelectAutocomplete1 from 'src/components/forms/controls/SelectAutocomplete/SelectAutocomplete1';
import TableRow2Input from 'src/components/Table_Components/TableRow2Input';
import InputText1 from 'src/components/forms/controls/Input/InputText1';
import { useQuery } from 'react-query';
import { get_all_Calendar_Categories } from 'src/queries/queries_actual/calendar';

import { get_active_users } from 'src/queries/queries_actual/users';
import TableRow2 from 'src/components/Table_Components/TableRow2';

const NewsAdd = () => {
	const { data: events, isLoading: eventssLoading } = useQuery(
		'Calendar Event Options',
		get_all_Calendar_Categories,
		{ refetchOnMount: false }
	);
	const { isLoading: active_usersLoading, data: active_users } = useQuery(
		'active users',
		get_active_users,
		{ refetchOnMount: false }
	);

	return (
		<>
			<TableRow2Input
				label={'Name'}
				info={
					<SelectAutocomplete1
						defaultValue={null}
						name="created_by"
						label="Name"
						options={active_users}
						labelKey={'full_name'}
						valueKey={'full_name'}
					/>
				}
			/>
			<TableRow2Input
				label={'Subject'}
				info={<InputText1 name={'subject'} label={'Subject'} />}
			/>
			<TableRow2Input
				label={'Description'}
				info={
					<InputText1
						multiline={true}
						rows={4}
						name={'news_description'}
						label={'Description'}
					/>
				}
			/>
			<TableRow2 info={<small> Add information such as event time to description.</small>} />
		</>
	);
};
export default NewsAdd;
