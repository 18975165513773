import PlasmaRunSheetDetail from './PlasmaRunSheetShow/PlasmaRunSheetDetail';
import PlasmaRunSheetExtraInfo from './PlasmaRunSheetShow/PlasmaRunSheetExtraInfo/PlasmaRunSheetExtraInfo';

const PlasmaRunSheetShowMain = () => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
			<PlasmaRunSheetDetail />
			<PlasmaRunSheetExtraInfo />
		</div>
	);
};

export default PlasmaRunSheetShowMain;
