import DashboardCards from '../calendar/DashBoardCards';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { add_one_news, getNews } from 'src/queries/queries_news/getNews';
import { UserContext } from 'src/contexts/UserContext';
import DashboardPopUp from '../calendar/DashboardPopUp';

import { TableRow, TableCell, Button, Paper, Table, TableHead, TableBody, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import GarbageIcon from 'src/icons/Trash';
import PlusIcon from 'src/icons/Plus';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress } from '@mui/material';
import NewsShow from './NewsAddShow/NewsShow';
import NewsRow from './NewsRow';
import NewsAdd from './NewsAddShow/NewsAdd';

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.tableRow1,
		},
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.background.tableRow2,
		},
		padding: 'none',
	},
}))(TableRow);

const NewsCard = () => {
	const user_data = useContext(UserContext)[0];
	const [deleteData, setDeleteData] = useState({});
	const [deleteEventPopUp, setDeleteEventPopUp] = useState(false);
	const [addEventPopUp, setAddEventPopUp] = useState(false);

	const handle_open_add_event = () => {
		setAddEventPopUp(true);
	};

	const handle_close_add_event = () => {
		setAddEventPopUp(false);
	};

	const handle_close_delete_event = () => {
		setDeleteEventPopUp(false);
	};

	const theme = useTheme();
	let { isLoading, error, data } = useQuery('newsData', getNews);
	if (isLoading)
		return (
			<DashboardCards GridXS={12} GridLG={6} title={'Roterra News'} height={'16rem'}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '16rem',
					}}
				>
					<CircularProgress size={50} />
				</div>
			</DashboardCards>
		);

	const cardHeader = (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<Typography gutterBottom variant="h6">
				Roterra news & Events
			</Typography>

			<Button
				size="small"
				color="error"
				variant="contained"
				type="submit"
				startIcon={<PlusIcon fontSize="small" />}
				onClick={handle_open_add_event}
			>
				Add News or Events
			</Button>
		</div>
	);

	return (
		<DashboardCards GridXS={12} GridLG={6} title={cardHeader} height={'20.375rem'}>
			<DashboardPopUp
				title={'Add News'}
				submitFunction={add_one_news}
				defaultValues={{}}
				handleClose={() => setAddEventPopUp(false)}
				popUpState={addEventPopUp}
				content={<NewsAdd />}
			/>

			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 240 }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow justifyContent="center" style={{ background: theme.palette.background.tableHead }}>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem', width: '20%' }} align="left">
									Subject
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem', width: '40%' }} align="Left">
									Details
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem', width: '20%' }} align="Left">
									Created by
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem', width: '20%' }} align="Left">
									Date
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1 }} align="Left"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{/* Mapss ALL data  */}
							{data.map((data) => {
								return <NewsRow key={data.news_id} data={data} />;
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</DashboardCards>
	);
};

export default NewsCard;
