import { Helmet } from 'react-helmet-async';

import Snowfall from 'react-snowfall';

import { matchRoutes, useLocation } from 'react-router-dom';

const ChristmasComponents = () => {
	// const currentMonth = new Date().getMonth();

	// const routes = [{ path: 'blank/workorders/:workorderId/workordersformprintable' }];

	// const printAblePath = () => {
	// 	const location = useLocation();
	// 	const fullRoutes = matchRoutes(routes, location);
	// 	if (fullRoutes) {
	// 		const [{ route }] = fullRoutes;

	// 		return false;
	// 	} else return true;
	// };

	return (
		<>
			{/* {currentMonth === 11 && printAblePath() && (
				<>
					<Helmet>
						<script src="https://cdn.jsdelivr.net/npm/lightrope.js/lightrope.js?darkShadow=1" type="text/javascript" />
					</Helmet>
					<Snowfall
						// style={{
						// 	position: 'fixed',
						// 	width: '100vw',
						// 	height: '100vh',
						// }}
						color="#A0E3F6"
						snowflakeCount={100}
					/>
				</>
			)} */}
		</>
	);
};

export default ChristmasComponents;
