import { add, format } from 'date-fns';
import InputText2 from 'src/components/forms/controls/Input/InputText2';

const User_sign_out_content = ({ control, userData }) => {
	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h5>User:</h5>
					<h5>{`${userData.first_name} ${userData.last_name}`}</h5>
				</div>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<h5>Departure Time:</h5>
					<h5> {format(new Date(), 'dd-LLL-yyyy hh:mm aaaa')}</h5>
				</div>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '1rem', marginTop: '1rem' }}>
				<InputText2
					rules={{ required: true }}
					control={control}
					label={'Return Time'}
					type={'datetime-local'}
					name={`expected_return`}
					width={'100%'}
					defaultValue={format(add(new Date(), { hours: 1 }), "yyyy-MM-dd'T'HH:mm")}
				/>

				<InputText2
					rules={{ required: true }}
					multiline={true}
					rows={5}
					control={control}
					label={'Comments'}
					type={'text'}
					name={`comments`}
					width={'100%'}
				/>
			</div>
		</>
	);
};

export default User_sign_out_content;
