import axios from 'axios';

export const get_all = async (url, token) => {
	const response = await axios.get(`${process.env.REACT_APP_API_PATH_1 + url}`, { headers: { Authorization: `Bearer ${token}` } });
	return response.data;
};

export const get_all_query = async (url, query_key, query_value) => {
	const response = await axios.get(`${process.env.REACT_APP_API_PATH_1 + url}?${query_key}=${query_value}`);
	return response.data;
};

export const get_all_with_params = async (url, params, token) => {
	const response = await axios.get(`${process.env.REACT_APP_API_PATH_1 + url}`, {
		headers: { Authorization: `Bearer ${token}` },
		params: { ...params },
	});
	return response.data;
};

export const get_one = async (url, token) => {
	const response = await axios.get(`${process.env.REACT_APP_API_PATH_1 + url}`, { headers: { Authorization: `Bearer ${token}` } });

	return response.data[0];
};

export const get_all_authed = async (url, token, params) => {
	const response = await axios.get(`${process.env.REACT_APP_API_PATH_1 + url}`, {
		headers: { Authorization: `Bearer ${token}` },
		params: { ...params },
	});
	return response;
};

export const get_one_authed = async (url, token) => {
	const response = await axios.get(`${process.env.REACT_APP_API_PATH_1 + url}`, { headers: { Authorization: `Bearer ${token}` } });

	return response;
};
