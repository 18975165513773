import { Box, Button } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { useContext, useMemo, useState } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FolderIcon from '@mui/icons-material/Folder';
import { IconButton } from '@mui/material';
import axios from 'axios';
import { format } from 'date-fns';
import { useCookies } from 'react-cookie';
import { useAuthedQuery } from 'src/components/authorization';
import { UserContext } from 'src/contexts/UserContext';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { RPNavLink } from 'src/utils/RPNavLink';
import { formatNumberToMoneyDecimal } from 'src/utils/formatNumbers';
import { changeColor, get_sharePoint_folder, handleExportRows } from '../Functions/projectTableFunctions';

const ProjectsTable = () => {
	const [cookie] = useCookies('RPT');
	const [filterValues, setFilterValues] = useLocalStorage('project-table-filters', []);
	const [columnFilters, setColumnFilters] = useState(filterValues ?? []);
	const [sorting, setSorting] = useState([]);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 50,
	});

	const [value, setValue] = useLocalStorage('project-table', []);
	const [activeColumns, setActiveColumns] = useState(value ?? []);

	const setFilters = (updateFn) => {
		const newFilter = updateFn();
		if (newFilter.length === 0) {
			setColumnFilters([]);
			setFilterValues([]);
			return;
		}
		const previousColumns = columnFilters;
		const index = previousColumns.findIndex((e) => e.id === newFilter[0]?.id);
		if (index !== -1) {
			previousColumns.splice(index, 1);
		}
		setColumnFilters([...previousColumns, ...newFilter]);
		setFilterValues([...previousColumns, ...newFilter]);
	};
	const userData = useContext(UserContext)[0];

	const { data, isError, isFetching, isLoading, refetch } = useAuthedQuery(
		{
			queryArr: [
				'table-data-projects',
				columnFilters, //refetch when columnFilters changes
				pagination.pageIndex, //refetch when pagination.pageIndex changes
				pagination.pageSize, //refetch when pagination.pageSize changes
				sorting, //refetch when sorting changes
			],
			queryFN: async () => {
				const fetchUrl = await axios.get(`${process.env.REACT_APP_API_PATH_1}projects/table`, {
					params: {
						start: pagination.pageIndex,
						size: pagination.pageSize,
						filters: JSON.stringify(columnFilters ?? []),
						sorting: JSON.stringify(sorting ?? []),
					},
					headers: { Authorization: `Bearer ${cookie.RPT}` },
				});

				return fetchUrl.data;
			},
		},
		{ keepPreviousData: true }
	);

	const projectTableColumns = useMemo(
		() => [
			{
				header: 'Id',
				accessorKey: 'workorder_id',
				filterVariant: 'text',
				Cell: ({ row }) => {
					const rowDetail = row.original;

					return <RPNavLink to={`${rowDetail.id}`} label={rowDetail.workorder_id} />;
				},
				size: 15,
			},

			{
				header: 'Folder',
				Cell: ({ row }) => {
					return (
						<IconButton sx={{ m: 0, p: 0 }} onClick={() => get_sharePoint_folder(row.original)}>
							<FolderIcon style={{ color: '#FFDB69' }} />
						</IconButton>
					);
				},
				size: 15,
			},

			{
				header: 'Project',
				accessorKey: 'project',
				filterFn: 'equals',

				// Cell: ({ row }) => {
				// 	return formatLongStr(row?.original?.project);
				// },
			},
			{
				header: 'Technology',
				accessorKey: 'project_technology',
				filterFn: 'equals',
			},
			{
				header: 'Status',
				accessorKey: 'project_status',
				filterFn: 'equals',
			},
			{
				header: 'Contract Value',
				accessorKey: 'contract_total',
				filterVariant: 'range',
				filterFn: 'betweenInclusive',
				Cell: ({ renderedCellValue }) => {
					return formatNumberToMoneyDecimal(renderedCellValue || 0);
				},
			},
			{
				header: 'Invoiced',
				accessorKey: 'invoiced_total',
				filterFn: 'equals',
				Cell: ({ renderedCellValue }) => {
					return formatNumberToMoneyDecimal(renderedCellValue || 0);
				},
			},

			{
				header: 'Difference',
				Cell: ({ row }) => {
					const invoiced_total = parseFloat(row.original.invoiced_total || 0);
					const contract_total = parseFloat(row.original.contract_total || 0);
					return (
						<div
							style={{
								color: changeColor(invoiced_total, contract_total),
							}}
						>
							{formatNumberToMoneyDecimal(Math.abs(contract_total - invoiced_total))}
						</div>
					);
				},
			},

			{
				header: 'Customer',
				accessorKey: 'customer_name',
				filterFn: 'equals',
				// Cell: ({ row }) => {
				// 	return formatLongStr(row?.original?.customer_name);
				// },
			},
			{
				header: 'Project Manager',
				accessorKey: 'projectmanager_name',
				filterFn: 'equals',
				// Cell: ({ row }) => {
				// 	return formatLongStr(row?.original?.projectmanager_name);
				// },
			},
			{
				header: 'Created Date',
				accessorKey: 'created_on',
				Cell: ({ row }) => {
					return format(new Date(row?.original?.created_on), 'LLL-dd-yyyy');
				},
			},
		],
		[]
	);

	return (
		<>
			<MaterialReactTable
				columns={projectTableColumns}
				data={data?.data ?? []} //data is undefined on first render
				rowCount={data?.pagination?.total ?? 0}
				enableDensityToggle={false}
				onColumnFiltersChange={setFilters}
				onPaginationChange={setPagination}
				onSortingChange={setSorting}
				enableFilterMatchHighlighting={true}
				manualFiltering={true}
				manualPagination={true}
				enableGlobalFilter={false}
				enableRowVirtualization
				initialState={{ showColumnFilters: columnFilters?.length > 0, columnVisibility: { ...activeColumns } }}
				onColumnVisibilityChange={async (update) => {
					const test = await update();
					setActiveColumns({ ...activeColumns, ...test });
					setValue({ ...activeColumns, ...test });
				}}
				defaultColumn={{
					minSize: 10, //allow columns to get smaller than default
					maxSize: 30, //allow columns to get larger than default
				}}
				state={{
					columnVisibility: { ...activeColumns },
					columnFilters: columnFilters ?? [],
					isLoading,
					pagination,
					showAlertBanner: isError,
					showProgressBars: isFetching,
					sorting,
					density: 'compact',
				}}
				muiToolbarAlertBannerProps={
					isError
						? {
								color: 'error',
								children: 'Error loading data',
						  }
						: undefined
				}
				muiTableBodyCellProps={({ row }) => {
					if (row.original.deleted) {
						return {
							sx: {
								textDecoration: 'line-through',
								color: 'red',
							},
						};
					}
				}}
				renderTopToolbarCustomActions={({ table }) => {
					return (
						<Box>
							{userData.project_manager && (
								<Button
									size="small"
									disabled={table.getPrePaginationRowModel().rows.length === 0}
									onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
									startIcon={<FileDownloadIcon />}
									variant="contained"
								>
									Export All Rows
								</Button>
							)}
						</Box>
					);
				}}
			/>
		</>
	);
};

export default ProjectsTable;
