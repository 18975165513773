import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { PlasmaRunSheetContext } from 'src/contexts/PlasmaRunSheetContext';
import { get_run_sheet_items } from 'src/queries/queries_actual/plasma_run_sheets';
import { totalInchesUsed } from '../../PlasmaFunctions';
import PlasmaRunSheetExtraInfoHelixTableRow from './PlasmaRunSheetTableComponent/PlasmaRunSheetExtraInfoHelixTableRow';
import PlasmaRunSheetTableHeader from './PlasmaRunSheetTableComponent/PlasmaRunSheetTableHeader';

const PlasmaRunSheetExtraInfoHelixTable = () => {
	const { plasmaRunSheetId } = useParams();
	const removePadding = { padding: '0px 0px', fontSize: 12, width: 50 };
	const { setTotalInches, setTotalParts } = useContext(PlasmaRunSheetContext);

	const { isLoading: sheetItemsLoading, data: sheetItems, error } = useQuery(['plasmaRunSheetItems', plasmaRunSheetId], get_run_sheet_items, {
		enabled: !!plasmaRunSheetId,
	});
	if (sheetItemsLoading) return <>...Loading</>;
	if (error) return <>Something Went Wrong</>;

	const orderItems = () => {
		const helix = [];
		const squareCaps = [];
		const roundCaps = [];
		let total = 0;
		let totalParts = 0;
		sheetItems.map((item) => {
			total = total + totalInchesUsed(item, item.type);
			totalParts = totalParts + parseInt(item.projected_qty);
			if (item.type === 1) helix.push(item);
			if (item.type === 2) squareCaps.push(item);
			if (item.type === 3) roundCaps.push(item);
		});

		setTotalInches(total);
		setTotalParts(totalParts);
		return { helix: helix, squareCaps: squareCaps, roundCaps: roundCaps };
	};

	return (
		<>
			<TableContainer sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
				<Table size={'small'} sx={{ minWidth: 'max-content' }}>
					<PlasmaRunSheetTableHeader />
					<TableBody>
						<TableRow>
							<TableCell sx={{ ...removePadding, backgroundColor: '#E0E0E0' }} colSpan={14}>
								<h4>Helix</h4>
							</TableCell>
						</TableRow>
						{orderItems().helix.map((row, index) => {
							return <PlasmaRunSheetExtraInfoHelixTableRow row={row} index={index} />;
						})}
						<TableRow>
							<TableCell sx={{ ...removePadding, backgroundColor: '#E0E0E0' }} colSpan={14}>
								<h4>Round Caps</h4>
							</TableCell>
						</TableRow>
						{orderItems().roundCaps.map((row, index) => {
							return <PlasmaRunSheetExtraInfoHelixTableRow row={row} index={index} />;
						})}

						<TableRow>
							<TableCell sx={{ ...removePadding, backgroundColor: '#E0E0E0' }} colSpan={14}>
								<h4>Square Caps</h4>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={{ ...removePadding }} />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center">
								Length
							</TableCell>
							<TableCell sx={{ ...removePadding }} align="center">
								Width
							</TableCell>
							<TableCell sx={{ ...removePadding }} align="center" colSpan={10} />
						</TableRow>

						{orderItems().squareCaps.map((row, index) => {
							return <PlasmaRunSheetExtraInfoHelixTableRow key={row.id} row={row} index={index} />;
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{/* <TableContainer sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
				<TableContainer size={'small'} sx={{ minWidth: 'max-content' }}>
					<TableHead>
						<TableRow>
							<TableCell sx={{ ...removePadding }} />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center">
								Length
							</TableCell>
							<TableCell sx={{ ...removePadding }} align="center">
								Width
							</TableCell>
							<TableCell sx={{ ...removePadding }} align="center" />

							<TableCell sx={{ ...removePadding, width: 300 }} align="center" />

							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
							<TableCell sx={{ ...removePadding }} align="center" />
						</TableRow>
					</TableHead>
					<TableBody>
						{orderItems().squareCaps.map((row, index) => {
							return <PlasmaRunSheetExtraInfoHelixTableRow row={row} index={index} />;
						})}
					</TableBody>
				</TableContainer> */}
			{/* </TableContainer> */}
		</>
	);
};

export default PlasmaRunSheetExtraInfoHelixTable;
