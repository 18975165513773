import { Controller, useForm } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';

const InputText2 = (props) => {
	const {
		label,
		name,
		disabled,
		rules,
		multiline,
		rows,
		defaultValue,
		required,
		inputStyle,
		labelStyle,
		inputProps,
		type,
		width,
		control,
		formStyle,
		value,
		other,
	} = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value: value }, fieldState: { error } }) => {
				return (
					<FormControl style={{ ...formStyle, display: 'flex', flexDirection: 'column', width: width || '100%' }} size="small">
						<InputLabel sx={labelStyle} id={`${name}-label`}>
							{label}
						</InputLabel>
						<OutlinedInput
							required={required}
							error={!!error}
							helperText={error ? error.message : null}
							size={'small'}
							multiline={multiline}
							minRows={rows}
							sx={inputStyle}
							labelId={`${name}-label`}
							id={`${name}-input`}
							value={value}
							label={label}
							onChange={onChange}
							disabled={disabled}
							inputProps={inputProps}
							type={type}
							{...other}
						/>
						{!!error && (
							<FormHelperText margin="dense" error id={`${name}-error`} sx={{ padding: 0, margin: 'auto' }}>
								{error.message}
							</FormHelperText>
						)}
					</FormControl>
				);
			}}
		/>
	);
};

export default InputText2;
