import { Box, Button, Card, CardContent, Container, Divider, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { FormProvider, useForm } from 'react-hook-form';

import { format } from 'date-fns';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import InputText2 from 'src/components/forms/controls/Input/InputText2';
import { UserContext } from 'src/contexts/UserContext';
import { update_sign_out } from 'src/queries/queries_actual/sign_out';

const User_sign_in = ({ signInData }) => {
	const userData = useContext(UserContext)[0];

	const {
		handleSubmit,
		formState: { error },
		control,
	} = useForm();

	const { mutateAsync: update } = useMutation(update_sign_out, {});
	const queryClient = useQueryClient();

	const signIn = async (formData) => {
		const updateData = { id: signInData.id, update: { ...formData, actual_return: new Date(), returned: true } };

		console.log(updateData);

		await update(updateData);
		await queryClient.invalidateQueries('sign_out');
	};

	return (
		<FormProvider>
			<Helmet>
				<title>sign In | RoterraNet</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh',
					background: 'linear-gradient(330deg, rgba(194,0,1,1) 37%, rgba(243,245,247,1) 38%);',
				}}
			>
				<Container maxWidth="sm" sx={{ py: '80px' }}>
					<Card sx={{ borderRadius: 0 }}>
						<CardContent
							sx={{
								display: 'flex',
								flexDirection: 'column',
								p: 4,
								alignItems: 'stretch',
							}}
						>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
									mb: 3,
								}}
							>
								<div style={{ width: '100%' }}>
									<Typography color="textPrimary" gutterBottom variant="h4">
										Sign In
									</Typography>
									<Box sx={{ px: 5 }}>
										<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
											<Typography color="textSecondary" variant="h6">
												User:
											</Typography>
											<Typography color="textSecondary" variant="h6">
												{signInData.preferred_name}
											</Typography>
										</div>

										<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
											<Typography color="textSecondary" variant="h6">
												Departure Time:
											</Typography>
											<Typography color="textSecondary" variant="h6">
												{format(new Date(signInData.departure_date), 'dd-LLL-yyyy hh:mm aaaa')}
											</Typography>
										</div>
										<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
											<Typography color="textSecondary" variant="h6">
												Expected Return Time:
											</Typography>
											<Typography color="textSecondary" variant="h6">
												{format(new Date(signInData.expected_return), 'dd-LLL-yyyy hh:mm aaaa')}
											</Typography>
										</div>
									</Box>
								</div>
							</Box>
							<Box sx={{ padding: 5 }}>
								<InputText2
									multiline={true}
									rows={5}
									control={control}
									label={'Comments'}
									type={'text'}
									name={`comments`}
									width={'100%'}
									defaultValue={signInData.comments || ''}
								/>
							</Box>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',

									alignItems: 'center',
									gap: '1rem',
								}}
							>
								<Button
									variant="contained"
									onClick={handleSubmit(signIn)}
									sx={{ color: 'white', backgroundColor: 'C20001', borderRadius: 0 }}
								>
									Sign In
								</Button>
							</Box>

							<Divider sx={{ my: 3 }} />
						</CardContent>
					</Card>
				</Container>
			</Box>
		</FormProvider>
	);
};

export default User_sign_in;
