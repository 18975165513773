import { format } from 'date-fns';

export const formatDate = (date1) => {
	if (!date1) {
		return '';
	}
	const date2 = new Date(date1);
	return format(date2, 'yyyy-MM-dd');
};

export const formatDateLongNoYear = (date1) => {
	if (!date1) {
		return '';
	}
	return format(new Date(date1), 'E, MMM do');
};

export const formatDateLong = (date1) => {
	if (!date1) {
		return '';
	}
	return format(new Date(date1), 'E, MMM do, yyyy');
};

export const currentDateTime = () => {
	return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
};

export const todayDate = () => {
	return format(new Date(), 'yyyy-MM-dd');
};

export const todayDateLong = () => {
	return format(new Date(), 'E, MMM do, yyyy');
};

export const isValidDate = (d) => {
	return d instanceof Date && !isNaN(d);
};
