import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import InputText2 from 'src/components/forms/controls/Input/InputText2';
import { useParams } from 'react-router';
import { UserContext } from 'src/contexts/UserContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { clone_plasma_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';

const PlasmaRunSheetCloneSheet = () => {
	const [open, setOpen] = useState(false);
	const { plasmaRunSheetId } = useParams();
	const userData = useContext(UserContext)[0];

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const { handleSubmit, control } = useForm({ defaultValues: {} });
	const { mutateAsync: clone_sheet } = useMutation(clone_plasma_run_sheet, {});

	const cloneSheet = async ({ clone_amount }) => {
		const submitDate = { amount: clone_amount, created_by: userData.user_id, sheet_id: plasmaRunSheetId };

		const cloneSheet = await clone_sheet(submitDate);
		handleClose();
	};
	return (
		<>
			<div>
				<Button variant="outlined" size={'small'} onClick={handleClickOpen}>
					Clone Sheet
				</Button>
				<Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
					<DialogTitle id="alert-dialog-title">{'Clone Sheet'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Please enter the amount of sheets you would like to Clone.
						</DialogContentText>
						<div style={{ display: 'flex', justifyContent: 'center', margin: '1rem' }}>
							<InputText2 control={control} label={'Number of Clones'} type={'number'} name={`clone_amount`} width={'50%'} />
						</div>
					</DialogContent>
					<DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Button size={'small'} variant="outlined" onClick={handleClose}>
							Close
						</Button>
						<Button size={'small'} variant="contained" onClick={handleSubmit(cloneSheet)} autoFocus>
							Clone Sheet
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</>
	);
};

export default PlasmaRunSheetCloneSheet;
