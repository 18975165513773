import { Divider, Grid, Typography } from '@mui/material';

export default function TableRow2Input({ label, info }) {
	return (
		<>
			<Grid
				item
				fullwidth
				container
				spacing={1}
				align={'left'}
				alignItems={'center'}
				sx={{ paddingBlock: 1 }}
			>
				<Grid item xs={12} sm={6}>
					<Typography color="textPrimary" variant="subtitle2">
						{label}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography color="textSecondary" variant="body2" style={{ fontSize: '8px' }}>
						{info}
					</Typography>
				</Grid>
			</Grid>
			<Divider />
		</>
	);
}
