import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import NavSection from 'src/components/layout/NavSection';
import { ReactComponent as Roterra_Logo_Trans } from 'src/SVG/Roterra_Logo_Trans.svg';
import MenuIcon from '../../../icons/Menu';
import InputSearch from '../../forms/controls/InputSearch/InputSearch';
import WeatherCard from '../calendar/WeatherCard';
import dashboardNavigation from './dashboardNavigation';
import NotificationsPopover from './NotificationsPopover';
import { UserIcon } from './usericon/UserIcon';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
	...(theme.palette.mode === 'light' && {
		backgroundColor: theme.palette.primary.main,
		boxShadow: 'none',
		color: theme.palette.primary.contrastText,
	}),
	...(theme.palette.mode === 'dark' && {
		backgroundColor: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.divider}`,
		boxShadow: 'none',
	}),
	zIndex: theme.zIndex.drawer + 100,
}));

const DashboardNavbar = memo((props) => {
	const { onSidebarMobileOpen, ...other } = props;

	return (
		<DashboardNavbarRoot {...other}>
			<Toolbar sx={{ minHeight: 64, display: 'flex', justifyContent: 'space-between' }} disableGutters>
				<Hidden lgUp>
					<IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
						<MenuIcon fontSize="small" />
					</IconButton>
				</Hidden>

				<Hidden lgDown>
					<Box
						sx={{
							py: 1,
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<RouterLink to="/dashboard">
							<Roterra_Logo_Trans />
						</RouterLink>

						{dashboardNavigation.map((section, index) => (
							<NavSection key={index} mobileView={false} pathname={location.pathname} {...section} />
						))}
					</Box>
				</Hidden>
				<Box sx={{ ml: 1, display: 'flex', alignItems: 'center' }}>
					<WeatherCard />
					<InputSearch />

					<NotificationsPopover />
					<Hidden mdDown>
						<UserIcon />
					</Hidden>
				</Box>
			</Toolbar>
		</DashboardNavbarRoot>
	);
});

DashboardNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default DashboardNavbar;
