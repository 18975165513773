import { Box, Breadcrumbs, Container, Divider, Grid, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';

import ChevronRight from 'src/icons/ChevronRight';
import { RPUuid } from 'src/utils/RPUuid';

const PageTemplateProvider = ({ pageName = 'Roterra', history = [], additionalBtn, children }) => {
	return (
		<>
			<Helmet>
				<title>{pageName}</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 1,
				}}
			>
				<Container maxWidth={true}>
					<Grid container justifyContent="space-between" alignItems={'center'} spacing={1}>
						<Grid item>
							<Typography color="textPrimary" variant="h5">
								{pageName}
							</Typography>
							<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRight fontSize="small" />} sx={{ mt: 1 }}>
								{history.map(({ title, nav }) => {
									return (
										<Link key={RPUuid()} color="textPrimary" component={RouterLink} to={nav} variant="subtitle2">
											{title}
										</Link>
									);
								})}
							</Breadcrumbs>
						</Grid>
						<Grid>{additionalBtn}</Grid>
					</Grid>
					<Divider />
					<Box sx={{ mt: 1 }}>{children}</Box>
				</Container>
			</Box>
		</>
	);
};

export default PageTemplateProvider;
