import { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import Logout from '@mui/icons-material/Logout';
import { ColorFromString } from 'src/utils/ColorFromString';
import { useNavigate } from 'react-router';

export const UserIconLoggedInMobile = ({ userData }) => {
	const { first_name, last_name, user_id } = userData;

	const [anchorEl, setAnchorEL] = useState(false);
	const open = Boolean(anchorEl);

	const navigate = useNavigate();

	const userInitials = first_name.split('')[0] + last_name.split('')[0];

	const handleClose = () => {
		setAnchorEL(false);
	};

	const handleLogout = () => {
		navigate('/auth/logout', { replace: true });
	};
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Avatar
				sx={{
					width: 42,
					height: 42,
					backgroundColor: ColorFromString(`${first_name}  ${last_name}`),
				}}
			>
				{userInitials}
			</Avatar>
			<Button onClick={handleLogout}>
				<Logout size="small" />
				Logout
			</Button>
		</div>
	);
};
