import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const GuestGuard = ({ children }) => {
	if (Boolean(JSON.parse(localStorage.getItem('RoterranetUser')))) {
		return <Navigate to="/dashboard" />;
	}

	return <>{children}</>;
};

GuestGuard.propTypes = {
	children: PropTypes.node,
};

export default GuestGuard;
