import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton } from '@mui/material';
import React, { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import NotificationSettingsPopUpContent from './NotificationSettingsPopup/NotificationSettingsPopUpContent';
import { UserContext } from 'src/contexts/UserContext';
import { useQuery } from 'react-query';
import { get_notification_settings } from 'src/queries/queries_actual/user_notifications';

const NotificationsSettings = () => {
	const [open, setOpen] = React.useState(false);

	const userData = useContext(UserContext)[0];

	const { isLoading, error, data } = useQuery(['notificationSettings', userData.user_id.toString()], get_notification_settings);
	if (isLoading) return <>Loading...</>;
	if (error) return <>Something Went Wrong</>;

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<h5>Notification Settings</h5>

				<IconButton size={'small'} onClick={handleClickOpen}>
					<SettingsIcon size="small" />
				</IconButton>
			</div>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>{'Notification Settings'}</DialogTitle>
				<DialogContent>
					<NotificationSettingsPopUpContent incomingNotificationsSettings={data} />
				</DialogContent>
			</Dialog>
		</>
	);
};

export default NotificationsSettings;
