import { Button } from '@mui/material';
import { format } from 'date-fns';
import { useContext } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { PlasmaRunSheetContext } from 'src/contexts/PlasmaRunSheetContext';
import { UserContext } from 'src/contexts/UserContext';
import { complete_plasma_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';

import PlasmaRunSheetCloneSheet from '../PlasmaCloneSheet/PlasmaRunSheetCloneSheet';

const PlasmaRunSheetCompletedBtn = () => {
	const { plasmaRunSheetId } = useParams();
	const userData = useContext(UserContext)[0];

	const { mutateAsync: updateSheet } = useMutation(complete_plasma_run_sheet, {});
	const queryClient = useQueryClient();
	const { sheet } = useContext(PlasmaRunSheetContext);

	const onSubmit = async () => {
		const update = {
			completed: true,
			completed_date: new Date(),
		};
		await updateSheet({ id: plasmaRunSheetId, update: update });

		await queryClient.invalidateQueries('plasmaRunSheet');
	};

	return (
		<div style={{ marginTop: '.5rem', display: 'flex', justifyContent: 'space-around', alignItems: 'start', gap: '1rem' }}>
			{sheet.completed ? (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<Button variant="contained" size={'small'} disabled>
						Sheet Completed
					</Button>
					<small>{sheet?.completed_date ? format(new Date(sheet?.completed_date), 'LLL-dd-yyyy hh:mm aaaa') : ''}</small>
				</div>
			) : (
				<Button variant="contained" size={'small'} disabled={!sheet.heat_number} onClick={onSubmit}>
					Complete Sheet
				</Button>
			)}

			{userData.manufacturing && (
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: '1rem' }}>
					<PlasmaRunSheetCloneSheet />
					{/* <Button variant="outlined" size={'small'} onClick={onClone}>
						Verify sheet
					</Button> */}
				</div>
			)}
		</div>
	);
};

export default PlasmaRunSheetCompletedBtn;
