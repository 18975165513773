import { format } from 'date-fns';
import InputText1 from 'src/components/forms/controls/Input/InputText1';
import SelectAutocomplete1 from 'src/components/forms/controls/SelectAutocomplete/SelectAutocomplete1';

import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from 'react-query';
import { get_active_Customers } from 'src/queries/queries_actual/customer';
import { get_active_Suppliers } from 'src/queries/queries_actual/suppliers';
import { get_ncr_departments } from 'src/queries/queries_actual/NCR';

const NCRaddContent = (props) => {
	const { first_name, last_name, alias_first_name, alias_last_name } = props.user_data;
	const userPrefFirstName = alias_first_name ? alias_first_name : first_name;
	const userPrefLastName = alias_last_name ? alias_last_name : last_name;
	const { classType, setClassType } = props;

	const { isLoading: suppliersLoading, data: suppliers } = useQuery('get active suppliers', get_active_Suppliers, { refetchOnMount: false });
	const { data: customers, isLoading: customersLoading } = useQuery('active customers', get_active_Customers, { refetchOnMount: false });

	const { data: ncrDepartments, isLoading: ncrDepartmentsLoading } = useQuery('ncrDepartment', get_ncr_departments, { refetchOnMount: false });
	if (suppliersLoading || customersLoading || ncrDepartmentsLoading) return <></>;

	const updateBasedOnClass = (typeOfClass) => {
		switch (typeOfClass) {
			case 'Internal':
				setClassType(1);
				break;
			case 'Supplier':
				setClassType(2);
				break;
			case 'Customer':
				setClassType(3);
				break;
		}
	};

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Autocomplete
					size="small"
					onClose={(e) => updateBasedOnClass(e.target.innerHTML)}
					sx={{ width: '45%' }}
					selectOnFocus
					options={[
						{ value: 1, label: 'Internal' },
						{ value: 2, label: 'Supplier' },
						{ value: 3, label: 'Customer' },
					]}
					renderInput={(params) => <TextField size={'small'} {...params} InputLabelProps={{ shrink: true }} label="Classification" />}
				/>
				<div style={{ width: '45%' }}>
					{classType === 2 ? (
						<SelectAutocomplete1
							name="supplier_id"
							label="Supplier"
							options={suppliers}
							labelKey={'name'}
							valueKey={'supplier_id'}
							rules={{ required: true }}
						/>
					) : (
						<SelectAutocomplete1
							name="customer_id"
							label="Customer"
							options={customers}
							labelKey={'name'}
							valueKey={'customer_id'}
							rules={{ required: true }}
						/>
					)}
				</div>
			</div>
			<br />
			<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
				<div style={{ width: '45%' }}>
					<SelectAutocomplete1
						name="internal_department"
						label="Department"
						options={ncrDepartments}
						labelKey={'label'}
						valueKey={'id'}
						rules={{ required: true }}
					/>
				</div>
				<div style={{ width: '45%' }}>
					<InputText1 name={'defect_code'} label={'Defect Code'} />
				</div>
			</div>
			<br />
			<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
				<div style={{ width: '45%' }}>
					<InputText1 name={'project_id'} label={'WO/PO/Project'} />
				</div>
				<div style={{ width: '45%' }}>
					<InputText1 rules={{ required: true }} name={'quantity'} label={'Quantity'} />
				</div>
			</div>
			<InputText1 multiline={true} rows={4} style={{ marginTop: '.8rem' }} name={'detail'} label={'Detail'} rules={{ required: true }} />

			<br />

			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<small> Created by: {`${userPrefFirstName} ${userPrefLastName}`}</small>
				<small>On: {format(new Date(), 'LLL-dd-yyyy')} </small>
			</div>
		</>
	);
};

export default NCRaddContent;
