import axios from 'axios';

export const delete_one = async (url, user_id) => {
	const response = await axios.delete(`${process.env.REACT_APP_API_PATH_1 + url}`, { user_id });
	return response.data[0];
};

export const delete_one_values = async (url, values) => {
	console.log('deep values', values);
	const response = await axios.delete(`${process.env.REACT_APP_API_PATH_1 + url}`, { values });
	return response.data[0];
};
