import axios from 'axios';

import { get_all, get_all_authed } from '../query_helpers/get';

/**
 * Function that Gets token from Graph api.
 * @author   Hasan Alghanim
 * @data     {Object}  	  		Information for our registered graph api app
 * @return   {String}         	Access token
 * GET => /graphapi  OUR BACK END API !!!!!
 */
export const get_graph_token = () => get_all('graphapi');

/**
 * Function Clones Template Folder in SharePoint => Saves it in the appropriate subsite
 * @author   Hasan Alghanim
 * @param    {String} access_token   	Access_token that was obtained from the function named "get_graph_token" ^^
 * @param    {Object} body   			New folder Name in an object.
 * @param    {Integer} quote_id   		Quote_id to of the newly created quote, needed to sort where the new folder goes.
 * @return   {Object} 					Returns an object from Graph API With res info such as status.
 */
export const make_quotes_folder = async (access_token, body, quote_id, auth_token) => {
	const { name } = body;

	const get_site_id = await get_all_authed('graphapi/site_id', auth_token, { quote_id: quote_id });
	const { site_id, temp_folder_id } = get_site_id.data[0];
	const formattedBody = { name: name, parentReference: { driveId: site_id } };
	const url = `https://graph.microsoft.com/v1.0/drives/${site_id}/items/${temp_folder_id}/copy`;

	let funcResponse;
	await axios
		.post(url, formattedBody, {
			headers: {
				Authorization: `Bearer ${access_token}`,
			},
		})
		.then((res) => {
			funcResponse = res;
		})
		.catch((error) => {
			funcResponse = { 'An Error': error };
		});
	return funcResponse;
};

/**
 * Function That locates a Quote in sharepoint based on the folder name
 * @author   Hasan Alghanim
 * @param    {String} access_token   	Access_token that was obtained from the function named "get_graph_token" ^^
 * @param    {Object} body   			Name of the current Folder in Sharepoint
 * @param    {Integer} quote_id   		Quote_id is needed to sort where to get what subsite to search for the folder.
 * @return   {Object} 					Returns an object from Graph API With res info such as webURL (direct Link to folder if found)
 */

// b!GEAx4pBm3E2gCfKIX6yfIFIR9XlqGgtLvUuJxfqh1lV4IyLHZsiSQbR88NiIydVu 26524 - Westcor Construction Ltd - Calgary Silvera Livingston Package#2
export const request_to_graph_api = async (access_token, body, quote_id, auth_token) => {
	const { folderName } = body;

	const get_site_id = await get_all_authed('graphapi/site_id', auth_token, { quote_id: quote_id });
	const { site_id } = get_site_id.data[0];

	const url = `https://graph.microsoft.com/v1.0/drives/${site_id}/root:/${folderName}`;
	let funcResponse;
	await axios
		.get(url, {
			headers: {
				Authorization: `Bearer ${access_token}`,
			},
		})
		.then((res) => {
			funcResponse = res.data;
		})
		.catch((error) => {
			funcResponse = { 'An Error': error };
		});
	return funcResponse;
};

/**
 * Function Clones Template Folder in SharePoint => Saves it in the appropriate subsite
 * @author   Hasan Alghanim
 * @param    {String} access_token   	Access_token that was obtained from the function named "get_graph_token" ^^
 * @param    {Object} body   			New folder Name in an object.
 * @param    {Integer} project_name   	project_name to of the newly created project, needed to sort where the new folder goes.
 * @return   {Object} 					Returns an object from Graph API With res info such as status.
 */
export const make_project_folder = async (access_token, body, project_name) => {
	const { name } = body;
	const site_id = project_site_id_sorter(project_name, project_siteId_json);
	const formattedBody = { name: name, parentReference: { driveId: site_id } };
	const templateFolderId = '01WSXU2C433OQ5FTB77BHKRDDVH734U5K5';
	const url = `https://graph.microsoft.com/v1.0/drives/${site_id}/items/${templateFolderId}/copy`;
	let funcResponse;
	await axios
		.post(url, formattedBody, {
			headers: {
				Authorization: `Bearer ${access_token}`,
			},
		})
		.then((res) => {
			funcResponse = res;
		})
		.catch((error) => {
			funcResponse = { 'An Error': error };
		});
	return funcResponse;
};

/**
 * Function That locates a Project in sharepoint based on the folder name
 * @author   Hasan Alghanim
 * @param    {String} access_token   	Access_token that was obtained from the function named "get_graph_token" ^^
 * @param    {Object} body   			Name of the current Folder in Sharepoint
 * @param    {Integer} quote_id   		Project_id is needed to sort where to get what subsite to search for the folder.
 * @return   {Object} 					Returns an object from Graph API With res info such as webURL (direct Link to folder if found)
 */

export const project_request_to_graph_api = async (access_token, body, project_id) => {
	const { folderName } = body;

	const site_id = project_site_id_sorter(project_id, project_siteId_json);

	const url = `https://graph.microsoft.com/v1.0/drives/${site_id}/root:/${folderName}`;
	let funcResponse;
	await axios
		.get(url, {
			headers: {
				Authorization: `Bearer ${access_token}`,
			},
		})
		.then((res) => {
			funcResponse = res.data;
		})
		.catch((error) => {
			funcResponse = { 'An Error': error };
		});
	return funcResponse;
};

/**
 * Function Clones Template Folder in SharePoint => Saves it in the appropriate subsite
 * @author   Hasan Alghanim
 * @param    {Integer} id   				Quote_id run by multiple if statements to sort what SubSite it belongs to.
 * @param    {Object} json_data   			An Object of all the quote SubSite "Site_id" obtained from graph api
 * @return   {String} 						The Site_id the quote belongs go.
 */
export const project_site_id_sorter = (id, json_data) => {
	const { project_site_8114001_8114500 } = json_data;

	if (id >= 8114001 && id <= 8114500) return project_site_8114001_8114500;
};

const project_siteId_json = {
	project_site_8114001_8114500: 'b!GEAx4pBm3E2gCfKIX6yfIFIR9XlqGgtLvUuJxfqh1lXzlnoI7tAES7GrLeB_I0IW',
};
