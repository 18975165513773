// import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import BuildIcon from '@mui/icons-material/Build';
// import HandymanIcon from '@mui/icons-material/Handyman';
// import HomeIcon from '@mui/icons-material/Home';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import ReceiptIcon from '@mui/icons-material/Receipt';
// import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
// import ShoppingCart from '../../../icons/ShoppingCart';

const dashboardNavigation = [
	{
		items: [
			// {
			// 	path: '/dashboard/analytics',
			// 	icon: <UserIcon fontSize="large" />,
			// },
			{
				title: 'General',
				path: '/dashboard',
				// icon: <HomeIcon fontSize="small" />,
				children: [
					{
						title: 'Dashboard',
						path: '/dashboard',
					},
					{
						title: 'Calendar',
						path: '/dashboard/calendar/',
					},
				],
			},
			{
				title: 'Sales',
				path: '/dashboard/sales',
				auth: ['quote_read'],
				// icon: <MonetizationOnIcon fontSize="small" />,
				children: [
					{
						title: 'Quotes',
						auth: ['quote_read'],
						path: '/dashboard/quotes',
					},
					{
						title: 'Contacts',
						auth: ['contact_read'],
						path: '/dashboard/contacts',
					},
					{
						title: 'Customers',
						auth: ['customer_read'],
						path: '/dashboard/customers',
					},
				],
			},
			{
				title: 'Operations',
				path: '/dashboard/projects',
				auth: ['project_read'],
				// icon: <BuildIcon fontSize="small" />,
				children: [
					{
						title: 'Projects',
						path: '/dashboard/projects',
					},
				],
			},
			{
				title: 'Purchasing',
				path: '/dashboard/purchasing',
				auth: ['po_read'],
				// icon: <ShoppingCart fontSize="small" />,
				children: [
					{
						title: 'Purchase Orders',
						auth: ['po_read'],
						path: '/dashboard/po',
					},
					{
						title: 'Suppliers',
						auth: ['supplier_read'],
						path: '/dashboard/suppliers',
					},
				],
			},
			{
				title: 'Manufacturing',
				path: '/dashboard/manufacturing',
				auth: ['workorder_read'],
				// icon: <HandymanIcon fontSize="small" />,
				children: [
					{
						title: 'Workorders',
						auth: ['workorder_read'],
						path: '/dashboard/workorders',
					},
					{
						title: 'Material Testing Reports',
						auth: ['workorder_read'],
						path: '/dashboard/mtrs',
					},
					{
						title: 'Plasma Run Sheets',
						auth: ['workorder_read'],
						path: '/dashboard/plasmarunsheets',
					},
					{
						title: 'Live Pipe Cut',
						auth: ['workorder_read'],
						path: '/dashboard/workorders/live',
					},
					{
						title: 'Pipes And Plates List',
						auth: ['workorder_manager'],
						path: '/dashboard/workorders/PipesAndPlates',
					},
				],
			},
			{
				title: 'QC',
				path: '/dashboard/qualitycontrol',
				// icon: <ShoppingCart fontSize="small" />,
				children: [
					{
						title: 'NCR',
						path: '/dashboard/qualitycontrol',
					},
					{
						title: 'RCA',
						path: '/dashboard/qualitycontrol/rca/list',
					},
				],
			},
			{
				title: 'HR',
				path: '/dashboard/production',
				// icon: <AccessibilityIcon fontSize="small" />,
				children: [
					{
						title: 'Roterra Staff',
						path: '/dashboard/users',
					},
					{
						title: 'HR Forms',
						path: '/dashboard/hr/forms',
					},
					{
						title: 'HR Policies',
						path: '/dashboard/hr/policies',
					},

					{
						title: 'HR Resources',
						path: '/dashboard/hr/resources',
					},
					{
						title: 'Newsletters',
						path: '/dashboard/hr/newsletters',
					},
					{
						title: 'HR Dashboard',
						path: '/dashboard/hr/dashboard',
						auth: ['user_manager'],
					},
					{
						title: 'Organizational Hierarchy',
						path: '/dashboard/hr/OrganizationalHierarchy',
					},
					// {
					// 	title: 'Courses and Certifications',
					// 	path: '/dashboard/production/supplier',
					// },
				],
			},
			// {
			// 	title: 'Accounting',
			// 	path: '/dashboard/invoices',

			// 	icon: <ReceiptIcon fontSize="small" />,
			// 	children: [
			// 		{
			// 			title: 'Invoices',
			// 			path: '/dashboard/invoices',
			// 		},
			// 		{
			// 			title: 'Contracts',
			// 			path: '/dashboard/invoices/',
			// 		},
			// 	],
			// },
			{
				title: 'IT',
				path: '/dashboard/it',
				// icon: <SettingsEthernetIcon fontSize="small" />,
				children: [
					{
						title: 'IT Projects',
						path: '/dashboard/it/',
					},
					{
						title: 'RoterraNet Version',
						path: '/dashboard/roterranet/versions',
					},
				],
			},

			{
				title: 'Logistics',
				auth: ['logistics_read'],
				path: '/dashboard/logistics',

				children: [
					{
						title: 'Equipment',
						path: '/dashboard/logistics/equipment',
					},
				],
			},
		],
	},
	// {
	// 	// title: 'Operations',
	// 	items: [],
	// },
];

export default dashboardNavigation;
