import { useContext, useState } from 'react';
import InputText2 from 'src/components/forms/controls/Input/InputText2';

import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import SelectAutoComeplet2 from 'src/components/forms/controls/SelectAutocomplete/SelectAutoComeplet2';

import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import Checkbox2 from 'src/components/forms/controls/Checkbox/Checkbox2';
import { UserContext } from 'src/contexts/UserContext';
import { get_plasma_options, update_plasma_run_sheet } from 'src/queries/queries_actual/plasma_run_sheets';
import PlasmaRunSheetDelete from './PlasmaRunSheetDelete';

const PlasmaRunSheetShowSheetinformation = ({ plasmaRunSheet }) => {
	const [edit, setEdit] = useState(false);

	const { plasmaRunSheetId } = useParams();
	const { handleSubmit, control } = useForm({ defaultValues: {} });
	const { mutateAsync: updateSheet } = useMutation(update_plasma_run_sheet, {});

	const user_data = useContext(UserContext)[0];

	const handleEditOpen = () => {
		setEdit(true);
	};

	const handleEditClose = () => {
		setEdit(false);
	};

	const onSubmit = async (formData) => {
		const update = {
			sheet_length: formData.sheet_length,
			sheet_width: formData.sheet_width,
			sheet_name: formData.sheet_name,
			sheet_thickness: formData.sheet_thickness.id,
			rush: formData.rush,
		};

		await updateSheet({ id: plasmaRunSheetId, update: update });
		handleEditClose();
	};

	const { isLoading, error, data } = useQuery('optionsList', get_plasma_options);
	if (isLoading) return <>Loading...</>;
	if (error) return <>Something Went Wrong</>;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '.5rem' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', gap: '.5rem' }}>
				<InputText2
					control={control}
					label={'Sheet Name'}
					type={'text'}
					name={`sheet_name`}
					width={'100%'}
					defaultValue={plasmaRunSheet.sheet_name}
					disabled={!edit}
				/>

				<SelectAutoComeplet2
					rules={{ required: true }}
					control={control}
					name={`sheet_thickness`}
					label="Sheet Thickness"
					freeSolo={false}
					getOptionLabel={(option) => `${option.size}`}
					options={data.sheetSizeList}
					disabled={!edit}
					defaultValue={{ id: plasmaRunSheet.sheet_thickness, size: plasmaRunSheet.thickness_name }}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', gap: '.5rem' }}>
				<InputText2
					control={control}
					label={'Sheet Width'}
					type={'number'}
					name={`sheet_width`}
					width={'100%'}
					defaultValue={plasmaRunSheet.sheet_width}
					disabled={!edit}
				/>
				<InputText2
					control={control}
					label={'Sheet Length'}
					type={'number'}
					name={`sheet_length`}
					width={'100%'}
					defaultValue={plasmaRunSheet.sheet_length}
					disabled={!edit}
				/>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', gap: '.5rem' }}>
				<InputText2
					control={control}
					label={'Program Name'}
					type={'text'}
					name={`program_name`}
					width={'100%'}
					defaultValue={plasmaRunSheet.program_name}
					disabled={!edit}
				/>

				<Checkbox2 control={control} width={'100%'} label={'Rush'} defaultValue={plasmaRunSheet.rush} name={`rush`} disabled={!edit} />
			</div>

			{(user_data.manufacturing === true || user_data.user_rights <= 2) && (
				<div>
					{edit ? (
						<div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
							<Button size={'small'} variant={'contained'} onClick={handleSubmit(onSubmit)}>
								Save
							</Button>
							<Button size={'small'} variant={'outlined'} onClick={handleEditClose}>
								Cancel
							</Button>
						</div>
					) : (
						!plasmaRunSheet.completed && (
							<div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
								<Button size={'small'} variant={'contained'} onClick={handleEditOpen}>
									Edit
								</Button>
							</div>
						)
					)}
				</div>
			)}
			{edit && !plasmaRunSheet.completed && <PlasmaRunSheetDelete deleted={plasmaRunSheet.deleted} />}
		</Box>
	);
};

export default PlasmaRunSheetShowSheetinformation;
