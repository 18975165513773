import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { format } from 'date-fns';
import { RPNavLink } from 'src/utils/RPNavLink';

const PlasmaRunSheetBody = (props) => {
	const { id, created_on, sheet_length, sheet_width, sheet_name, thickness_name, completed, rush, workorders, completed_date } = props.sheet;

	return (
		<>
			<TableRow hover key={id} style={{ textDecoration: completed ? 'line-through' : '' }}>
				<TableCell align="left">
					<RPNavLink to={`${id}`} label={sheet_name} />
				</TableCell>
				<TableCell align="center">
					{rush ? (
						<div style={{ background: !completed ? 'red' : '', width: '50%' }}>
							<h5 style={{ display: 'flex', justifyContent: 'center' }}>RUSH</h5>
						</div>
					) : (
						<></>
					)}
				</TableCell>

				<TableCell scope="row">{thickness_name}</TableCell>
				<TableCell scope="row">{sheet_length}"</TableCell>
				<TableCell scope="row">{sheet_width}"</TableCell>
				<TableCell scope="row">
					{workorders.map((each, index) => {
						const nextSpace = workorders.length - 1 === index ? '' : ' || ';
						return `${each} ${nextSpace}`;
					})}
				</TableCell>
				<TableCell scope="row" align="left">
					{completed_date ? format(new Date(completed_date), 'LLL-dd-yyyy hh:mm aaaa') : ''}
				</TableCell>
				<TableCell scope="row" align="right">
					{format(new Date(created_on), 'LLL-dd-yyyy')}
				</TableCell>
			</TableRow>
		</>
	);
};

export default PlasmaRunSheetBody;
