import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import { useLayoutEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PlasmaRunSheetBody from './PlasmaRunSheetBody';
import PlasmaRunSheetSearchTable from './PlasmaRunSheetSearchTable';

// Gets All Data
const getItemsPaginated = async (page = 1, rowsPerPage, query) => {
	const { data } = await axios.get(`${process.env.REACT_APP_API_PATH_1}plasmarunsheets/table`, {
		params: { perPage: rowsPerPage, page: page, search: query },
	});

	return data;
};

const PlasmaRunSheetTable = () => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const [searchInfo, setSearchInfo] = useState({});

	const queryClient = useQueryClient();

	// PRE fetch the next page when pages/rowsPerPage changes
	useLayoutEffect(() => {
		const getData = async () => {
			await queryClient.prefetchQuery(`plasmaRunSheetTable`, () => getItemsPaginated(page + 1, rowsPerPage, searchInfo));
		};
		if (plasmaSheets) {
			getData();
		}
	}, [page, rowsPerPage, searchInfo]);

	let {
		isLoading,
		error,
		data: plasmaSheets,
		isFetching,
		isPreviousData,
		refetch,
	} = useQuery('plasmaRunSheetTable', () => getItemsPaginated(page, rowsPerPage, searchInfo), { keepPreviousData: true, staleTime: Infinity });

	if (isLoading) return <>Loading...</>;
	if (error) return <>Something went Wrong</>;

	const tableHeadRow = [
		{ title: 'ID', width: 100, align: 'left' },
		{ title: '', width: 10, align: '' },
		{ title: 'Sheet Thickness', width: 100, align: 'left' },
		{ title: 'Length', width: 100, align: 'left' },
		{ title: 'Width', width: 100, align: 'left' },
		{ title: 'Workorders', width: 400, align: 'left' },
		{ title: 'Completed Date', width: 200, align: 'left' },
		{ title: 'Created on', width: 100, align: 'right' },
	];

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%' }}>
				<TableContainer sx={{ scrollBehavior: 'smooth' }}>
					<Table sx={{ minWidth: 300 }} stickyHeader size={'small'}>
						<TableHead>
							<TableRow>
								{tableHeadRow.map((i, index) => {
									return (
										<TableCell key={index} align={i.align} width={i.width}>
											{i.title}
										</TableCell>
									);
								})}
							</TableRow>

							<PlasmaRunSheetSearchTable setSearchInfo={setSearchInfo} searchInfo={searchInfo} />
						</TableHead>
						<TableBody>
							{plasmaSheets.data.map((row, index) => {
								return <PlasmaRunSheetBody key={index} sheet={row} />;
							})}
						</TableBody>
					</Table>
				</TableContainer>

				<TablePagination
					sx={{ display: 'flex', justifyContent: 'center' }}
					rowsPerPageOptions={[20, 50, 100]}
					component="div"
					count={plasmaSheets.pagination.total}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					ActionsComponent={TablePaginationActions}
				/>
			</Paper>
		</Box>
	);
};

export default PlasmaRunSheetTable;

const TablePaginationActions = (props) => {
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
				<FirstPageIcon />
			</IconButton>
			<IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
				<KeyboardArrowLeft />
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				<KeyboardArrowRight />
			</IconButton>
			<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
				<LastPageIcon />
			</IconButton>
		</Box>
	);
};
