import { useState, useEffect, useRef } from 'react';
import { Autocomplete, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useQuery } from 'react-query';
import { get_list_search } from 'src/queries/queries_actual/searching';
import { useNavigate } from 'react-router';
import BusinessIcon from '@mui/icons-material/Business';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BuildIcon from '@mui/icons-material/Build';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';

const chooseIcon = (description) => {
	switch (description) {
		case 'Customer':
			return <BusinessIcon />;
		case 'Quote':
			return <AttachMoneyIcon />;
		case 'Workorder':
			return <BuildIcon />;
		// Contact / User
		default:
			return <PersonIcon />;
	}
};

export default (props) => {
	const [searchBar, setSearchBar] = useState();

	const { label, name, xs, sm, md, lg } = props;
	const [search, setSearch] = useState('');
	const [options, setOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	useQuery(['search', search], get_list_search, {
		enabled: search !== '',
		onSuccess: (queryResults) => {
			setOptions(queryResults);
		},
	});

	// SEARCH SELECTION => Writing Text

	let timeout = 0;
	const handleSearchChange = (searchText) => {
		clearTimeout(timeout);

		timeout = setTimeout(() => {
			setSearch(searchText.target.value);
		}, 500);
	};

	// SEARCH SELECTION => Selecting Option
	const handleSelectLocation = (e, searchInfo) => {
		if (searchInfo?.link_name) {
			navigate(searchInfo.link_name);
		}
	};

	return (
		<>
			{searchBar ? (
				<div style={{ margin: 0, padding: 0 }}>
					<Grid item xs={xs} sm={sm} md={md} lg={lg} sx={{ display: 'flex', margin: 0.2, padding: 0 }}>
						<Autocomplete
							id="search website"
							getOptionLabel={(option) => option.search_text}
							options={options}
							autoComplete
							includeInputInList
							onChange={handleSelectLocation}
							onInputChange={handleSearchChange}
							renderInput={(params) => (
								<TextField
									style={{
										border: '.5px solid grey',
										borderRadius: 16,
										width: '15rem',
										backgroundColor: 'white',
									}}
									{...params}
									label={label}
									variant="outlined"
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												<CloseIcon
													style={{ color: '#C20000', cursor: 'pointer' }}
													size={20}
													onClick={() => setSearchBar(false)}
												/>
											</>
										),
									}}
									size="small"
								/>
							)}
						/>
					</Grid>
				</div>
			) : (
				<>
					<div>
						<Grid item xs={xs} sm={sm} md={md} lg={lg} sx={{ display: 'flex', margin: 0.2, padding: 0 }}>
							<SearchIcon
								style={{
									color: 'white',
									margin: '0rem 1rem 0rem 1rem',
									cursor: 'pointer',
								}}
								size={30}
								onClick={() => setSearchBar(true)}
							/>
						</Grid>
					</div>
				</>
			)}
		</>
	);
};

{
	/* <Typography variant="body2" color="textSecondary">
{option.structured_formatting.secondary_text}
</Typography> */
}
{
	/* <Grid item xs={1}>
{chooseIcon(option.description)}
</Grid>
<Grid item xs={10}>
</Grid> */
}
