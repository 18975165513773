import { Button, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { refetchOnMount, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import CalendarIcon from 'src/icons/Calendar';
import { getInAndOut } from 'src/queries/queries_actual/calendar';
import { get_all_signed_out } from 'src/queries/queries_actual/sign_out';
import CurrentlyOutRow from './CurrentlyOutRow';
import DashboardCards from './DashBoardCards';

const CurrentInAndOutCard = () => {
	const theme = useTheme();
	let { isLoading, error, data } = useQuery('inOutData', getInAndOut, { refetchOnMount });

	let {
		isLoading: signedOutLoading,
		error: signedOutError,
		data: signedOutData,
	} = useQuery('all_signed_out', get_all_signed_out, {
		refetchOnMount,
	});

	if (isLoading || signedOutLoading)
		return (
			<DashboardCards GridXS={12} GridLG={6} title={'Current In and Out'} height={'16rem'}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '16rem',
					}}
				>
					<CircularProgress size={50} />
				</div>
			</DashboardCards>
		);

	if (error || signedOutError)
		return (
			<DashboardCards GridXS={12} GridLG={6} title={'Current In and Out'} height={'16rem'}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '16rem',
					}}
				>
					Something went wrong
				</div>
			</DashboardCards>
		);
	const cardHeader = (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography gutterBottom variant="h6">
					Currently Out
				</Typography>

				<Link to="/Dashboard/calendar" style={{ textDecoration: 'none' }}>
					<Button size="small" color="error" variant="contained" type="submit" startIcon={<CalendarIcon fontSize="small" />}>
						View Calendar
					</Button>
				</Link>
			</div>
		</>
	);

	return (
		<DashboardCards GridXS={12} GridLG={6} title={cardHeader} height={'20.375rem'}>
			<Paper sx={{ width: '100%', overflow: 'hidden', mt: 1 }}>
				<TableContainer sx={{ maxHeight: 240 }}>
					<Table stickyHeader size={'small'}>
						<TableHead>
							<TableRow style={{ background: theme.palette.background.tableHead, justifyContent: 'center' }}>
								<TableCell sx={{ pt: 0.5, pb: 0.5, fontSize: '.8rem' }} align="left">
									Name
								</TableCell>
								<TableCell sx={{ pt: 0.5, pb: 0.5, fontSize: '.8rem' }} align="left">
									Date Out
								</TableCell>
								<TableCell sx={{ pt: 0.5, pb: 0.5, fontSize: '.8rem' }} align="left">
									Returning Date
								</TableCell>
								<TableCell sx={{ pt: 0.5, pb: 0.5, fontSize: '.8rem' }} align="left">
									Comments
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{/* Maps ALL data  */}

							{data.map((data) => {
								return <CurrentlyOutRow key={data.id} data={data} />;
							})}
							{signedOutData.map((each) => {
								console.log(each);
								return (
									<TableRow key={each.id} style={{ padding: '.5rem' }}>
										<TableCell align="left" sx={{ padding: '.2rem' }} style={{ width: '8rem' }}>
											<p style={{ color: 'red' }}>{each.preferred_name}</p>
										</TableCell>
										<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem', width: '10rem' }}>
											<p style={{ color: 'red' }}>{format(new Date(each.departure_date), 'dd-LLL-yyyy - h:mm aaaa')}</p>
										</TableCell>

										<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem', width: '10rem' }}>
											<p style={{ color: 'red' }}>{format(new Date(each.expected_return), 'dd-LLL-yyyy - h:mm aaaa')}</p>
										</TableCell>

										<TableCell align="left" sx={{ padding: '.2rem' }}>
											<p style={{ color: 'red' }}>{each.comments}</p>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</DashboardCards>
	);
};

export default CurrentInAndOutCard;
