import TableRow2 from 'src/components/Table_Components/TableRow2';
import { Divider } from '@mui/material';
import format from 'date-fns/format';

const Calender_Event_Delete = ({ data }) => {
	const set_date_time = (data) => {
		if (data.start_time === null) return format(new Date(data.date), 'LLL-dd-yyyy - h:mm bbbb');
		else if (data.start_time === '00:00') return format(new Date(data.date), 'LLL-dd-yyyy');
		else return format(new Date(data.date), 'LLL-dd-yyyy- ') + data.start_time;
	};
	const set_return_date = (data) => {
		if (data.end_time === null) return format(new Date(data.return_date), 'LLL-dd-yyyy - h:mm bbbb');
		else if (data.end_time === '00:00') return format(new Date(data.return_date), 'LLL-dd-yyyy');
		else return format(new Date(data.return_date), 'LLL-dd-yyyy- ') + data.start_time;
	};
	return (
		<>
			<h3>Are you sure you want to delete the following ? </h3>
			<Divider sx={{ mb: 4 }} />
			<TableRow2 label="Name" info={data.event_name} />
			<TableRow2 label="Start Date" info={set_date_time(data)} />
			<TableRow2 label="Return Date" info={set_return_date(data)} />
			<TableRow2 label="details" info={data.location + ' - ' + data.description} />
		</>
	);
};
export default Calender_Event_Delete;
