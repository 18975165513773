import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'nprogress/nprogress.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { HelmetProvider } from 'react-helmet-async';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SettingsProvider } from './contexts/SettingsContext';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import './__mocks__';

console.log('made it index.js');

ReactDOM.render(
	<StrictMode>
		<HelmetProvider>
			<StyledEngineProvider injectFirst>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<SettingsProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</SettingsProvider>
				</LocalizationProvider>
			</StyledEngineProvider>
		</HelmetProvider>
	</StrictMode>,
	document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
