import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import { Badge, Box, Divider, IconButton, Link, List, ListItem, ListItemText, Popover, Tooltip, Typography } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserContext } from 'src/contexts/UserContext';
import { get_all_notifications, update_notifications } from 'src/queries/queries_actual/user_notifications';
import BellIcon from '../../../icons/Bell';
import { useNavigate } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsSettings from './NotificationsSettings';
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';

const NotificationsPopover = () => {
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const userData = useContext(UserContext)[0];
	const navigate = useNavigate();
	const { mutateAsync: update } = useMutation(update_notifications, {});
	const handleOpen = () => {
		setOpen(true);
	};

	const queryClient = useQueryClient();

	const handleClose = () => {
		setOpen(false);
	};

	const updateNotifications = async (id) => {
		await update({ id: id, update: { seen: true } });
		await queryClient.invalidateQueries('notifications');
	};

	const urlDirecting = async (url, id) => {
		await update({ id: id, update: { seen: true } });
		await queryClient.invalidateQueries('notifications');
		navigate(url);
	};

	const { isLoading, data: notifications, error } = useQuery(['notifications', userData.user_id.toString()], get_all_notifications, {
		refetchInterval: 60000,
		refetchIntervalInBackground: true,
		refetchOnMount: true,
		refetchOnWindowFocus: 'always',
	});

	if (isLoading)
		return (
			<>
				<IconButton color="inherit" size="small">
					<Badge color="error" badgeContent={<>Loading</>}>
						<BellIcon fontSize="small" />
					</Badge>
				</IconButton>
			</>
		);
	if (error)
		return (
			<>
				<>
					<IconButton color="inherit" size="small">
						<Badge color="error" badgeContent={<>Error</>}>
							<BellIcon fontSize="small" />
						</Badge>
					</IconButton>
				</>
			</>
		);

	return (
		<>
			<Tooltip title="Notifications">
				<IconButton color="inherit" ref={anchorRef} onClick={handleOpen} size="small">
					<Badge color="error" badgeContent={notifications.length} max={50}>
						<BellIcon fontSize="small" />
					</Badge>
				</IconButton>
			</Tooltip>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom',
				}}
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 250 },
				}}
			>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="h6">
						Notifications
					</Typography>
					<Divider />
					<NotificationsSettings />
					<Divider />
				</Box>
				{notifications.length === 0 ? (
					<Box sx={{ p: 1 }}>
						<Typography color="textPrimary" variant="subtitle2">
							There are no notifications
						</Typography>
					</Box>
				) : (
					<>
						<List disablePadding>
							{notifications.map((notification) => {
								return (
									<ListItem divider key={notification.id}>
										<ListItemText
											primary={
												<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
													<Link
														color="textPrimary"
														sx={{ cursor: 'pointer' }}
														underline="none"
														variant="subtitle2"
														onClick={() => {
															urlDirecting(notification.url, notification.id);
														}}
													>
														{notification.title}
													</Link>

													<IconButton
														sx={{ margin: 0, padding: 0 }}
														variant={'outlined'}
														onClick={() => updateNotifications(notification.id)}
														size={'small'}
													>
														<MarkChatReadIcon size={'small'} />
													</IconButton>
												</div>
											}
											secondary={notification.description}
										/>
									</ListItem>
								);
							})}
							<Divider />
						</List>
					</>
				)}
			</Popover>
		</>
	);
};

export default NotificationsPopover;
