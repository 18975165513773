import { Button } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useMutation, useQueryClient } from 'react-query';
import { add_sign_out } from 'src/queries/queries_actual/sign_out';
import User_sign_out_content from './User_sign_out_content';

const User_sign_out = () => {
	const [open, setOpen] = useState(false);

	const navigate = useNavigate();

	const userData = useContext(UserContext)[0];
	const queryClient = useQueryClient();

	const {
		handleSubmit,
		formState: { error },
		control,
	} = useForm();

	const handleClose = () => {
		setOpen(false);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const { mutateAsync: sign_out } = useMutation(add_sign_out, {});

	const signOut = async (data) => {
		const postData = { ...data, user_id: userData.user_id, departure_date: new Date() };

		await sign_out(postData);
		handleClose();
		await queryClient.invalidateQueries('sign_out');
	};

	return (
		<div>
			<Button variant="outlined" onClick={handleClickOpen}>
				Sign out
			</Button>
			<Dialog maxWidth={'sm'} fullWidth={true} open={open} onClose={handleClose}>
				<DialogTitle>Sign Out</DialogTitle>
				<DialogContent>
					<User_sign_out_content control={control} userData={userData} />
				</DialogContent>
				<DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
					<Button onClick={handleClose}>Return</Button>
					<Button variant="contained" onClick={handleSubmit(signOut)}>
						Sign Out
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default User_sign_out;
