import TableCell from '@mui/material/TableCell';

import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { useContext } from 'react';
import { useMutation } from 'react-query';
import ControlledCheckBox from 'src/components/forms/controls/DenseInputs/ControlledCheckBox';
import { UserContext } from 'src/contexts/UserContext';
import { update_plasma_run_sheet_items } from 'src/queries/queries_actual/plasma_run_sheets';
import { PlasmaRunSheetContext } from 'src/contexts/PlasmaRunSheetContext';

const PlasmaRunSheetShowTableRow = ({ row, index }) => {
	const userData = useContext(UserContext)[0];
	const { operator } = useContext(PlasmaRunSheetContext);

	const { mutateAsync: updateItem } = useMutation(update_plasma_run_sheet_items, {});

	const onSubmit = async (inputValue, key) => {
		const submitData = {
			id: row.id,
			update: {
				[key]: inputValue,
			},
		};

		const res = await updateItem(submitData);
	};

	let timeout = 0;
	const onKeyUp = (e) => {
		clearTimeout(timeout);

		timeout = setTimeout(() => {
			onSubmit(e.target.value, 'actual_cut');
		}, 2000);
	};

	const removePadding = { padding: '0px 0px' };

	return (
		<>
			<TableCell align="center" component="th" scope="row" sx={{ ...removePadding }}>
				{`${row.id_name} - ${index + 1}`}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.type === 1 ? row.od : row.length}
				{row.type === 3 && row.od}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.type === 1 ? row.helix_size : row.width}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.type_name}
			</TableCell>

			<TableCell sx={{ ...removePadding }} align="center">
				{row.extra_detail}
			</TableCell>

			<TableCell sx={{ ...removePadding }} align="center">
				{row.first_off_check ? (
					row.first_off_check_name
				) : (
					<ControlledCheckBox
						onChange={(e) => onSubmit(operator.user_id || userData.user_id, 'first_off_check')}
						name={'first_off_check'}
					/>
				)}
			</TableCell>

			<TableCell align="center" sx={{ ...removePadding }}>
				<FormControl style={{ display: 'flex', flexDirection: 'column', width: '100%' }} size="small">
					<InputLabel id={`actualCut-label`}>{'Actual Cut'}</InputLabel>
					<OutlinedInput
						size={'small'}
						labelId={`actualCut-label`}
						id={`actualCut-input`}
						label={'Actual Cut'}
						onKeyUp={onKeyUp}
						disabled={Boolean(row.actual_cut)}
						type={'number'}
						defaultValue={row.actual_cut}
					/>
				</FormControl>
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.projected_qty}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.total_required}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.total_cut}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.workorder_name}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.verified_by ? (
					row.verified_by_name
				) : (
					<ControlledCheckBox onChange={(e) => onSubmit(operator.user_id || userData.user_id, 'verified_by')} name={'verified_by'} />
				)}
			</TableCell>
			<TableCell sx={{ ...removePadding }}></TableCell>
		</>
	);
};

export default PlasmaRunSheetShowTableRow;
