import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import DashboardCards from 'src/components/dashboard/calendar/DashBoardCards';

import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAuthedQuery } from 'src/components/authorization';
import { get_all_versions } from 'src/components/dashboard/IT_Projects/RoterraNetVersions/ApiEndpoints/RoterraNetVersionsApi';
import CircleLoader from 'src/components/utilityComponents/CircleLoader';

const RoterranetVersionsDashboardTable = () => {
	const theme = useTheme();
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 5,
	});

	const [cookie] = useCookies();

	const {
		data: incomingData,
		isError,
		isLoading,
	} = useAuthedQuery({
		queryArr: ['app-versions', cookie.RPT, pagination],
		queryFN: get_all_versions,
	});
	if (isLoading) return <CircleLoader />;

	if (isError) return <CircleLoader error />;

	const data = incomingData.data.data;
	return (
		<DashboardCards GridXS={12} GridLG={6} title={'RoterraNet Updates'} height={'20.375rem'}>
			<Paper sx={{ width: '100%', overflow: 'hidden', mt: 1 }}>
				<TableContainer sx={{ maxHeight: 240 }}>
					<Table stickyHeader size={'small'}>
						<TableHead>
							<TableRow style={{ background: theme.palette.background.tableHead, justifyContent: 'center' }}>
								<TableCell sx={{ pt: 0.5, pb: 0.5, fontSize: '.8rem', width: '10%' }} align="left">
									Version#
								</TableCell>
								<TableCell sx={{ pt: 0.5, pb: 0.5, fontSize: '.8rem', width: '20%' }} align="left">
									Date
								</TableCell>
								<TableCell width={30} sx={{ pt: 0.5, pb: 0.5, fontSize: '.8rem', width: '70%' }} align="left">
									Detail
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((each) => {
								return (
									<TableRow key={each.id} style={{ padding: '.5rem' }}>
										<TableCell align="left" sx={{ padding: '.2rem' }} style={{}}>
											<p>{each.version}</p>
										</TableCell>
										<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem' }}>
											<p>{format(new Date(each.date), 'dd-LLL-yyyy ')}</p>
										</TableCell>

										<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem' }}>
											<p>{each.detail}</p>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</DashboardCards>
	);
};

export default RoterranetVersionsDashboardTable;
