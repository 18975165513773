import { delete_one } from '../query_helpers/delete';
import { get_all_query, get_one } from '../query_helpers/get';
import { add_one } from '../query_helpers/post';
import { edit_one } from '../query_helpers/put';

// GET LIST
export const get_active_users = () => get_all_query('users', 'type', 'active');

export const get_all_engineers = () => get_all_query('users', 'type', 'engineers');

export const get_all_estimators = () => get_all_query('users', 'type', 'estimators');

export const get_all_managers = () => get_all_query('users', 'type', 'managers');

export const get_all_office_users = () => get_all_query('users', 'type', 'office_users');

export const get_all_shop_users = () => get_all_query('users', 'type', 'shop_users');

export const get_all_plasma_operators = () => get_all_query('users', 'type', 'plasma_users');

// ADD
export const add_one_User = async (values, user_id) => add_one('users', values, user_id);

// delete values.user_id; // delete {id: ""} -> leave the rest of the values
// return

// EDIT  everything But Image
export const edit_one_User = async (values, user_id) => edit_one(`users/${values.user_id}`, formatValuesEdit(values), user_id);

export const edit_one_User_from_table = async (values, user_id) => edit_one(`users/${values.user_id}`, values, user_id);

// EDIT ONLY IMAGE
export const upload_user_image = async (values, user_id) => edit_one(`users/${values.user_id}`, values, user_id);

// GET 1
export const get_one_User = ({ queryKey: { 1: id } }) => get_one(`users/${id}`);

// DELETE
export const delete_one_User = (id, values) =>
	delete_one(`users/${id}?type=deleted&senority_debit=${values.senority_debit}&deleted_on=${values.deleted_on}&user_id=${values.user_id}`, values);

export const activate_one_User = async (id, values) => {
	console.log(values);
	await delete_one(
		`users/${id}?type=activated&senority_debit=${values.senority_debit}&start_date=${values.start_date}&user_id=${values.user_id}`,
		values
	);
};

export const previous_next_user = ({ queryKey: { 1: user_id } }) => get_one(`users/prevnextUsers/?id=${user_id}`);

const formatValuesEdit = (values) => {
	const {
		user_id,
		user_name,
		password,
		first_name,
		middle_name,
		last_name,
		alias_first_name,
		alias_middle_name,
		alias_last_name,
		start_date,
		senority_debit,
		birthday,
		direct_dial,
		work_extension,
		work_cell,
		work_email,
		manager,
		company,
		user_rights,
		position,
		department,
		created_by,
		created_on,
		updated_by,
		updated_on,
		deleted,
		deleted_by,
		deleted_on,
		home_address,
		home_address2,
		home_city,
		home_province,
		home_postal_code,
		home_country,
		personal_cell,
		home_phone,
		personal_email,
		image,
		first_aider,
		ice1name,
		ice1phone1,
		ice1phone2,
		ice2name,
		ice2phone1,
		ice2phone2,
		po_access,
		quote_access,
		project_access,
		parking_pass,
		v1_license_plate,
		v1_man,
		v1_model,
		v1_colour,
		v2_license_plate,
		v2_man,
		v2_model,
		v2_colour,
		external_access,
		external_password,
		engineer,
		healthcare_number,
		ice1relationship,
		ice2relationship,
		allergies_dietary_concerns,
		medical_concerns,
		boot_tool_allowance,
		activated_on,
		activated_by,
	} = values;

	const user_info = {
		user_id: user_id,
		user_name: user_name || '',
		password: password || '',
		first_name: first_name || '',
		middle_name: middle_name || '',
		last_name: last_name || '',
		alias_first_name: alias_first_name || '',
		alias_middle_name: alias_middle_name || '',
		alias_last_name: alias_last_name || '',
		start_date: start_date || null,
		senority_debit: senority_debit || 0,
		birthday: birthday || null,
		direct_dial: direct_dial || '',
		work_extension: work_extension || null,
		work_cell: work_cell || '',
		work_email: work_email || '',
		manager: manager || null,
		company: 1,
		user_rights: user_rights || null,
		position: position || null,
		department: department || null,
		created_by: created_by || null,
		created_on: created_on || null,
		updated_by: updated_by || null,
		updated_on: updated_on || null,
		deleted: deleted || 0,
		deleted_by: deleted_by || null,
		deleted_on: deleted_on || null,
		home_address: home_address || '',
		home_address2: home_address2 || '',
		home_city: home_city || '',
		home_province: home_province || '',
		home_postal_code: home_postal_code || '',
		home_country: home_country || '',
		personal_cell: personal_cell || '',
		home_phone: home_phone || '',
		personal_email: personal_email || '',
		first_aider: first_aider || 1,
		ice1name: ice1name || '',
		ice1phone1: ice1phone1 || '',
		ice1phone2: ice1phone2 || '',
		ice2name: ice2name || '',
		ice2phone1: ice2phone1 || '',
		ice2phone2: ice2phone2 || '',
		po_access: po_access || 0,
		quote_access: quote_access || 0,
		project_access: project_access || 0,
		parking_pass: parking_pass || '',
		v1_license_plate: v1_license_plate || '',
		v1_man: v1_man || '',
		v1_model: v1_model || '',
		v1_colour: v1_colour || '',
		v2_license_plate: v2_license_plate || '',
		v2_man: v2_man || '',
		v2_model: v2_model || '',
		v2_colour: v2_colour || '',
		external_access: external_access || null,
		external_password: external_password || '',
		engineer: engineer || false,
		healthcare_number: healthcare_number || '',
		ice1relationship: ice1relationship || '',
		ice2relationship: ice2relationship || '',
		allergies_dietary_concerns: allergies_dietary_concerns || '',
		medical_concerns: medical_concerns || '',
		boot_tool_allowance: boot_tool_allowance || '',
		activated_on: activated_on || null,
		activated_by: activated_by || null,
	};
	console.log('user_info', user_info);
	return user_info;
};
