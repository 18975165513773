import { useCookies } from 'react-cookie';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

/**
 * Cover Function for useQuery.
 * @author   Hasan Alghanim
 * @queryArr   {object} queryArr  Array for useQuery Cache Key + whatever extra (params)
 * @queryFN    {object} queryFN   Api Function to make request
 * @return     {object}           Returns useQuery Object which includes {isLoading, error, data}
 * @Appends    {object}           Functions Appends array with Token from cookies.
 * @Condition  {object}           Functions gets Authorized by middleware. if fails gets redirected to UnAuthorized page (401)
 
 * Example of use
*  import { useAuthedQuery } from 'src/components/authorization';
*  const { isLoading, error, data } = useAuthedQuery({ queryArr: ['plasmaRunSheet', plasmaRunSheetId.toString()], queryFN: get_run_sheet });

 */

const useAuthedQuery = ({ queryArr, queryFN }, ...other) => {
	const navigate = useNavigate();
	const [cookies] = useCookies(['RPT']);

	const query = useQuery([...queryArr, cookies.RPT], queryFN, ...other);

	if (query?.error?.response?.status === 401) {
		navigate('/401', { replace: true });
		localStorage.removeItem('RoterranetUser');
	}

	if (query?.error?.response?.status === 403) {
		navigate('/403', { replace: true });
		localStorage.removeItem('RoterranetUser');
	}
	return query;
};

export default useAuthedQuery;
