import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import PlasmaRunSheetCompletedBtn from 'src/components/dashboard/PlasmaRunSheets/PlasmaRunSheetCompleted/PlasmaRunSheetCompletedBtn';
import PlasmaRunSheetShowMain from 'src/components/dashboard/PlasmaRunSheets/PlasmaRunSheetShowMain';

import ChevronRightIcon from '../../icons/ChevronRight';
import { PlasmaRunSheetProvider } from 'src/contexts/PlasmaRunSheetContext';
const PlasmaRunSheetShow = () => {
	return (
		<PlasmaRunSheetProvider>
			<Helmet>
				<title>Plasma Run Sheet</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 1,
				}}
			>
				<Container maxWidth={true}>
					<Grid container justifyContent="space-between" spacing={1}>
						<Grid item>
							<Typography color="textPrimary" variant="h5">
								Plasma Run Sheets
							</Typography>
							<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
								<Link color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2">
									Dashboard
								</Link>
								<Link color="textPrimary" component={RouterLink} to="/dashboard/plasmarunsheets" variant="subtitle2">
									Sheets list
								</Link>
								<Typography color="textSecondary" variant="subtitle2">
									Plasma Run Sheet
								</Typography>
							</Breadcrumbs>
							<Box
								sx={{
									mb: -1,
									mx: -1,
									mt: 1,
								}}
							/>
						</Grid>
						<Grid item>
							<Box sx={{ m: -1 }}>
								<PlasmaRunSheetCompletedBtn />
							</Box>
						</Grid>
					</Grid>
					<Box sx={{ mt: 3 }}>
						<PlasmaRunSheetShowMain />
					</Box>
				</Container>
			</Box>
		</PlasmaRunSheetProvider>
	);
};
export default PlasmaRunSheetShow;
