import { IconButton, TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import format from 'date-fns/format';
import { useContext, useState } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import GarbageIcon from 'src/icons/Trash';
import { delete_one_event } from 'src/queries/queries_actual/news';
import { formatDateLong } from 'src/utils/formatDates';
import DashboardPopUp from '../calendar/DashboardPopUp';
import NewsDelete from './NewsAddShow/NewsShow';

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.tableRow1,
		},
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.background.tableRow2,
		},
		padding: 'none',
	},
}))(TableRow);

const NewsRow = ({ data }) => {
	const user_data = useContext(UserContext)[0];
	const [deleteEventPopUp, setDeleteEventPopUp] = useState(false);

	const theme = useTheme();

	return (
		<StyledTableRow style={{ padding: '1rem' }}>
			<TableCell align="left" sx={{ ml: 1, p: 1, fontSize: '.8rem' }}>
				{data.subject}
			</TableCell>
			<TableCell align="left" sx={{ padding: 0, fontSize: '.8rem' }}>
				{data.news_description}
			</TableCell>
			<TableCell align="left" sx={{ padding: '.5rem', fontSize: '.8rem' }}>
				{data.full_name}
			</TableCell>
			<TableCell align="left" sx={{ padding: '0rem', fontSize: '.8rem' }}>
				{format(new Date(data.created_on), 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd') ? (
					<p>TODAY!</p>
				) : (
					formatDateLong(data.created_on)
				)}
			</TableCell>
			<TableCell sx={{ padding: '.2rem', fontSize: '.8rem' }}>
				{data.created_by === user_data.user_id || user_data.user_rights <= 2 ? (
					<IconButton size="small" color="error" variant="text" type="submit" onClick={() => setDeleteEventPopUp(true)}>
						<GarbageIcon fontSize="small" />
						<DashboardPopUp
							title={'Delete event'}
							submitFunction={() => delete_one_event(data.news_id)}
							defaultValues={{}}
							handleClose={() => setDeleteEventPopUp(false)}
							popUpState={deleteEventPopUp}
							content={<NewsDelete data={data} />}
						/>
					</IconButton>
				) : null}
			</TableCell>
		</StyledTableRow>
	);
};

export default NewsRow;
