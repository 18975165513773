import { TableCell } from '@mui/material';
import InputText2 from 'src/components/forms/controls/Input/InputText2';
import SelectAutoComeplet2 from 'src/components/forms/controls/SelectAutocomplete/SelectAutoComeplet2';

const PlasmaRunSheetAddItemFieldArrayRows = ({ control, index, options }) => {
	const removePadding = { padding: '.5rem .5rem' };

	return (
		<>
			<TableCell align="center" sx={{ ...removePadding, width: 100 }}>
				{index + 1}
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 100 }}>
				<InputText2 control={control} label={'OD'} type={'text'} name={`AddItemArray.${index}.od`} width={'100%'} />
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 100 }}>
				<SelectAutoComeplet2
					control={control}
					name={`AddItemArray.${index}.size`}
					label="Id"
					freeSolo={false}
					getOptionLabel={(option) => `${option.name}`}
					options={options.idSizeList}
					defaultValue={{ id: '', name: '' }}
				/>
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 100 }}>
				<InputText2 control={control} label={'Length'} type={'number'} name={`AddItemArray.${index}.length`} width={'100%'} />
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 100 }}>
				<InputText2 control={control} label={'Width'} type={'number'} name={`AddItemArray.${index}.width`} width={'100%'} />
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 100 }}>
				<SelectAutoComeplet2
					rules={{ required: true }}
					control={control}
					name={`AddItemArray[${index}].type`}
					label="Type"
					freeSolo={false}
					getOptionLabel={(option) => `${option.label}`}
					options={[
						{ value: 1, label: 'Helix' },
						{ value: 2, label: 'Square Caps' },
						{ value: 3, label: 'Round Caps' },
					]}
					defaultValue={{ value: '', label: '' }}
				/>
			</TableCell>

			<TableCell align="center" sx={{ ...removePadding, width: 300 }}>
				<InputText2 control={control} label={'Extra Info'} type={'text'} name={`AddItemArray.${index}.extra_detail`} width={'100%'} />
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 100 }}>
				<InputText2 control={control} label={'Qty'} type={'number'} name={`AddItemArray.${index}.projected_qty`} width={'100%'} />
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 150 }}>
				<InputText2 control={control} label={'Of'} type={'number'} name={`AddItemArray.${index}.total_required`} width={'100%'} />
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 150 }}>
				<InputText2 control={control} label={'Total Cut'} type={'number'} name={`AddItemArray.${index}.total_cut`} width={'100%'} />
			</TableCell>
			<TableCell align="center" sx={{ ...removePadding, width: 200 }}>
				<SelectAutoComeplet2
					rules={{ required: true }}
					control={control}
					name={`AddItemArray.${index}.workorder_id`}
					label="Workorder #"
					freeSolo={false}
					getOptionLabel={(option) => `${option.workorder_name}`}
					options={options.workorderList}
					defaultValue={{ workorder_id: '', workorder_name: '' }}
				/>
			</TableCell>
		</>
	);
};

export default PlasmaRunSheetAddItemFieldArrayRows;
