import { get_all } from '../query_helpers/get';
import { add_one } from '../query_helpers/post';
import { delete_one } from '../query_helpers/delete';

// All internal Roterra News
export const getNews = () => get_all('news');

export const delete_one_event = (id) => delete_one(`news/${id}`);

export const add_one_news = async (values) => add_one('news', values, values.created_by);
