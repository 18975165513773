import TableRow2Input from 'src/components/Table_Components/TableRow2Input';
import InputText2 from 'src/components/forms/controls/Input/InputText2';

const RoterraNetVersionsTableAddButtonContent = ({ control }) => {
	return (
		<div>
			<TableRow2Input
				label={'Version # (major.minor.patch)'}
				info={
					<InputText2
						control={control}
						label={'Version #'}
						type={'text'}
						name={`version`}
						width={'100%'}
						rules={{ required: 'Please Add a version number' }}
					/>
				}
			/>

			<TableRow2Input
				label={'Detail'}
				info={
					<InputText2
						multiline={true}
						rows={5}
						control={control}
						label={'Detail'}
						type={'text'}
						name={`detail`}
						width={'100%'}
						rules={{ required: 'Please Add update Detail' }}
					/>
				}
			/>

			<TableRow2Input
				label={'Date Of Update'}
				info={<InputText2 control={control} type={'date'} name={`date`} width={'100%'} rules={{ required: 'Please Add an update Date' }} />}
			/>
		</div>
	);
};

export default RoterraNetVersionsTableAddButtonContent;
