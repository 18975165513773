import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';

const ControlledCheckBox = (props) => {
	const { checked_name, checked_by, label, onChange, disabled } = props;

	const makeInitials = (full_name) => {
		const full_name_split = full_name.split(' ');
		return `${full_name_split[0].charAt(0)}${full_name_split[1].charAt(0)} `;
	};
	return (
		<FormControlLabel
			control={
				<Checkbox
					disabled={disabled}
					size="small"
					defaultChecked={Boolean(checked_by)}
					onChange={onChange}
					style={{
						transform: 'scale(.8)',
					}}
				/>
			}
			label={
				<Typography sx={{ fontSize: '.8rem' }}>
					{checked_by && makeInitials(checked_name) + ' - '} {label}
				</Typography>
			}
		/>
	);
};

export default ControlledCheckBox;
