import { Controller, useFormContext } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Grid, TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
			transform: 'translate(10px, 10px) scale(1);',
		},
	},
	inputRoot: {
		// This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
			// Default left padding is 6px
			padding: 0,
		},
	},
}));

export default (props) => {
	const classes = useStyles();

	const {
		control,
		formState: { errors },
	} = useFormContext();
	const {
		label,
		name,
		xs,
		sm,
		md,
		lg,
		disabled,
		rules,
		multiline,
		rows,
		style,
		hidden,
		defaultValue,
		placeholder,
		value,
		onChange,
	} = props;
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			<Controller
				defaultValue={defaultValue ? defaultValue : ''}
				name={name}
				control={control}
				rules={rules}
				render={({ field }) => (
					<TextField
						onChange={onChange}
						style={style}
						placeholder={placeholder}
						fullWidth
						size="small"
						multiline={multiline}
						rows={rows}
						error={!!errors[name]}
						helperText={errors?.[name]?.message}
						label={label}
						type={hidden ? 'hidden' : 'text'}
						{...field}
						disabled={disabled}
					/>
				)}
			/>
		</Grid>
	);
};
