import InputText2 from 'src/components/forms/controls/Input/InputText2';
import SelectAutoComeplet2 from 'src/components/forms/controls/SelectAutocomplete/SelectAutoComeplet2';

import Checkbox2 from 'src/components/forms/controls/Checkbox/Checkbox2';

const PlasmaRunSheetPopUpContent = ({ control, sheetSize }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '.5rem', padding: '.5rem' }}>
			<div style={{ display: 'flex', justifyContent: 'space-between', gap: '.5rem' }}>
				<InputText2 rules={{ required: true }} control={control} label={'Sheet Name'} type={'text'} name={`sheet_name`} width={'100%'} />

				<SelectAutoComeplet2
					rules={{ required: true }}
					control={control}
					name={`sheet_thickness`}
					label="Sheet Thickness"
					freeSolo={false}
					getOptionLabel={(option) => `${option.size}`}
					options={sheetSize}
					defaultValue={{ id: '', size: '' }}
				/>
			</div>
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between', gap: '.5rem' }}>
					<InputText2 control={control} label={'Sheet Width'} type={'number'} name={`sheet_width`} width={'100%'} />
					<InputText2
						rules={{ required: true }}
						control={control}
						label={'Sheet Length'}
						type={'number'}
						name={`sheet_length`}
						width={'100%'}
					/>
				</div>
				<small>Please ensure length and width is in inches</small>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between', gap: '.5rem' }}>
				<InputText2 rules={{ required: true }} control={control} label={'Program Name'} type={'test'} name={`program_name`} width={'100%'} />
				<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<InputText2
						rules={{ required: true }}
						control={control}
						label={'Plate Utilization'}
						type={'number'}
						name={`plate_utilization`}
						width={'100%'}
					/>
					<small>Utilization should be a % </small>
				</div>
			</div>

			<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Checkbox2 control={control} label={'Cut off'} name={`cut_off`} />
				<Checkbox2 control={control} label={'Rush'} name={`rush`} />
			</div>
		</div>
	);
};

export default PlasmaRunSheetPopUpContent;
