import { get_all_query, get_one } from '../query_helpers/get';
import { add_one } from '../query_helpers/post';
import { edit_one } from '../query_helpers/put';

export const add_one_NCR = async (values) => add_one('ncr', formatAddValues(values), values.created_by);

export const get_one_NCR = ({ queryKey: { 1: id, 2: token } }) => get_one(`ncr/${id}`, token);

export const edit_one_NCR = async (values) => edit_one(`ncr/${values.id}`, values, values.initial_review_by);

const formatAddValues = (values) => {
	if (values.supplier_id === '') delete values.supplier_id;
	if (values.customer_id === '') delete values.customer_id;

	return values;
};

export const get_ncr_departments = () => get_all_query('ncr/departments/all');
