import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export const RPNavLink = (props) => {
	const { to, label } = props;
	const theme = useTheme();
	const [hoverMode, setHoverMode] = useState({ color: theme.palette.text.primary, textDecoration: 'none' });
	return (
		<Link
			style={hoverMode}
			to={to}
			onMouseEnter={() => setHoverMode({ color: 'red', textDecoration: 'none' })}
			onMouseLeave={() => {
				setHoverMode({ color: theme.palette.text.primary, textDecoration: 'none' });
			}}
		>
			{label || ''}
		</Link>
	);
};
