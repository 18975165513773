import { useEffect, useState } from 'react';

const PREFIX = 'RoterraNet-';

export default function useLocalStorage(key, initialValue = []) {
	const prefixedKey = PREFIX + key;

	const [value, setValue] = useState(() => {
		const jsonValue = localStorage.getItem(prefixedKey);

		if (jsonValue) {
			return JSON.parse(jsonValue);
		} else {
			localStorage.setItem(prefixedKey, JSON.stringify(initialValue ?? []));
		}
	});

	const setLocalStorageStateValue = (valueOrFn) => {
		let newValue;
		if (typeof valueOrFn === 'function') {
			const fn = valueOrFn;
			newValue = fn(value);
		} else {
			newValue = valueOrFn;
		}

		localStorage.setItem(prefixedKey, JSON.stringify(newValue ?? []));
		setValue(newValue);
	};

	useEffect(() => {
		localStorage.setItem(prefixedKey, JSON.stringify(value ?? []));
	}, [prefixedKey, value]);

	return [value, setLocalStorageStateValue];
}

// const [localStorageQueries, setLocalStorageQueries] = useLocalStorage('quoteTable-queries', []);
