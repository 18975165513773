import { get_all_query, get_one } from '../query_helpers/get';
import { add_one } from '../query_helpers/post';
import { edit_one } from '../query_helpers/put';

// GET LIST

export const get_run_sheet = ({ queryKey: { 1: id } }) => get_one(`plasmarunsheets/${id}/sheet`);

// export const get_run_sheet = ({ queryKey: { 1: id, 2: token } }) => console.log(token);
export const get_run_sheet_items = ({ queryKey: { 1: id } }) => get_all_query(`plasmarunsheets/${id}/items`, '', '');

export const get_plasma_options = () => get_all_query('plasmarunsheets/optionsList', '', '');

export const get_plasma_heat_options = ({ queryKey: { 1: id } }) => get_all_query('plasmarunsheets/heatOptions', 'plateId', id);

export const post_plasma_run_sheet = (values) => add_one('plasmarunsheets/sheet', values, values.created_by);

export const post_plasma_run_sheet_items = (values) => add_one('plasmarunsheets/items', values, values.created_by);

export const update_plasma_run_sheet = (values) => edit_one('plasmarunsheets/sheet', values, values.created_by);

export const complete_plasma_run_sheet = (values) => edit_one('plasmarunsheets/sheet/complete', values, values.created_by);

export const update_plasma_run_sheet_items = (values) => edit_one('plasmarunsheets/items', values, values.created_by);

export const clone_plasma_run_sheet = (values) => add_one('plasmarunsheets/clone', values, values.created_by);
