import TableCell from '@mui/material/TableCell';

import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const PlasmaRunSheetTableHeader = () => {
	const removePadding = { padding: '0px 0px', fontSize: 12, width: 50 };
	return (
		<>
			<TableHead>
				<TableRow>
					<TableCell sx={{ ...removePadding }} />
					<TableCell sx={{ ...removePadding }} align="center">
						#
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						OD
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						ID
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						Type
					</TableCell>

					<TableCell sx={{ ...removePadding, width: 300 }} align="center">
						Additional Information
					</TableCell>

					<TableCell sx={{ ...removePadding }} align="center">
						First Off Check
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						Actual Cut
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						Qty
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						Of
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						Total Cut
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						Workorder
					</TableCell>
					<TableCell sx={{ ...removePadding }} align="center">
						Checked by
					</TableCell>
					<TableCell sx={{ ...removePadding }} />
				</TableRow>
			</TableHead>
		</>
	);
};

export default PlasmaRunSheetTableHeader;
