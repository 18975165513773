import { todayDate } from 'src/utils/formatDates';
import { delete_one } from '../query_helpers/delete';
import { get_all_query, get_one } from '../query_helpers/get';
import { add_one } from '../query_helpers/post';
import { edit_one } from '../query_helpers/put';
import axios from 'axios';

// GET LIST
export const get_active_Customers = () => get_all_query('customers', 'type', 'active');

// ADD 1
export const add_one_Customer = async (values, user_id) =>
	add_one(`customers`, { ...formatValues(values), created_by: user_id, created_on: todayDate() }, user_id);

// EDIT 1
export const edit_one_Customer = async (values, user_id) =>
	edit_one(`customers/${values.customer_id}`, { ...formatValues(values), updated_by: user_id, updated_on: todayDate() }, user_id);

// GET 1
export const get_one_Customer = ({ queryKey: { 1: id } }) => get_one(`customers/${id}`);

// DELETE 1
export const delete_one_Customer = async (id, user_id) => delete_one(`customers/${id}`, user_id);

export const get_customer_analysis = async ({ queryKey }) => {
	const params = queryKey[1];

	const { data } = await axios.get(`${process.env.REACT_APP_API_PATH_1}customer_quote_analysis/analysis`, {
		params: { search: params },
	});
	return data;
};

const formatValues = (data, user_id) => {
	if (!!data?.location?.lat) {
		data.lat = data.location.lat;
		data.long = data.location.long;
		data.city = data.location.city;
		data.province = data.location.province;
		data.country = data.location.country;
		data.postal_code = data.location.postal_code;
		data.address = data.location.streetAddress;
	}
	delete data.location;

	// Created
	if (data?.customer_id) {
		data.created_on = todayDate();
		data.created_by = user_id;
	}
	// Updated
	else {
		data.updated_on = todayDate();
		data.updated_by = user_id;
	}
	delete data.user_id;

	data.blacklisted = data.blacklisted ? 'on' : null;
	data.customer_type = data.customer_type_id;
	data.industry1 = data.industry1_id;
	data.industry2 = data.industry2_id;

	delete data.industry1_id;
	delete data.industry2_id;
	delete data.industry1_name;
	delete data.industry2_name;
	delete data.industry1_background_color;
	delete data.industry2_background_color;
	delete data.industry1_color_format;
	delete data.industry2_color_format;

	delete data.created_by_name;
	delete data.updated_by_name;
	delete data.deleted_by_name;
	delete data.customer_type_id;
	delete data.customer_type_name;
	delete data.customer_type_background;

	return data;
};
