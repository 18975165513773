export const totalInchesUsed = (lineItem, type) => {
	let total = 0;
	//helix type

	if (type === 1) {
		// const od = parseInt(lineItem.od) * 4.145;
		const od = 2 * Math.PI * (parseInt(lineItem.od) / 2);

		// const id = parseFloat(lineItem.decimal) * 3.145;

		const id = 2 * Math.PI * (parseFloat(lineItem.decimal) / 2);

		const totalAddon = ((parseInt(lineItem.od) - parseFloat(lineItem.decimal)) * 2) / 2;

		total = (od + id + totalAddon) * lineItem.projected_qty;
	}
	//Square Caps
	if (type === 2) {
		const length = parseFloat(lineItem.length) * 2;
		const width = parseFloat(lineItem.width) * 2;
		total = (length + width) * lineItem.projected_qty;
	}
	//Round Caps
	if (type === 3) {
		const roundOD = parseInt(lineItem.od) * Math.PI;

		total = roundOD * lineItem.projected_qty;
	}
	return total;
};
