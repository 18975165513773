import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';
import Login from 'src/pages/authentication/Login';
import { check_sign_out } from 'src/queries/queries_actual/sign_out';
import User_sign_in from './SignInSignOut/User_sign_in';

const AuthGuard = (props) => {
	const { children } = props;
	const userData = useContext(UserContext)[0];

	const userId = userData?.user_id?.toString() || 0;
	const { isLoading, data, error } = useQuery(['sign_out', userId], check_sign_out);

	if (isLoading)
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100Vh' }}>
				<CircularProgress />
			</div>
		);
	if (error)
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100Vh' }}>Something Went Wrong, try again</div>
		);

	if (data.sign_in) return <User_sign_in signInData={data.sign_out_info} />;

	if (!Boolean(JSON.parse(localStorage.getItem('RoterranetUser')))) {
		return <Login />;
	}

	return <>{children}</>;
};

AuthGuard.propTypes = {
	children: PropTypes.node,
};

export default AuthGuard;
