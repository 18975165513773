import { useState, useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import DashboardCards from './DashBoardCards';
import { useQuery, refetchOnMount } from 'react-query';
import { getUpcomingInAndOut } from 'src/queries/queries_actual/calendar';
import { TableRow, TableCell, Table, TableHead, TableBody, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import { CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import TableContainer from '@mui/material/TableContainer';
import DashboardPopUp from './DashboardPopUp';
import InAndOutAdd from './CalendarEventsAddShow/CalendarEventAdd';

import { add_one_event } from 'src/queries/queries_actual/calendar';
import PlusIcon from 'src/icons/Plus';

import CalendarUpComingRows from './CalendarUpComingRows';

const CurrentInAndOutCard = () => {
	const user_data = useContext(UserContext)[0];
	const theme = useTheme();
	const [addEventPopUp, setAddEventPopUp] = useState(false);

	const handle_open_add_event = () => {
		setAddEventPopUp(true);
	};

	const handle_close_add_event = () => {
		setAddEventPopUp(false);
	};

	let { isLoading, error, data } = useQuery('inOutDataupcoming', getUpcomingInAndOut, {
		refetchOnMount,
	});
	if (isLoading)
		return (
			<DashboardCards GridXS={12} GridLG={6} title={'UpComing In and Out'} height={'16rem'}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						width: '100%',
						height: '16rem',
					}}
				>
					<CircularProgress size={50} />
				</div>
			</DashboardCards>
		);

	const cardHeader = (
		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
			<Typography gutterBottom variant="h6">
				In and Out (Next 7 Days)
			</Typography>

			<Button
				size="small"
				color="error"
				variant="contained"
				type="submit"
				startIcon={<PlusIcon fontSize="small" />}
				onClick={handle_open_add_event}
			>
				Add to Calendar
			</Button>
		</div>
	);

	return (
		<DashboardCards GridXS={12} GridLG={6} title={cardHeader} height={'20.375rem'}>
			<DashboardPopUp
				title={'Add event'}
				submitFunction={add_one_event}
				defaultValues={{}}
				handleClose={handle_close_add_event}
				popUpState={addEventPopUp}
				setState={setAddEventPopUp}
				content={<InAndOutAdd />}
			/>

			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 240 }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow
								sx={{
									background: theme.palette.background.tableHeader,
									justifyContent: 'center',
								}}
							>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem' }} align="left">
									Name
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem', width: '10rem' }} align="Left">
									Date Out
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem', width: '10rem' }} align="Left">
									Returning Date
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1, fontSize: '.8rem' }} align="Left">
									Comments
								</TableCell>
								<TableCell sx={{ pt: 1, pb: 1 }} align="Left"></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{/* Mapss ALL data  */}
							{data.map((data) => {
								return <CalendarUpComingRows key={data.id} data={data} />;
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</DashboardCards>
	);
};

export default CurrentInAndOutCard;
