import { Alert, Snackbar } from '@mui/material';

export const SnackBar = (props) => {
	const { content, open, handleClose, onClick, severity } = props;

	return (
		<Snackbar
			open={open}
			autoHideDuration={4000}
			onClose={handleClose}
			onClick={onClick}
			sx={{ width: '25rem' }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
		>
			<Alert onClose={handleClose} severity={severity} sx={{ width: '100%', borderRadius: '12px' }} variant="filled" color={severity}>
				{content}
			</Alert>
		</Snackbar>
	);
};
