import Grid from '@mui/material/Grid';
import React from 'react';
import StartForm from './StartForm';

export const NCRAddDetail = () => {
	return (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<StartForm />
			</Grid>
			<Grid item xs={1} />
		</Grid>
	);
};
