import { Switch } from '@mui/material';
import { useState } from 'react';
import { useQuery } from 'react-query';
import InputDateTime1 from 'src/components/forms/controls/Input/InputDateTime1';
import InputText1 from 'src/components/forms/controls/Input/InputText1';
import SelectAutocomplete1 from 'src/components/forms/controls/SelectAutocomplete/SelectAutocomplete1';
import TableRow2Input from 'src/components/Table_Components/TableRow2Input';
import { get_all_Calendar_Categories } from 'src/queries/queries_actual/calendar';
import { get_active_users } from 'src/queries/queries_actual/users';

const InAndOutAdd = () => {
	const { isLoading: eventssLoading, data: events } = useQuery('Calendar Event Options', get_all_Calendar_Categories, { refetchOnMount: false });
	const { isLoading: active_usersLoading, data: active_users } = useQuery('active users', get_active_users, { refetchOnMount: false });

	const [dateTime, setDateTime] = useState();

	if (eventssLoading || active_usersLoading) return <>Loading...</>;
	return (
		<>
			<TableRow2Input
				label={'Name'}
				info={
					<SelectAutocomplete1
						defaultValue={null}
						name="user_id"
						label="Name"
						options={active_users}
						labelKey={'full_name'}
						valueKey={'user_id'}
						rules={{ required: true }}
					/>
				}
			/>
			<TableRow2Input
				label={'Category'}
				info={
					<SelectAutocomplete1
						rules={{ required: true }}
						name="category"
						label="Category"
						options={events}
						labelKey={'category'}
						valueKey={'id'}
					/>
				}
			/>
			<TableRow2Input label={'Location'} info={<InputText1 name={'location'} label={'Location'} />} />
			<TableRow2Input
				label={'Description'}
				info={<InputText1 multiline={true} rows={3} rules={{ required: true }} name={'description'} label={'Description'} />}
			/>
			<TableRow2Input label={'All Day'} info={<Switch color="primary" onChange={(e) => setDateTime(e.target.checked)} />} />

			{dateTime ? (
				<TableRow2Input
					label={'Date'}
					info={<InputDateTime1 name={'date'} label={'Select Date'} type={'date'} rules={{ required: true }} />}
				/>
			) : (
				<>
					<TableRow2Input
						label={'Leaving Date'}
						info={<InputDateTime1 name={'date'} label={'Leaving Date'} type={'datetime-local'} rules={{ required: true }} />}
					/>
					<TableRow2Input
						label={'Returning Date'}
						info={<InputDateTime1 name={'return_date'} label={'Returning Date'} type={'datetime-local'} rules={{ required: true }} />}
					/>
				</>
			)}
		</>
	);
};
export default InAndOutAdd;

// 	<InputDateTime1 name={'date'} label={'Leaving Date'} type={'date'} rules={{ required: true }} />
