import { Autocomplete, createFilterOptions, Grid, Popper, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router';

const PopperMy = function (props) {
	return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
};

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
			// Default transform is "translate(14px, 20px) scale(1)""
			// This lines up the label with the initial cursor position in the input
			// after changing its padding-left.
			transform: 'translate(10px, 10px) scale(1);',
		},
	},
	inputRoot: {
		// This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
		'&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
			// Default left padding is 6px
			padding: 0,
		},
	},
}));

export default (props) => {
	const navigate = useNavigate();
	const {
		control,
		formState: { errors },
		setValue,
	} = useFormContext();
	const {
		disabled,
		label,
		name,
		options,
		labelKey,
		additionalOption,
		url,
		xs,
		sm,
		md,
		lg,
		rules,
		defaultValue,
		valueKey,
		saveAllData,
		onClose,
	} = props;
	const classes = useStyles();

	const filter = createFilterOptions({
		matchFrom: 'start',
		ignoreCase: true,
		stringify: (option) => option[labelKey],
	});

	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg}>
			<Controller
				name={name}
				control={control}
				defaultValue={''}
				rules={rules}
				render={({ field }) => (
					<Autocomplete
						PopperComponent={PopperMy}
						clearOnBlur
						defaultValue={defaultValue}
						disabled={disabled}
						classes={classes}
						fullWidth
						name={name}
						freeSolo
						selectOnFocus
						onClose={onClose}
						options={options}
						filterOptions={(options, params) => {
							const filtered = filter(options, params);

							if (additionalOption) {
								// Create an Additional Option => e.g Add Company => this option will always be there
								let newItem = {};
								newItem[labelKey] = additionalOption;
								newItem.additionalOption = 1;
								filtered.push(newItem);
							}
							return filtered;
						}}
						getOptionLabel={(option) => (option[labelKey] ? option[labelKey] : '')}
						onChange={(event, newValue) => {
							// If Additional Option is choosen (recognized by newValue?.additionalOption) redirect to new url
							if (newValue?.additionalOption) {
								navigate(url);
								// If other option is choosen set it as the value
							} else {
								if (!newValue) {
									field.onChange('');
								} else {
									if (saveAllData) {
										field.onChange(newValue);
									} else {
										field.onChange(newValue[valueKey]);
									}
								}
							}
						}}
						renderInput={(params) => (
							<TextField
								style={props.style}
								InputLabelProps={{ shrink: true }}
								fullWidth
								error={!!errors[name]}
								label={label}
								{...params}
							/>
						)}
					/>
				)}
				{...props}
			/>
		</Grid>
	);
};
