import { TableRow, TableCell, Button, IconButton } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import PlasmaRunSheetSearchRows from './PlasmaRunSheetSearchRows';
const PlasmaRunSheetSearchTable = ({ setSearchInfo, searchInfo }) => {
	const [showFilter, setShowFilter] = useState(false);
	return (
		<>
			<TableRow>
				<TableCell colSpan={8} sx={{ padding: '0px 0px' }}>
					<IconButton
						onClick={() => {
							setShowFilter(!showFilter);
							setSearchInfo({});
						}}
					>
						{!showFilter ? <SearchIcon /> : <SearchOffIcon />}
					</IconButton>
				</TableCell>
			</TableRow>

			{showFilter && <PlasmaRunSheetSearchRows setSearchInfo={setSearchInfo} searchInfo={searchInfo} />}
		</>
	);
};

export default PlasmaRunSheetSearchTable;
