import { Box, Divider, Drawer, Hidden, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';
import useAuth from '../../../hooks/useAuth';
import NavSection from '../../layout/NavSection';
import Logo from '../../zz.RandomComponents/Logo';
import Scrollbar from '../../zz.RandomComponents/Scrollbar';
import dashboardNavigation from './dashboardNavigation';
import { UserIcon } from './usericon/UserIcon';

const DashboardSidebar = (props) => {
	const user_data = useContext(UserContext)[0];

	const { onMobileClose, openMobile } = props;
	const location = useLocation();
	const { user } = useAuth();

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				mt: 7,
			}}
		>
			<Scrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							p: 2,
						}}
					>
						<RouterLink to="/">
							<Logo
								sx={{
									height: 40,
									width: 40,
								}}
							/>
						</RouterLink>
					</Box>
				</Hidden>
				<Box sx={{ p: 2 }}>
					<Box
						sx={{
							alignItems: 'center',
							backgroundColor: 'background.default',
							borderRadius: 1,
							display: 'flex',
							overflow: 'hidden',
							p: 2,
						}}
					>
						<UserIcon mobileView={true} />
						<RouterLink to={`/dashboard/users/${user_data.user_id}`}>
							<Box sx={{ ml: 2 }}>
								<Typography color="textPrimary" variant="subtitle2">
									{`${user_data.first_name} ${user_data.last_name}`}
								</Typography>
							</Box>
						</RouterLink>
					</Box>
				</Box>
				<Divider />
				<Box sx={{ p: 2 }}>
					{dashboardNavigation.map((section, index) => (
						<NavSection
							mobileView={true}
							key={index}
							pathname={location.pathname}
							sx={{
								'& + &': {
									mt: 3,
								},
							}}
							{...section}
						/>
					))}
				</Box>
				<Divider />
				{/* <Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="subtitle2">
						Need Help?
					</Typography>
					<Typography color="textSecondary" variant="body2">
						Check our docs
					</Typography>
					<Button color="primary" component={RouterLink} fullWidth sx={{ mt: 2 }} to="/docs" variant="contained">
						Documentation
					</Button>
				</Box> */}
			</Scrollbar>
		</Box>
	);

	return (
		<>
			<Hidden>
				{/* <Hidden lgUp> */}
				<Drawer
					anchor="left"
					onClose={onMobileClose}
					open={openMobile}
					PaperProps={{
						sx: {
							backgroundColor: 'background.paper',
							width: 280,
						},
					}}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			{/* This was here so there would be a permanent side bar on larger views */}
			{/* <Hidden lgDown>
				<Drawer
					anchor="left"
					open
					PaperProps={{
						sx: {
							backgroundColor: 'background.paper',
							height: 'calc(100% - 64px) !important',
							top: '64px !Important',
							width: 280,
						},
					}}
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden> */}
		</>
	);
};

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default DashboardSidebar;
