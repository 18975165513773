import { Box, Breadcrumbs, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Helmet } from 'react-helmet-async';
import User_sign_out from 'src/components/authentication/SignInSignOut/User_sign_out';
import CurrentInAndOutCard from 'src/components/dashboard/calendar/CurrentInAndOutCard';
import UpcomingInAndOutCard from 'src/components/dashboard/calendar/UpcomingInAndOutCard';
import NewsCard from 'src/components/dashboard/news/NewsCard';
import ChevronRightIcon from '../../icons/ChevronRight';
import RoterranetVersionsDashboardTable from 'src/components/dashboard/IT_Projects/RoterraNetVersions/Components/DashboardTable/RoterranetVersionsDashboardTable';

const MainDashboard = () => {
	return (
		<div>
			<Helmet>
				<title>Dashboard</title>
			</Helmet>

			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 1,
				}}
			>
				<Container maxWidth={true}>
					<Grid container justifyContent="space-between" spacing={0.5}>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<Box>
								<Typography color="textPrimary" variant="h5">
									Dashboard
								</Typography>
								<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
									<Typography color="textSecondary" variant="subtitle2">
										Dashboard
									</Typography>
								</Breadcrumbs>
							</Box>
							<Box>
								<User_sign_out />
							</Box>
						</Grid>

						<CurrentInAndOutCard />

						<UpcomingInAndOutCard />
						<NewsCard />
						<RoterranetVersionsDashboardTable />
					</Grid>
				</Container>
			</Box>
		</div>
	);
};

export default MainDashboard;
