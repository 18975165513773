import { Button } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';
import useSettings from '../../hooks/useSettings';
import PlusIcon from '../../icons/Plus';

import ProjectsTable from 'src/components/dashboard/projects/Features/ProjectsTable/Features/ProjectsTable';
import PageTemplateProvider from './_Components/PageTemplateProvider';

const ProjectsList = () => {
	const { settings } = useSettings();
	const navigate = useNavigate();

	const user_data = useContext(UserContext)[0];

	return (
		<>
			<PageTemplateProvider
				pageName={'Project List'}
				history={[{ title: 'Dashboard', nav: '/dashboard' }]}
				additionalBtn={
					<>
						{(user_data.project_create || user_data.user_rights <= 2) && (
							<Button
								color="primary"
								size={'small'}
								startIcon={<PlusIcon fontSize="small" />}
								sx={{ m: 1 }}
								variant="contained"
								onClick={() => {
									navigate('add');
								}}
							>
								Add Project
							</Button>
						)}
					</>
				}
			>
				<ProjectsTable />
			</PageTemplateProvider>
		</>
	);
};
export default ProjectsList;
