import { Box, Breadcrumbs, Container, Divider, Grid, Link, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';
import ChevronRightIcon from '../../icons/ChevronRight';
import { NCRAddDetail } from 'src/components/dashboard/NCR/NCRAdd/NcrDetailAdd/NCRadd';

const NCRadd = () => {
	const user_data = useContext(UserContext)[0];

	return (
		<>
			<Helmet>
				<title>Add NCR</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 1,
				}}
			>
				<Container maxWidth={true}>
					<Grid container justifyContent="space-between" spacing={3}>
						<Grid item>
							<Typography color="textPrimary" variant="h5">
								NCR Detail
							</Typography>
							<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
								<Link color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2">
									Dashboard
								</Link>

								<Link color="textPrimary" component={RouterLink} to="/dashboard/qualitycontrol" variant="subtitle2">
									NCR Table
								</Link>
							</Breadcrumbs>
						</Grid>
					</Grid>
					<Divider sx={{ mt: 2, mb: 1 }} />
					<Grid item>
						<Box mt={1}>
							<NCRAddDetail />
						</Box>
					</Grid>
				</Container>
			</Box>
		</>
	);
};

export default NCRadd;
