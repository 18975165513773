import { Divider } from '@mui/material';
import DialogContentText from '@mui/material/DialogContentText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { UserContext } from 'src/contexts/UserContext';
import { update_notifications_settings } from 'src/queries/queries_actual/user_notifications';

const NotificationSettingsPopUpContent = ({ incomingNotificationsSettings }) => {
	const userData = useContext(UserContext)[0];

	const { mutateAsync: updateSettings } = useMutation(update_notifications_settings, {});

	const queryClient = useQueryClient();
	const updateCheck = async (name, e) => {
		const update = {
			[name]: e.target.checked,
		};
		await updateSettings({ user_id: userData.user_id, update: update });
		await queryClient.invalidateQueries('notificationSettings');
	};

	return (
		<>
			<DialogContentText>Select All Notifications you would like to receive.</DialogContentText>
			<Divider />
			<FormGroup>
				{userData.manufacturing && (
					<>
						<FormControlLabel
							control={
								<Switch
									defaultChecked={incomingNotificationsSettings?.plasma_run_sheet || false}
									onChange={(e) => {
										updateCheck('plasma_run_sheet', e);
									}}
								/>
							}
							label="Plasma Run Sheet"
						/>
						<small> You will get notified once a Run Sheet has been completed. </small>
					</>
				)}
				<Divider />

				{(userData.workorder_create || userData.workorder_manager || userData.user_rights <= 3) && (
					<>
						<FormControlLabel
							control={
								<Switch
									defaultChecked={incomingNotificationsSettings?.workorder_status || false}
									onChange={(e) => {
										updateCheck('workorder_status', e);
									}}
								/>
							}
							label="Workorder Status"
						/>
						<small>
							You will be notified of any status change to a workorder that belongs to you. <br /> (PM's will be notified if that
							workorder belong to their Project)
						</small>
					</>
				)}
				<Divider />

				{userData.user_rights <= 3 && (
					<>
						<FormControlLabel
							control={
								<Switch
									defaultChecked={incomingNotificationsSettings?.ncr || false}
									onChange={(e) => {
										updateCheck('ncr', e);
									}}
								/>
							}
							label="NCR"
						/>
						<small> You will get notified once a Run Sheet has been completed. </small>
					</>
				)}

				{/* <FormControlLabel control={<Switch />} label="Workorder" disabled />
				<small style={{ textDecoration: 'line-through' }}> You will get notified once a workorder you're part of has a status change.</small>
				<Divider />

				<FormControlLabel control={<Switch />} label="Project" disabled />
				<small style={{ textDecoration: 'line-through' }}> You will get notified once a Project you're part of has a status change.</small>
				<Divider /> */}
			</FormGroup>
		</>
	);
};

export default NotificationSettingsPopUpContent;
