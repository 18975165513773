import { Button } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ControlledCheckBox from 'src/components/forms/controls/DenseInputs/ControlledCheckBox';
import InputText2 from 'src/components/forms/controls/Input/InputText2';
import SelectAutoComeplet2 from 'src/components/forms/controls/SelectAutocomplete/SelectAutoComeplet2';
import { get_plasma_options, update_plasma_run_sheet_items } from 'src/queries/queries_actual/plasma_run_sheets';

const PlasmaRunSheetTableEditRow = ({ row, index, setEdit }) => {
	const removePadding = { padding: '0px 0px' };

	const { mutateAsync: updateItem } = useMutation(update_plasma_run_sheet_items, {});

	const { handleSubmit, reset, control } = useForm({ defaultValues: {} });
	const queryClient = useQueryClient();
	const onUpdate = async (formData) => {
		const submitData = {
			id: row?.id,
			update: { ...formData, size: formData?.size?.id, type: formData?.type?.value, workorder_id: formData?.workorder_id?.workorder_id },
		};

		console.log(submitData);
		const res = await updateItem(submitData);
		await queryClient.invalidateQueries('plasmaRunSheetItems');
		setEdit(false);
	};

	const getODvalue = (row) => {
		if (row.type === 3) return <InputText2 control={control} label={'OD'} type={'text'} name={'od'} width={'100%'} defaultValue={row.od} />;
		if (row.type === 1) {
			return <InputText2 control={control} label={'OD'} type={'text'} name={'od'} width={'100%'} defaultValue={row.od} />;
		} else {
			return <InputText2 control={control} label={'Length'} type={'number'} name={`length`} width={'100%'} defaultValue={row.length} />;
		}
	};

	const { isLoading, error, data } = useQuery('optionsList', get_plasma_options);

	if (isLoading) return <>Loading...</>;
	if (error) return <>Something Went Wrong</>;

	return (
		<>
			<TableCell align="center" component="th" scope="row" sx={{ ...removePadding }}>
				{`${row.id_name} - ${index + 1}`}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{getODvalue(row)}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.type === 3 ? (
					<InputText2 control={control} label={'Width'} type={'number'} name={`width`} width={'100%'} defaultValue={row.width} />
				) : (
					<SelectAutoComeplet2
						control={control}
						name={`size`}
						label="Id"
						freeSolo={false}
						getOptionLabel={(data) => `${data.name}`}
						options={data.idSizeList}
						defaultValue={{ id: row.helix_size_id, name: row.helix_size }}
					/>
				)}
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				<SelectAutoComeplet2
					disabled={true}
					rules={{ required: true }}
					control={control}
					name={`type`}
					label="Type"
					freeSolo={false}
					getOptionLabel={(option) => `${option.label}`}
					options={[
						{ value: 1, label: 'Helix' },
						{ value: 2, label: 'Square Caps' },
						{ value: 3, label: 'Round Caps' },
					]}
					defaultValue={{ value: row.type, label: row.type_name }}
				/>
			</TableCell>

			<TableCell sx={{ ...removePadding }} align="center">
				<InputText2
					control={control}
					label={'Extra Info'}
					type={'text'}
					name={`extra_detail`}
					width={'100%'}
					defaultValue={row.extra_detail}
				/>
			</TableCell>

			<TableCell sx={{ ...removePadding }} align="center">
				{row.first_off_check ? row.first_off_check_name : <ControlledCheckBox disabled />}
			</TableCell>

			<TableCell align="center" sx={{ ...removePadding }}>
				<InputText2
					control={control}
					label={'Actual Cut'}
					type={'number'}
					name={`actual_cut`}
					width={'100%'}
					defaultValue={row.actual_cut || 0}
				/>
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				<InputText2
					control={control}
					label={'Projected Qty'}
					type={'number'}
					name={`projected_qty`}
					width={'100%'}
					defaultValue={row.projected_qty || 0}
				/>
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				<InputText2
					control={control}
					label={'Total Required'}
					type={'number'}
					name={`total_required`}
					width={'100%'}
					defaultValue={row.total_required || 0}
				/>
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				<InputText2
					control={control}
					label={'Total Cut'}
					type={'number'}
					name={`total_cut`}
					width={'100%'}
					defaultValue={row.total_cut || 0}
				/>
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				<SelectAutoComeplet2
					rules={{ required: true }}
					control={control}
					name={`workorder_id`}
					label="Workorder #"
					freeSolo={false}
					getOptionLabel={(data) => `${data.workorder_name}`}
					options={data.workorderList}
					defaultValue={{ workorder_id: row.workorder_id, workorder_name: row.workorder_name }}
				/>
			</TableCell>
			<TableCell sx={{ ...removePadding }} align="center">
				{row.verified_by ? row.verified_by_name : <ControlledCheckBox disabled />}
			</TableCell>
			<TableCell sx={{ ...removePadding }}>
				<Button onClick={handleSubmit(onUpdate)} size={'small'}>
					Update
				</Button>
			</TableCell>
		</>
	);
};

export default PlasmaRunSheetTableEditRow;
