import { Divider, Grid, Typography } from '@mui/material';

export default function TableRow2({ label, info, paddingBlock }) {
	return (
		<>
			<Grid item fullWidth container spacing={1} align={'left'} alignItems={'center'} sx={{ paddingBlock: paddingBlock || 1 }}>
				<Grid item xs={12} sm={6}>
					<Typography color="textPrimary" variant="subtitle2" sx={{ wordBreak: 'break-word' }}>
						{label}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography color="textSecondary" variant="body2" sx={{ wordBreak: 'break-word' }}>
						{info}
					</Typography>
				</Grid>
			</Grid>
			<Divider />
		</>
	);
}
