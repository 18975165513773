import { Box, Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink } from 'react-router-dom';
import PlasmaRunSheetAddPopup from 'src/components/dashboard/PlasmaRunSheets/PlasmaRunSheetMainTable/PlasmaRunSheetAddPopup';
import PlasmaRunSheetTable from 'src/components/dashboard/PlasmaRunSheets/PlasmaRunSheetMainTable/PlasmaRunSheetTable';

import { UserContext } from 'src/contexts/UserContext';
import ChevronRightIcon from '../../icons/ChevronRight';

const PlasmaRunSheetsList = () => {
	const user_data = useContext(UserContext)[0];

	return (
		<>
			<Helmet>
				<title>Plasma Run Sheets</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 1,
				}}
			>
				<Container maxWidth={true}>
					<Grid container justifyContent="space-between" spacing={1}>
						<Grid item>
							<Typography color="textPrimary" variant="h5">
								Plasma Run Sheets
							</Typography>
							<Breadcrumbs aria-label="breadcrumb" separator={<ChevronRightIcon fontSize="small" />} sx={{ mt: 1 }}>
								<Link color="textPrimary" component={RouterLink} to="/dashboard" variant="subtitle2">
									Dashboard
								</Link>

								<Typography color="textSecondary" variant="subtitle2">
									Plasma Run Sheets
								</Typography>
							</Breadcrumbs>
							<Box
								sx={{
									mb: -1,
									mx: -1,
									mt: 1,
								}}
							/>
						</Grid>
						<Grid item>
							<Box sx={{ m: -1 }}>{(user_data.manufacturing === true || user_data.user_rights <= 2) && <PlasmaRunSheetAddPopup />}</Box>
						</Grid>
					</Grid>
					<Box sx={{ mt: 3 }}>
						<PlasmaRunSheetTable />
					</Box>
				</Container>
			</Box>
		</>
	);
};
export default PlasmaRunSheetsList;
