import { useContext } from 'react';
import { PlasmaRunSheetContext } from 'src/contexts/PlasmaRunSheetContext';
const PlasmaRunSheetUsedTotal = () => {
	const { totalInches, totalParts } = useContext(PlasmaRunSheetContext);

	return (
		<div>
			<div style={{ display: 'flex', gap: '1rem' }}>
				<small>Total Inches:</small>
				<small>{Math.round((totalInches + Number.EPSILON) * 100) / 100} </small>
			</div>
			<div style={{ display: 'flex', gap: '1rem' }}>
				<small>Total Parts:</small>
				<small>{totalParts}</small>
			</div>
		</div>
	);
};

export default PlasmaRunSheetUsedTotal;
