import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

export default (props) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const { label, name, xs, sm, md, lg, rules, defaultValue, value, style, type } = props;

	return (
		<>
			<Controller
				name={name}
				defaultValue={defaultValue || ''}
				control={control}
				rules={rules}
				render={({ field }) => (
					<TextField
						value={field.value}
						defaultValue={defaultValue}
						onChange={field.onChange}
						id="datetime-local"
						label={label}
						type={type || 'datetime-local'}
						sx={{ width: '17rem' }}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				)}
			/>
		</>
	);
};

// OLD CALENDAR "UnUsable"
{
	/* <LocalizationProvider dateAdapter={AdapterDateFns}>
<DateTimePicker
	renderInput={(props) => (
		<TextField fullWidth {...field} {...props} style={style} />
	)}
	label={label}
	value={field.value}
	onChange={field.onChange}
/>
</LocalizationProvider> */
}
