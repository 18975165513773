import { lazy, Suspense } from 'react';
import AuthGuard from './components/authentication/AuthGuard';
import GuestGuard from './components/authentication/GuestGuard';
import DashboardLayout from './components/dashboard/00. layout/DashboardLayout';
import MainLayout from './components/layout/MainLayout';
import LoadingScreen from './components/zz.RandomComponents/LoadingScreen';
// Set Cookies
import { Navigate } from 'react-router-dom';
import SetCookie from './pages/authentication/setcookie';
import Dashboard from './pages/dashboard/Dashboard';
import NCRadd from './pages/dashboard/NCRadd';
import PlasmaRunSheetShow from './pages/dashboard/PlasmaRunSheetShow';
import PlasmaRunSheetsList from './pages/dashboard/PlasmaRunSheetsList';
import ProjectsList from './pages/dashboard/ProjectsList';
import RoterraNetVersionsTable from './pages/dashboard/RoterraNetVersions';

export const Loadable = (Component) => (props) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login' /* webpackChunkName: 'login' */)));

const Logout = Loadable(lazy(() => import('./pages/authentication/Logout' /* webpackChunkName: 'logout' */)));

// Dashboard pages

const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));

// Contact
const ContactsShow = Loadable(lazy(() => import('./pages/dashboard/ContactsShow' /* webpackChunkName: 'ContactsShow' */)));
const ContactsAdd = Loadable(lazy(() => import('./pages/dashboard/ContactsAdd' /* webpackChunkName: 'ContactsAdd' */)));
const ContactsList = Loadable(lazy(() => import('./pages/dashboard/ContactsList' /* webpackChunkName: 'ContactsList' */)));

// Calendar
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar' /* webpackChunkName: 'Calendar' */)));

// Customer
const CustomersShow = Loadable(lazy(() => import('./pages/dashboard/CustomersShow' /* webpackChunkName: 'CustomersShow' */)));
const CustomersAdd = Loadable(lazy(() => import('./pages/dashboard/CustomersAdd' /* webpackChunkName: 'CustomersAdd' */)));
const CustomersList = Loadable(lazy(() => import('./pages/dashboard/CustomersList' /* webpackChunkName: 'CustomersList' */)));

// Equipment
const EquipmentShow = Loadable(lazy(() => import('./pages/dashboard/EquipmentShow' /* webpackChunkName: 'EquipmentShow' */)));
const EquipmentAdd = Loadable(lazy(() => import('./pages/dashboard/EquipmentAdd' /* webpackChunkName: 'EquipmentAdd' */)));
const EquipmentList = Loadable(lazy(() => import('./pages/dashboard/EquipmentList' /* webpackChunkName: 'EquipmentList' */)));
// Contracts
const ContractsList = Loadable(lazy(() => import('./pages/dashboard/ContractsList' /* webpackChunkName: 'ContractsList' */)));

// HR
const HumanResources = Loadable(lazy(() => import('./pages/dashboard/HumanResources' /* webpackChunkName: 'HumanResources' */)));
const HumanResourcesForms = Loadable(lazy(() => import('./pages/dashboard/HumanResourcesForms' /* webpackChunkName: 'HumanResourcesForms' */)));
const HumanResourcesPolicies = Loadable(lazy(() => import('./pages/dashboard/HumanResourcesPolicies' /* webpackChunkName: 'HumanResourcesForms' */)));
const HumanResourcesNewsletters = Loadable(
	lazy(() => import('./pages/dashboard/HumanResourcesNewsLetter' /* webpackChunkName: 'HumanResourcesForms' */))
);
const HumanResourcesResources = Loadable(
	lazy(() => import('./pages/dashboard/HumanResourcesResources' /* webpackChunkName: 'HumanResourcesForms' */))
);

// Contracts
const InvoicesList = Loadable(lazy(() => import('./pages/dashboard/InvoicesList' /* webpackChunkName: 'InvoicesList' */)));

const ItProjects = Loadable(lazy(() => import('./pages/dashboard/ItProjects' /* webpackChunkName: 'ItProjects' */)));
const ItProjectsAdd = Loadable(lazy(() => import('./pages/dashboard/itProjectsAdd' /* webpackChunkName: 'itProjectsAdd' */)));
const ItProjectsShow = Loadable(lazy(() => import('./pages/dashboard/itProjectShow' /* webpackChunkName: 'itProjectShow' */)));

const LogisticsEquipment = Loadable(
	lazy(() => import('./pages/dashboard/Logistics/LogisticsEquipment' /* webpackChunkName: 'LogisticsEquipment' */))
);

// MTR
const MTRsShow = Loadable(lazy(() => import('./pages/dashboard/MTRsShow' /* webpackChunkName: 'MTRsShow' */)));
const MTRsDetailShow = Loadable(lazy(() => import('./pages/dashboard/MTRsDetailShow' /* webpackChunkName: 'MTRsDetailShow' */)));
const MTRsAdd = Loadable(lazy(() => import('./pages/dashboard/MTRsAdd' /* webpackChunkName: 'MTRsAdd' */)));
const MTRsList = Loadable(lazy(() => import('./pages/dashboard/MTRsList' /* webpackChunkName: 'MTRsList' */)));

const NCRList = Loadable(lazy(() => import('./pages/dashboard/NCRlist' /* webpackChunkName: 'NCRList' */)));
const NCRShow = Loadable(lazy(() => import('./pages/dashboard/NCRShow' /* webpackChunkName: 'NCRShow' */)));

const RCAShow = Loadable(lazy(() => import('./pages/dashboard/RCAShow' /* webpackChunkName: 'RCAshow' */)));
const RCAadd = Loadable(lazy(() => import('./pages/dashboard/RCAadd' /* webpackChunkName: 'RCAadd' */)));

const RCATable = Loadable(lazy(() => import('./pages/dashboard/RCAList' /* webpackChunkName: 'RCAadd' */)));

// Po
const PoShow = Loadable(lazy(() => import('./pages/dashboard/PoShow' /* webpackChunkName: 'PoShow' */)));
const PoAdd = Loadable(lazy(() => import('./pages/dashboard/PoAdd' /* webpackChunkName: 'PoAdd' */)));
const PoList = Loadable(lazy(() => import('./pages/dashboard/PoList' /* webpackChunkName: 'PoList' */)));
const PoAnalytics = Loadable(lazy(() => import('./pages/dashboard/PoAnalytics' /* webpackChunkName: 'PoAnalytics' */)));

// Po Details
const PoAdvancedSearchList = Loadable(lazy(() => import('./pages/dashboard/PoAdvancedSearchList' /* webpackChunkName: 'PoAdvancedSearchList' */)));

// Projects
const ProjectsShow = Loadable(lazy(() => import('./pages/dashboard/ProjectsShow' /* webpackChunkName: 'ProjectsShow' */)));
const ProjectsAdd = Loadable(lazy(() => import('./pages/dashboard/ProjectsAdd' /* webpackChunkName: 'ProjectsAdd' */)));
// const ProjectsList = Loadable(lazy(() => import('./pages/dashboard/ProjectsList' /* webpackChunkName: 'ProjectsList' */)));

const PipesAndPlatesList = Loadable(lazy(() => import('./pages/dashboard/PipesAndPlatesList')));

// Quotes
const QuotesShow = Loadable(lazy(() => import('./pages/dashboard/QuotesShow' /* webpackChunkName: 'QuotesShow' */)));
const QuotesAdd = Loadable(lazy(() => import('./pages/dashboard/QuotesAdd' /* webpackChunkName: 'QuotesAdd' */)));
const QuotesList = Loadable(lazy(() => import('./pages/dashboard/QuotesList' /* webpackChunkName: 'QuotesList' */)));
const QuotesAnalytics = Loadable(lazy(() => import('./pages/dashboard/QuotesAnalytics' /* webpackChunkName: 'QuotesAnalytics' */)));

// Supplier
const SuppliersShow = Loadable(lazy(() => import('./pages/dashboard/SuppliersShow' /* webpackChunkName: 'SuppliersShow' */)));
const SuppliersAdd = Loadable(lazy(() => import('./pages/dashboard/SuppliersAdd' /* webpackChunkName: 'SuppliersAdd' */)));
const SuppliersList = Loadable(lazy(() => import('./pages/dashboard/SuppliersList' /* webpackChunkName: 'SuppliersList' */)));

// Users
const UsersShow = Loadable(lazy(() => import('./pages/dashboard/UsersShow' /* webpackChunkName: 'UsersShow' */)));
const UsersAdd = Loadable(lazy(() => import('./pages/dashboard/UsersAdd' /* webpackChunkName: 'UsersAdd' */)));
const UsersList = Loadable(lazy(() => import('./pages/dashboard/UsersList' /* webpackChunkName: 'UsersList' */)));

const OrganizationalHierarchy = Loadable(lazy(() => import('./pages/dashboard/OrganizationalHierarchy' /* webpackChunkName: 'usersChart' */)));

// Workorders
const WorkordersProjectManagerView = Loadable(
	lazy(() => import('./pages/dashboard/WorkorderProjectManagerView' /* webpackChunkName: 'WorkorderProjectManagerView' */))
);
const WorkordersShow = Loadable(lazy(() => import('./pages/dashboard/WorkordersShow' /* webpackChunkName: 'WorkordersShow' */)));
const WorkordersAdd = Loadable(lazy(() => import('./pages/dashboard/WorkordersAdd' /* webpackChunkName: 'WorkordersAdd' */)));
const WorkordersList = Loadable(lazy(() => import('./pages/dashboard/WorkordersList' /* webpackChunkName: 'WorkordersList' */)));
const WorkordersForm = Loadable(lazy(() => import('./pages/dashboard/WorkordersForm' /* webpackChunkName: 'WorkordersForm' */)));

const WorkordersLiveWeldedPipe = Loadable(
	lazy(() => import('./pages/dashboard/WorkordersLiveWeldedPipe' /* webpackChunkName: 'WorkordersLiveWeldedPipe.js' */))
);

const WorkorderAnalytics = Loadable(lazy(() => import('./pages/dashboard/WorkorderAnalytics' /* webpackChunkName: 'WorkordersAnalytics.js' */)));

// Workorders Items
const WorkordersItemsWelds = Loadable(lazy(() => import('./pages/dashboard/WorkordersItemsWelds' /* webpackChunkName: 'WorkordersItemsWelds' */)));
const WorkordersItemsPipe = Loadable(lazy(() => import('./pages/dashboard/WorkordersItemsPipe' /* webpackChunkName: 'WorkordersItemsPipe' */)));
const WorkordersItemsLabels = Loadable(lazy(() => import('./pages/dashboard/WorkordersItemsLabels' /* webpackChunkName: 'WorkordersItemsLabels' */)));

// Docs pages
const Docs = Loadable(lazy(() => import('./pages/Docs')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired' /* webpackChunkName: 'AuthorizationRequired' */)));
const AuthorizationExpired = Loadable(lazy(() => import('./pages/AuthorizationExpired' /* webpackChunkName: 'AuthorizationExpired' */)));
const NotFound = Loadable(lazy(() => import('./pages/NotFound' /* webpackChunkName: 'NotFound' */)));
const ServerError = Loadable(lazy(() => import('./pages/ServerError' /* webpackChunkName: 'ServerError' */)));

const Home = Loadable(lazy(() => import('./pages/Home')));

const routes = [
	{
		path: '',
		element: <Navigate to="/auth/login" />,
	},
	{
		path: 'auth',

		children: [
			{
				path: '',
				element: <Navigate to="/auth/login" />,
			},
			{
				path: 'login',
				element: (
					<GuestGuard>
						<Login />
					</GuestGuard>
				),
			},
			{
				path: 'logout',
				element: (
					<AuthGuard>
						<Logout />
					</AuthGuard>
				),
			},
		],
	},
	{
		path: 'dashboard',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '',
				element: <Dashboard />,
			},
			{
				path: 'analytics',

				children: [
					{
						path: 'po',
						element: <PoAnalytics />,
					},
				],
			},
			{ path: 'calendar', element: <Calendar /> },
			{
				path: 'contacts',
				children: [
					{
						path: '',
						element: <ContactsList />,
					},
					{
						path: 'add',
						element: <ContactsAdd />,
					},
					{
						path: ':contactId',
						element: <ContactsShow />,
					},
				],
			},
			{
				path: 'customers',
				children: [
					{
						path: '',
						element: <CustomersList />,
					},
					{
						path: 'add',
						element: <CustomersAdd />,
					},
					{
						path: ':customerId',
						element: <CustomersShow />,
					},
				],
			},
			{
				path: 'equipment',
				children: [
					{
						path: '',
						element: <EquipmentList />,
					},
					{
						path: 'add',
						element: <EquipmentAdd />,
					},
					{
						path: ':equipmentId',
						element: <EquipmentShow />,
					},
				],
				path: 'invoices',
				children: [
					{
						path: '',
						element: <InvoicesList />,
					},
				],
			},

			{
				path: 'it',
				children: [
					{
						path: '',
						element: <ItProjects />,
					},
					{
						path: 'add',
						element: <ItProjectsAdd />,
					},
					{
						path: ':job_id',
						element: <ItProjectsShow />,
					},
				],
			},

			{
				path: 'roterranet',
				children: [
					{
						path: 'versions',
						element: <RoterraNetVersionsTable />,
					},
				],
			},

			{
				path: 'logistics',
				children: [
					{
						path: 'equipment',
						element: <LogisticsEquipment />,
					},
				],
			},

			{
				path: 'contracts',
				children: [
					{
						path: '',
						element: <ContractsList />,
					},
				],
			},
			{
				path: 'mtrs',
				children: [
					{
						path: '',
						element: <MTRsList />,
					},
					{
						path: 'add',
						element: <MTRsAdd />,
					},
					{
						path: ':mtrId',
						element: <MTRsShow />,
					},
					{
						path: ':mtrId/detail/:mtrDetailId',
						element: <MTRsDetailShow />,
					},
				],
			},
			{
				path: 'po',
				children: [
					{
						path: '',
						element: <PoList />,
					},
					{
						path: 'add',
						element: <PoAdd />,
					},
					{
						path: 'advanced_search',
						element: <PoAdvancedSearchList />,
					},
					{
						path: ':poId',
						element: <PoShow />,
					},
				],
			},
			{
				path: 'plasmarunsheets',
				children: [
					{
						path: '',
						element: <PlasmaRunSheetsList />,
					},
					{
						path: ':plasmaRunSheetId',
						element: <PlasmaRunSheetShow />,
					},
				],
			},
			{
				path: 'projects',
				children: [
					{
						path: '',
						element: <ProjectsList />,
					},
					{
						path: 'add',
						element: <ProjectsAdd />,
					},
					{
						path: ':projectId',
						element: <ProjectsShow />,
					},
					{
						path: 'workorder/:workorderId',
						element: <WorkordersProjectManagerView />,
					},
				],
			},
			{
				path: 'suppliers',
				children: [
					{
						path: '',
						element: <SuppliersList />,
					},
					{
						path: 'add',
						element: <SuppliersAdd />,
					},
					{
						path: ':supplierId',
						element: <SuppliersShow />,
					},
				],
			},
			{
				path: 'quotes',
				children: [
					{
						path: '',
						element: <QuotesList />,
					},
					{
						path: 'add',
						element: <QuotesAdd />,
					},
					{
						path: 'analytics',
						element: <QuotesAnalytics />,
					},
					{
						path: ':quoteId',
						element: <QuotesShow />,
					},
				],
			},

			{
				path: 'qualitycontrol',
				children: [
					{
						path: '',
						element: <NCRList />,
					},
					{
						path: 'add',
						element: <NCRadd />,
					},
					{
						path: ':ncrid',
						element: <NCRShow />,
					},
				],
			},
			{
				path: 'qualitycontrol/rca',
				children: [
					{
						path: 'add',
						element: <RCAadd />,
					},
					{
						path: ':rcaId',
						element: <RCAShow />,
					},
					{
						path: 'list',
						element: <RCATable />,
					},
				],
			},

			{
				path: 'users',
				children: [
					{
						path: '',
						element: <UsersList />,
					},
					{
						path: 'add',
						element: <UsersAdd />,
					},
					{
						path: ':userId',
						element: <UsersShow />,
					},
				],
			},
			{
				path: 'hr',
				children: [
					{
						path: 'dashboard',
						element: <HumanResources />,
					},
					{
						path: 'forms',
						element: <HumanResourcesForms />,
					},
					{
						path: 'policies',
						element: <HumanResourcesPolicies />,
					},
					{
						path: 'resources',
						element: <HumanResourcesResources />,
					},
					{
						path: 'newsletters',
						element: <HumanResourcesNewsletters />,
					},

					{
						path: 'OrganizationalHierarchy',
						element: <OrganizationalHierarchy />,
					},
				],
			},

			{
				path: 'workorders',
				children: [
					{
						path: '',
						element: <WorkordersList />,
					},
					{
						path: 'add',
						element: <WorkordersAdd />,
					},
					{
						path: ':workorderId/workorders_items/:workorder_itemId/weld_inspection',
						element: <WorkordersItemsWelds />,
					},
					{
						path: ':workorderId/workorders_items/:workorder_itemId/pipe_inspection',
						element: <WorkordersItemsPipe />,
					},
					{
						path: ':workorderId',
						element: <WorkordersShow />,
					},
					{
						path: 'live',
						element: <WorkordersLiveWeldedPipe />,
					},
					{
						path: 'Analytics',
						element: <WorkorderAnalytics />,
					},
					{
						path: 'PipesAndPlates',
						element: <PipesAndPlatesList />,
					},
				],
			},
		],
	},

	{
		path: 'blank',
		children: [
			{
				path: 'workorders/:workorderId/workorders_items/:workorder_itemId/labels',
				element: <WorkordersItemsLabels />, //<AuthGuard>
			},
			{
				path: 'workorders/:workorderId/workordersformprintable',
				element: <WorkordersForm />, //<AuthGuard>
			},
		],
	},
	{
		path: 'setcookie',
		element: <SetCookie />, //<AuthGuard>
		children: [
			{
				path: '*',
				element: <Docs />,
			},
		],
	},
	// {
	// 	path: 'docs',
	// 	element: <DocsLayout />,
	// 	children: [
	// 		{
	// 			path: '',
	// 			element: <Navigate to="/docs/overview/welcome" replace />,
	// 		},
	// 		{
	// 			path: '*',
	// 			element: <Docs />,
	// 		},
	// 	],
	// },
	{
		path: '*',
		element: <MainLayout />,
		children: [
			{
				path: '401',
				element: <AuthorizationRequired />,
			},
			{
				path: '403',
				element: <AuthorizationExpired />,
			},
			{
				path: '404',
				element: <NotFound />,
			},
			{
				path: '500',
				element: <NotFound />,
			},
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
];

export default routes;
