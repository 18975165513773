import { Autocomplete, Popper, TextField, FormControl, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

const PopperMy = function (props) {
	return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
};

const SelectAutoComeplet2 = (props) => {
	const { label, name, rules, control, options, optionLabel, style, getOptionLabel, disabled, defaultValue = '', freeSolo = true } = props;

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue}
			rules={rules}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<FormControl style={{ display: 'flex', flexDirection: 'column', width: '100%' }} size="small">
					<Autocomplete
						disabled={disabled}
						size={'small'}
						error={!!error}
						helperText={error ? error.message : null}
						PopperComponent={PopperMy}
						fullWidth
						defaultValue={defaultValue}
						disableClearable
						freeSolo={freeSolo}
						sx={style}
						options={options}
						getOptionLabel={getOptionLabel}
						onChange={(e, data) => {
							onChange(data);
						}}
						autoHighlight
						renderInput={(params) => {
							return (
								<TextField
									size={'small'}
									onChange={onChange}
									value={value}
									// sx={style}
									InputLabelProps={{ shrink: true }}
									fullWidth
									label={label}
									{...params}
								/>
							);
						}}
					/>
					{!!error && (
						<FormHelperText margin="dense" error id={`${name}-error`} sx={{ padding: 0, margin: 'auto' }}>
							{error.message}
						</FormHelperText>
					)}
				</FormControl>
			)}
			onChange={([, data]) => data}
			{...props}
		/>
	);
};
export default SelectAutoComeplet2;
