import axios from 'axios';

const deleteDataAuthed = async ({ url, body, token }) => {
	try {
		const axiosRes = await axios.put(`${process.env.REACT_APP_API_PATH_1}${url}`, body, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return axiosRes;
	} catch (error) {
		if (error.response) {
			// Request made and server responded
			return error.response.data;
		} else if (error.request) {
			// The request was made but no response was received
			return error.request;
		} else {
			// Something happened in setting up the request that triggered an Error
			return 'Error', error.message;
		}
	}
};

export default deleteDataAuthed;
