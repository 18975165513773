import { useState, createContext, useContext } from 'react';
import { UserContext } from './UserContext';

export const PlasmaRunSheetContext = createContext();

export const PlasmaRunSheetProvider = (props) => {
	const userData = useContext(UserContext)[0];
	const [totalInches, setTotalInches] = useState(0);
	const [totalParts, setTotalParts] = useState(0);
	const [sheet, setSheet] = useState({});
	const [operator, setOperator] = useState({ full_name: `${userData.first_name} ${userData.last_name}`, user_id: userData.user_id });

	return (
		<PlasmaRunSheetContext.Provider value={{ totalInches, setTotalInches, totalParts, setTotalParts, sheet, setSheet, operator, setOperator }}>
			{props.children}
		</PlasmaRunSheetContext.Provider>
	);
};
