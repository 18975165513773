import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import RoterraNetVersionsTableAddButtonContent from './RoterraNetVersionsTableAddButton/RoterraNetVersionsTableAddButtonContent';
import { postDataAuthed } from 'src/queries/authedQueries';
import { UserContext } from 'src/contexts/UserContext';
import { useCookies } from 'react-cookie';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';

const RoterraNetVersionsTableAddButton = () => {
	const [open, setOpen] = useState(false);

	const { enqueueSnackbar } = useSnackbar();
	const userData = useContext(UserContext)[0];
	const [cookies] = useCookies('RTP');
	const {
		handleSubmit,
		formState: { error },
		control,
	} = useForm();

	const queryClient = useQueryClient();
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit = async (formData) => {
		const res = await postDataAuthed({
			url: 'roterranet_versions/createVersion',
			body: {
				created_on: new Date(),
				created_by: userData.user_id,
				...formData,
			},
			token: cookies.RPT,
		});
		handleClose();

		await queryClient.invalidateQueries('app-versions');
		enqueueSnackbar(res?.data?.msg, {
			anchorOrigin: {
				horizontal: 'left',
				vertical: 'bottom',
			},
			variant: res?.data?.color ?? 'error',
		});
	};

	return (
		<div>
			<Button variant="contained" size={'small'} onClick={handleClickOpen}>
				Add version update
			</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				fullWidth={true}
				maxWidth="md"
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{'Add a new Version'}</DialogTitle>
				<DialogContent>
					<RoterraNetVersionsTableAddButtonContent control={control} />
				</DialogContent>
				<DialogActions style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<Button variant="outlined" size="small" onClick={handleClose}>
						Close
					</Button>
					<Button variant="contained" size="small" onClick={handleSubmit(onSubmit)} autoFocus>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default RoterraNetVersionsTableAddButton;
