import { List } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { matchPath } from 'react-router-dom';
import { UserContext } from 'src/contexts/UserContext';
import NavItem_ComputerView from './NavItem_ComputerView';
import NavItem_MobileView from './NavItem_MobileView';

const renderNavItems = ({ depth = 0, items, pathname, mobileView }) => {
	return (
		<List style={{ display: depth == 0 && !mobileView && 'flex' }} disablePadding>
			{items.reduce(
				// eslint-disable-next-line no-use-before-define
				(acc, item) =>
					reduceChildRoutes({
						acc,
						item,
						pathname,
						depth,
						mobileView,
					}),
				[]
			)}
		</List>
	);
};

const reduceChildRoutes = ({ acc, pathname, item, depth, mobileView }) => {
	const user_data = useContext(UserContext)[0];
	const key = `${item.title}-${depth}`;
	const exactMatch = item.path
		? !!matchPath(
				{
					path: item.path,
					end: true,
				},
				pathname
		  )
		: false;

	// Check if user is authorized to see links
	let auth = true;
	if (Array.isArray(item.auth)) {
		auth = item.auth.every((one_user_auth) => user_data[one_user_auth] === true);
	}
	if (auth || user_data.user_rights <= 2) {
		// Mobile View
		if (mobileView) {
			if (item.children) {
				const partialMatch = item.path
					? !!matchPath(
							{
								path: item.path,
								end: false,
							},
							pathname
					  )
					: false;

				acc.push(
					<NavItem_MobileView
						active={partialMatch}
						depth={depth}
						icon={item.icon}
						info={item.info}
						key={key}
						open={partialMatch}
						path={item.path}
						title={item.title}
					>
						{renderNavItems({
							depth: depth + 1,
							items: item.children,
							pathname,
							mobileView,
						})}
					</NavItem_MobileView>
				);
			} else {
				acc.push(
					<NavItem_MobileView
						active={exactMatch}
						depth={depth}
						icon={item.icon}
						info={item.info}
						key={key}
						path={item.path}
						title={item.title}
					/>
				);
			}
		}
		// ComputerView => Not Mobile View
		else {
			if (item.children) {
				const partialMatch = item.path
					? !!matchPath(
							{
								path: item.path,
								end: false,
							},
							pathname
					  )
					: false;

				acc.push(
					<NavItem_ComputerView
						mobileView={mobileView}
						active={partialMatch}
						depth={depth}
						icon={item.icon}
						info={item.info}
						key={key}
						open={partialMatch}
						path={item.path}
						title={item.title}
					>
						{renderNavItems({
							depth: depth + 1,
							items: item.children,
							pathname,
							mobileView,
						})}
					</NavItem_ComputerView>
				);
			} else {
				acc.push(
					<NavItem_ComputerView
						mobileView={mobileView}
						active={exactMatch}
						depth={depth}
						icon={item.icon}
						info={item.info}
						key={key}
						path={item.path}
						title={item.title}
					/>
				);
			}
		}
	}
	return acc;
};

const NavSection = (props) => {
	const { mobileView, items, pathname, title, auth, ...other } = props;

	return (
		<List sx={{ pt: 0, pb: 0 }} {...other}>
			{renderNavItems({
				items,
				pathname,
				mobileView,
			})}
		</List>
	);
};

NavSection.propTypes = {
	items: PropTypes.array,
	pathname: PropTypes.string,
	title: PropTypes.string,
};

export default NavSection;

// subheader={
// 	<ListSubheader
// 		disableGutters
// 		disableSticky
// 		sx={{
// 			color: 'text.secondary',
// 			fontSize: '0.75rem',
// 			lineHeight: 2.5,
// 			fontWeight: 700,
// 			textTransform: 'uppercase',
// 		}}
// 	>
// 		{title}
// 	</ListSubheader>
// }
