import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PlasmaRunSheetAddItemFieldArrayRows from './PlasmaRunSheetAddItemFieldArrayRows';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get_plasma_options, post_plasma_run_sheet_items } from 'src/queries/queries_actual/plasma_run_sheets';
import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import { todayDate } from 'src/utils/formatDates';
import { useParams } from 'react-router';

const PlasmaRunSheetAddItemFieldArray = () => {
	const userData = useContext(UserContext)[0];
	const { plasmaRunSheetId } = useParams();

	const { handleSubmit, reset, control } = useForm();
	const queryClient = useQueryClient();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'AddItemArray',
	});
	const { mutateAsync: addItems } = useMutation(post_plasma_run_sheet_items, {});

	const onSubmit = async (formData) => {
		const submitData = {
			created_by: userData.user_id,
			created_on: todayDate(),
			plasma_run_sheet_id: plasmaRunSheetId,
			arrayOfItems: formData.AddItemArray,
		};

		const res = await addItems(submitData);
		await queryClient.invalidateQueries('plasmaRunSheetItems');
		remove();
	};

	const { isLoading, error, data } = useQuery('optionsList', get_plasma_options);

	if (isLoading) return <>Loading...</>;
	if (error) return <>Something Went Wrong</>;

	return (
		<>
			<TableContainer sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', border: 1, borderColor: '#E0E0E0' }}>
				<Table size={'small'} sx={{ minWidth: 'max-content' }}>
					<TableHead>
						<TableRow>
							<TableCell align="center" colSpan={12}>
								<Button
									size={'small'}
									onClick={() => {
										append({});
									}}
								>
									Add Row
								</Button>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{fields.map(({ id }, index) => {
							return (
								<TableRow key={id}>
									<PlasmaRunSheetAddItemFieldArrayRows control={control} index={index} options={data} />
									<TableCell align="left" sx={{ padding: '0px 0px' }}>
										<Button
											size={'small'}
											onClick={() => {
												remove(index);
											}}
										>
											Remove
										</Button>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{fields.length > 0 && <Button onClick={handleSubmit(onSubmit)}>Submit</Button>}
		</>
	);
};

export default PlasmaRunSheetAddItemFieldArray;
