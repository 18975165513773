import { useState } from 'react';

import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';

import Logout from '@mui/icons-material/Logout';
import { ColorFromString } from 'src/utils/ColorFromString';
import { useNavigate } from 'react-router-dom';

export const UserIconLoggedIn = ({ userData }) => {
	const { first_name, last_name, user_id } = userData;

	const [anchorEl, setAnchorEL] = useState(false);
	const open = Boolean(anchorEl);

	const navigate = useNavigate();
	const userInitials = first_name.split('')[0] + last_name.split('')[0];

	const handleClose = () => {
		setAnchorEL(false);
	};

	const handleLogout = () => {
		navigate('/auth/logout', { replace: true });
	};
	return (
		<>
			<Tooltip title="Account settings">
				<Button
					id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={(e) => setAnchorEL(e.currentTarget)}
				>
					<Avatar
						sx={{
							width: 42,
							height: 42,
							backgroundColor: ColorFromString(`${first_name}  ${last_name}`),
						}}
					>
						{userInitials}
					</Avatar>
				</Button>
			</Tooltip>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem disabled={true}>
					<ListItemIcon>
						<Avatar sx={{ width: 26, height: 25 }} />
					</ListItemIcon>
					<span>{first_name + ' ' + last_name}</span>
				</MenuItem>
				<MenuItem disabled={true} onClick={handleClose}>
					<Link
						to={`users/${user_id}`}
						style={{
							textDecoration: 'none',
							color: 'black',
						}}
					>
						<ListItemIcon>
							<Avatar sx={{ width: 26, height: 25 }} />
						</ListItemIcon>
						Profile
					</Link>
				</MenuItem>
				<Divider />

				<MenuItem onClick={handleLogout}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					Logout
				</MenuItem>
			</Menu>
		</>
	);
};
