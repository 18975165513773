import { useQuery } from 'react-query';
import getWeather from 'src/queries/queries_weather/getWeather';

const WeatherCard = () => {
	const { isLoading, error, data } = useQuery('weatherData', getWeather);

	if (isLoading) return <>Loading...</>;
	if (error) return <>Something Went Wrong</>;

	const currentTempt_C = data.data.current.temp_c;

	const icon = data.data.current.condition.icon;

	return (
		<div
			style={{
				display: 'flex',
				width: '6rem',

				alignItems: 'center',
			}}
		>
			<img src={icon} style={{ height: '3rem', width: '3rem' }} />
			<small style={{ fontSize: '1rem' }}>{currentTempt_C} &#8451; </small>
		</div>
	);
};

export default WeatherCard;
