import { useState, useRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem, Menu, Popover } from '@mui/material';
import ChevronDownIcon from '../../icons/ChevronDown';
import ChevronRightIcon from '../../icons/ChevronRight';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	popover: {
		pointerEvents: 'none',
	},
	popoverContent: {
		pointerEvents: 'auto',
	},
}));

const NavItem_ComputerView = (props) => {
	const { active, children, depth, icon, info, open: openProp, path, title, ...other } = props;
	const [openedPopover, setOpenedPopover] = useState(false);
	const popoverAnchor = useRef(null);

	const popoverEnter = ({ currentTarget }) => {
		setOpenedPopover(true);
	};

	const popoverLeave = ({ currentTarget }) => {
		setOpenedPopover(false);
	};

	const classes = useStyles();

	// Branch
	if (children) {
		return (
			<ListItem
				disableGutters
				sx={{
					display: 'flex',
					py: 0,
				}}
			>
				<Button
					ref={popoverAnchor}
					aria-owns="mouse-over-popover"
					aria-haspopup="true"
					onMouseEnter={popoverEnter}
					onMouseLeave={popoverLeave}
					startIcon={icon}
					endIcon={!openedPopover ? <ChevronRightIcon fontSize="small" /> : <ChevronDownIcon fontSize="small" />}
					sx={{
						minWidth: depth == 0 ? '50px' : 'auto',
						color: depth == 0 ? 'white' : 'text.primary',
						fontWeight: 'fontWeightMedium',
						// justifyContent: 'flex-start',
						textAlign: 'left',
						textTransform: 'none',
						width: '100%',
					}}
					variant="text"
				>
					<Box sx={{ flexGrow: 1 }}>{title}</Box>
					{info}
				</Button>
				<Popover
					id="mouse-over-popover"
					className={classes.popover}
					classes={{
						paper: classes.popoverContent,
					}}
					open={openedPopover}
					anchorEl={popoverAnchor.current}
					anchorOrigin={{
						vertical: depth == 0 ? 'bottom' : 'top',
						horizontal: depth == 0 ? 'left' : 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					PaperProps={{
						onMouseEnter: popoverEnter,
						onMouseLeave: popoverLeave,
						style: { minWidth: 150, borderRadius: 5 },
					}}
				>
					{children}
				</Popover>
			</ListItem>
		);
	}

	// Leaf
	return (
		<ListItem
			disableGutters
			sx={{
				display: 'flex',
				py: 0,
			}}
		>
			<Button
				component={path && RouterLink}
				startIcon={icon}
				sx={{
					color: depth == 0 ? 'white' : 'text.primary',
					fontWeight: 'fontWeightMedium',
					justifyContent: 'flex-start',
					textAlign: 'left',
					// pr: '8px',
					textTransform: 'none',
					width: '100%',
					...(active && {
						color: 'primary.main',
						fontWeight: 'fontWeightBold',
						'& svg': {
							color: 'primary.main',
						},
					}),
				}}
				variant="text"
				to={path}
			>
				<Box sx={{ flexGrow: 1 }}>{title}</Box>
				{info}
			</Button>
		</ListItem>
	);
};

NavItem_ComputerView.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.node,
	depth: PropTypes.number.isRequired,
	icon: PropTypes.node,
	info: PropTypes.node,
	open: PropTypes.bool,
	path: PropTypes.string,
	title: PropTypes.string.isRequired,
};

NavItem_ComputerView.defaultProps = {
	active: false,
	open: false,
};

export default NavItem_ComputerView;
