import { get_all, get_one } from '../query_helpers/get';
import { edit_one } from '../query_helpers/put';

// GET ALL
export const get_all_notifications = ({ queryKey: { 1: id } }) => get_all(`user_notifications/getNotifications/${id}`);

export const update_notifications = (values) => edit_one('user_notifications/updateNotifications/', values);

export const get_notification_settings = ({ queryKey: { 1: id } }) => get_one(`user_notifications_settings/getall/${id}`);

export const update_notifications_settings = (values) => edit_one(`user_notifications_settings/updateSettings`, values);
