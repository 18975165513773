import { Checkbox, FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { Controller } from 'react-hook-form';
export default (props) => {
	const { label, name, disabled, rules, defaultValue, inputStyle, labelStyle, inputProps, width, control, formStyle } = props;

	return (
		<Controller
			name={name}
			rules={rules}
			control={control}
			defaultValue={defaultValue}
			render={({ field: { onChange, value: value }, fieldState: { error } }) => {
				return (
					<div style={{ ...formStyle, display: 'flex', width: width, justifyContent: 'center', alignItems: 'center' }} size="small">
						<InputLabel sx={labelStyle} id={`${name}-label`}>
							{label}
						</InputLabel>
						<Checkbox
							defaultChecked={defaultValue}
							size={'small'}
							sx={inputStyle}
							labelId={`${name}-label`}
							id={`${name}-input`}
							value={value}
							label={label}
							onChange={onChange}
							disabled={disabled}
							inputProps={inputProps}
						/>
						{!!error && (
							<FormHelperText margin="dense" error id={`${name}-error`} sx={{ padding: 0, margin: 'auto' }}>
								{error.message}
							</FormHelperText>
						)}
					</div>
				);
			}}
		/>
	);
};
