import PlasmaRunSheetCard from '../../PlasmaRunSheetCard';
import PlasmaRunSheetAddItemFieldArray from './PlasmaRunSheetAddItemFieldArray';
import PlasmaRunSheetExtraInfoHelixTable from './PlasmaRunSheetExtraInfoHelixTable';

import PlasmaRunSheetUsedTotal from '../PlasmaRunSheetUsedTotal/PlasmaRunSheetUsedTotal';
import { useContext } from 'react';
import { UserContext } from 'src/contexts/UserContext';
const PlasmaRunSheetExtraInfo = () => {
	const user_data = useContext(UserContext)[0];

	return (
		<PlasmaRunSheetCard cardTitle={<PlasmaRunSheetUsedTotal />}>
			<PlasmaRunSheetExtraInfoHelixTable />

			{(user_data.manufacturing === true || user_data.user_rights <= 2) && <PlasmaRunSheetAddItemFieldArray />}
		</PlasmaRunSheetCard>
	);
};

export default PlasmaRunSheetExtraInfo;
