import { TableRow, TableCell, TextField } from '@mui/material';
import React from 'react';

import { queryFilter } from '../../Table_/TableFIlterFunctions';

const PlasmaRunSheetSearchRows = ({ setSearchInfo, searchInfo }) => {
	return (
		<TableRow>
			<TableCell align="left">
				<TextField
					inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
					size="small"
					variant="outlined"
					label="ID"
					onKeyUp={(e) => {
						queryFilter(0, setSearchInfo, searchInfo, 'sheet_name', e.target.value, 'string');
					}}
				/>
			</TableCell>

			<TableCell align="left" />

			<TableCell align="left">
				<TextField
					inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
					size="small"
					variant="outlined"
					label="Thickness"
					onKeyUp={(e) => {
						queryFilter(0, setSearchInfo, searchInfo, 'thickness_name', e.target.value, 'string');
					}}
				/>
			</TableCell>
			<TableCell align="left">
				<TextField
					inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
					size="small"
					variant="outlined"
					label="Length"
					onKeyUp={(e) => {
						queryFilter(0, setSearchInfo, searchInfo, 'sheet_length', e.target.value, 'string');
					}}
				/>
			</TableCell>
			<TableCell align="left">
				<TextField
					inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
					size="small"
					variant="outlined"
					label="Width"
					onKeyUp={(e) => {
						queryFilter(0, setSearchInfo, searchInfo, 'sheet_width', e.target.value, 'string');
					}}
				/>
			</TableCell>
			<TableCell align="left" />
			<TableCell align="right">
				<div style={{ display: 'flex', gap: '.5rem' }}>
					<TextField
						inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
						type="date"
						size="small"
						variant="outlined"
						onChange={(e) => {
							queryFilter(0, setSearchInfo, searchInfo, 'completed_date', e.target.value, 'date', 'start');
						}}
					/>
					<TextField
						inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
						type="date"
						size="small"
						variant="outlined"
						onChange={(e) => {
							queryFilter(0, setSearchInfo, searchInfo, 'completed_date', e.target.value, 'date', 'finish');
						}}
					/>
				</div>
			</TableCell>
			<TableCell align="right">
				<div style={{ display: 'flex', gap: '.5rem' }}>
					<TextField
						inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
						type="date"
						size="small"
						variant="outlined"
						onChange={(e) => {
							queryFilter(0, setSearchInfo, searchInfo, 'created_on', e.target.value, 'date', 'start');
						}}
					/>
					<TextField
						inputProps={{ style: { padding: '.4rem .4rem', width: '5rem' } }}
						type="date"
						size="small"
						variant="outlined"
						onChange={(e) => {
							queryFilter(0, setSearchInfo, searchInfo, 'created_on', e.target.value, 'date', 'finish');
						}}
					/>
				</div>
			</TableCell>
		</TableRow>
	);
};

export default PlasmaRunSheetSearchRows;
