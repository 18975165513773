import { IconButton, TableCell, TableRow } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import format from 'date-fns/format';
import { Suspense, useContext, useState } from 'react';
import { UserContext } from 'src/contexts/UserContext';
import GarbageIcon from 'src/icons/Trash';
import { delete_one_event } from 'src/queries/queries_actual/calendar';
import CalendarEventDelete from './CalendarEventsAddShow/CalendarEventShow';
// import DashboardPopUp from './DashboardPopUp';
import { lazy } from 'react';
const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.background.tableRow1,
		},
		'&:nth-of-type(even)': {
			backgroundColor: theme.palette.background.tableRow2,
		},
		padding: 'none',
	},
}))(TableRow);

const CalendarUpComingRows = ({ data }) => {
	const [deleteEventPopUp, setDeleteEventPopUp] = useState(false);
	const user_data = useContext(UserContext)[0];

	const set_date_time = (data) => {
		if (data.start_time === null) return format(new Date(data.date), 'LLL-dd-yyyy - h:mm aaaa');
		else if (data.start_time !== null) return format(new Date(data.date), 'LLL-dd-yyyy');
		else return format(new Date(data.date), 'LLL-dd-yyyy- ') + data.start_time;
	};
	const set_return_date = (data) => {
		if (data.end_time === null) return format(new Date(data.return_date), 'LLL-dd-yyyy - h:mm aaaa');
		else if (data.end_time !== null) return format(new Date(data.return_date), 'LLL-dd-yyyy');
		else return format(new Date(data.return_date), 'LLL-dd-yyyy- ') + data.end_time;
	};
	const DashboardPopUp = lazy(() => import('./DashboardPopUp'));
	return (
		<StyledTableRow style={{ padding: '1rem' }}>
			<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem' }} style={{ width: '8rem' }}>
				{data.event_name}
			</TableCell>
			<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem' }}>
				{set_date_time(data)}
			</TableCell>

			<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem' }}>
				{set_return_date(data)}
			</TableCell>

			<TableCell align="left" sx={{ padding: '.2rem', fontSize: '.8rem' }}>
				{data.location === '' ? data.description : data.description + ' - ' + data.location}
			</TableCell>
			<TableCell sx={{ padding: '.2rem', fontSize: '.8rem' }}>
				{data.created_by === user_data.user_id || user_data.user_rights <= 2 ? (
					<IconButton size="small" color="error" variant="text" type="submit" onClick={() => setDeleteEventPopUp(true)}>
						<GarbageIcon fontSize="small" />
						<Suspense fallback={<div>Loading</div>}>
							<DashboardPopUp
								title={'Delete event'}
								submitFunction={() => delete_one_event(data.id)}
								defaultValues={{}}
								handleClose={() => setDeleteEventPopUp(false)}
								popUpState={deleteEventPopUp}
								content={<CalendarEventDelete data={data} />}
							/>
						</Suspense>
					</IconButton>
				) : null}
			</TableCell>
		</StyledTableRow>
	);
};

export default CalendarUpComingRows;
