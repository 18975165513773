export const formatNumberToNumberWithCommas = (x) => {
	if (x && typeof x === 'number') {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	return '0';
};

export const formatNumberToMoney = (x) => {
	if (x && typeof x === 'number') {
		return `$${x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	} else if (x && typeof x === 'string') {
		return `$${parseInt(x)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
	}

	return '$0';
};

export const getTotalCost = (...args) => {
	let total = 0;
	args.map((i) => (total = total + parseInt(i)));
	return total;
};

export const formatNumberToMoneyDecimal = (x) => {
	if (x !== null) {
		return (
			'$' +
			parseFloat(x)
				.toFixed(2)
				.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
		);
	}
	return '$0.00';
};

export const formatNumberToMoneyDecimalUnlimitedDigits = (x) => {
	if (x !== null) {
		const places_after_decimal = x.toString().split('.')[1];

		if (places_after_decimal !== undefined && places_after_decimal.length > 2) {
			return (
				'$' +
				parseFloat(x)
					.toFixed(places_after_decimal.length)
					.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
			);
		} else {
			return (
				'$' +
				parseFloat(x)
					.toFixed(2)
					.replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
			);
		}
	}
	return '$0.00';
};

export const formatMoneyToNumber = (x) => {
	if (x && typeof x === 'string') {
		return parseInt(x.replace(/\$|,/g, ''));
	} else if (x && typeof x === 'number') {
		return x;
	}
	return 0;
};

export const round5 = (value) => {
	return (Math.round(value * 1e5) / 1e5).toFixed(5);
};
