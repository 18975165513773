import axios from 'axios';

const getWeather = async () => {
	const apiKey = process.env.REACT_APP_WEATHER_API_KEY;
	const city = 'edmonton';
	const baseUrl = `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${city}&aqi=no`;
	const data = axios.get(baseUrl);
	return data;
};
export default getWeather;
